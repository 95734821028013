<template>
  <div>
    <v-component
      v-bind:is="component"
      :widget="widget"
      @add="addWidgetElement"
      @remove="removeWidgetElement"
      @edit="editWidgetElement"/>
    <WidgetElementDetailsDialog
      :type="widget.type"
      :element="selectedWidgetElement"
      :selectedLine="selectedLine"
      :allowBufferCreation="allowBufferCreation"
      :hasCompareTo="hasCompareTo"
      :isVisible="widgetElementDetailsDialog"
      @update="updateElement"
      @closed="widgetElementDetailsDialog = false" />
  </div>
</template>
<script>
import {
  findByHexacode,
  findByHexacodeAndNetworkNumber,
  findByHexacodeAndDepartmentAndSubDepartment,
  findByHexacodeAndDepartmentAndSubDepartmentAndPage
} from '@/store/devices'
import {
  LOAD_PAGES,
  LOAD_LINES,
  LOAD_DEPARTMENTS_NAMES
} from '@/store/mutations'
import groupBy from 'lodash.groupby'
import { v4 as uuidv4 } from 'uuid'
import WidgetElementDetailsDialog from '@/components/dashboards/widgets/dialog2/WidgetElementDetailsDialog.vue'
export default {
  name: 'WidgetDetailsNode',
  components: {
    WidgetElementDetailsDialog
  },
  props: {
    widget: {
      type: Object,
      default() {
        return {
        }
      }
    }
  },
  data() {
    return {
      elements: {
        PAGE: [],
        TABLE: [],
        MULTIGRID_CHART: [],
        LINE_CHART: [],
        MULTIPLE_Y_CHART: [],
        LINE: [],
        PICTOGRAPH: [],
        CARD_CHART: [],
        BAR_CHART: []
      },
      selectedLine: null,
      selectedWidgetElement: null,
      allowBufferCreation: false,
      widgetElementDetailsDialog: false
    }
  },
  computed: {
    component() {
      if (this.widget.type === 'PAGE') {
        return () => import('@/components/dashboards/widgets/dialog2/types/PageWidgetDetailsNode')
      }
      if (this.widget.type === 'TABLE') {
        return () => import('@/components/dashboards/widgets/dialog2/types/TableWidgetDetailsNode')
      }
      if (this.widget.type === 'MULTIGRID_CHART') {
        return () => import('@/components/dashboards/widgets/dialog2/types/MultigridChartWidgetDetailsNode')
      }
      if (this.widget.type === 'LINE_CHART') {
        return () => import('@/components/dashboards/widgets/dialog2/types/LineChartWidgetDetailsNode')
      }
      if (this.widget.type === 'MULTIPLE_Y_CHART') {
        return () => import('@/components/dashboards/widgets/dialog2/types/MultipleYChartWidgetDetailsNode')
      }
      if (this.widget.type === 'PICTOGRAPH') {
        return () => import('@/components/dashboards/widgets/dialog2/types/PictographWidgetDetailsNode')
      }
      if (this.widget.type === 'CARD_CHART') {
        return () => import('@/components/dashboards/widgets/dialog2/types/CardChartWidgetDetailsNode')
      }
      if (this.widget.type === 'BAR_CHART') {
        return () => import('@/components/dashboards/widgets/dialog2/types/BarChartWidgetDetailsNode')
      }
      if (this.widget.type === 'COLLAPSABLE_TABLE') {
        return () => import('@/components/dashboards/widgets/dialog2/types/CollapsableTableWidgetDetailsNode')
      }
      return () => import('@/components/dashboards/widgets/dialog2/types/LineWidgetDetailsNode')
    },
    hasCompareTo() {
      return this.widget.interval && this.widget.interval.compareTo !== null
    }
  },
  created() {
    this.$watch(
      () => this.widget.type,
      (newValue, oldValue) => {
        if (oldValue !== undefined) {
          this.elements[oldValue] = this.clone(this.widget.elements)
          this.widget.elements = this.elements[newValue]
        } else {
          this.elements[newValue] = this.widget.elements
        }
        if (this.widget.elements.length === 0) {
          if (newValue === 'TABLE') {
            this.addWidgetElement({ yAxisNumber: 1, label: null })
          } else if (newValue === 'PICTOGRAPH') {
            this.addWidgetElement({ group: 0, showAxis: false })
          } else if (newValue === 'CARD_CHART') {
            const lineColor = this.$vuetify.theme.themes.light.primary
            this.addWidgetElement({ yAxisNumber: 0, compareWith: 'NONE', compareType: 'NUMERIC', showAxis: false, showSparkline: false, color: lineColor, compareTo: null })
          } else {
            this.addWidgetElement({ yAxisNumber: 0 })
          }
        }
        if (newValue === 'MULTIPLE_Y_CHART' && this.widget.elements.length === 1) {
          this.addWidgetElement({ yAxisNumber: 1 })
        }
      },
      { immediate: true }
    )
    this.$watch(
      () => this.widget,
      (newValue, oldValue) => {
        this.loadData()
        this.elements = {
          PAGE: [],
          TABLE: [],
          MULTIGRID_CHART: [],
          LINE_CHART: [],
          MULTIPLE_Y_CHART: [],
          LINE: [],
          PICTOGRAPH: [],
          CARD_CHART: [],
          BAR_CHART: []
        }
      },
      { immediate: true }
    )
  },
  methods: {
    addWidgetElement(element) {
      const order = this.widget.elements.reduce((prev, current) => (prev.order > current.order) ? prev.order : current.order, 0)
      this.widget.elements.push({
        type: this.widget.type,
        dataFilter: 'SHOW_ALL',
        dataName: null,
        department: null,
        hexacode: null,
        name: null,
        pageNumber: null,
        lineNumber: null,
        rawHexacode: null,
        serieType: 'line',
        compareTypeTooltip: 'NONE',
        showAverageValue: false,
        showHighestValue: false,
        showLowestValue: false,
        subDepartment: null,
        yAxisNumber: 0,
        buffer: null,
        color: null,
        showAxis: true,
        backgroundColor: null,
        symbol: null,
        compareTo: null,
        compareType: null,
        compareWith: null,
        compareToValue: null,
        pcNumber: null,
        useDefaultPcNumber: true,
        ...element,
        order: order + 1,
        id: uuidv4()
      })
    },
    removeWidgetElement(element) {
      const index = this.widget.elements.findIndex(e => e.id === element.id)
      if (index >= 0) {
        this.widget.elements.splice(index, 1)
      }
    },
    editWidgetElement({ element, selectedLine, allowBufferCreation }) {
      this.selectedWidgetElement = element
      this.selectedLine = selectedLine
      this.allowBufferCreation = allowBufferCreation
      this.widgetElementDetailsDialog = true
    },
    updateElement(element) {
      const index = this.widget.elements.findIndex(e => e.id === element.id)
      const e = this.widget.elements[index]
      Object.assign(e, element)
    },
    /**
     * Efficient data loading for each widget element
     * First we group by hexacode then by department/subdepartment and then by page
     */
    loadData() {
      const promises = []
      const byHexacode = groupBy(this.widget.elements, e => e.hexacode)
      this.widget.elements.forEach(element => { // for card widget, we have a nested element as compareTo
        if (element.compareTo) {
          if (byHexacode[element.compareTo.hexacode] === undefined) {
            byHexacode[element.compareTo.hexacode] = []
          }
          byHexacode[element.compareTo.hexacode].push(element.compareTo)
        }
      })
      Object.keys(byHexacode).forEach(hexacode => {
        const deviceEntry = findByHexacode(hexacode)
        if (deviceEntry !== null && deviceEntry.device.type !== 'BUSINESS_GROUP') { // for vs/rd load department names here
          this.$store.dispatch(`devices/${LOAD_DEPARTMENTS_NAMES}`, deviceEntry.device)
        }
        const byDepartment = groupBy(byHexacode[hexacode], e => e.department)
        Object.keys(byDepartment).forEach(department => {
          if (department > 0 && deviceEntry !== null && deviceEntry.device.type === 'BUSINESS_GROUP') { // in case we have departments selected from a device that's linked to bg
            const device = findByHexacodeAndNetworkNumber(hexacode, department)
            if (device !== null) {
              this.$store.dispatch(`devices/${LOAD_DEPARTMENTS_NAMES}`, device)
            }
          } else if (department === 0 && deviceEntry !== null && deviceEntry.device.type === 'BUSINESS_GROUP') { // normal bg loading, we have only a network controller
            this.$store.dispatch(`devices/${LOAD_DEPARTMENTS_NAMES}`, deviceEntry.device)
          }
          const bySubDepartment = groupBy(byDepartment[department], e => e.subDepartment)
          Object.keys(bySubDepartment).forEach(subDepartment => {
            const d = findByHexacodeAndDepartmentAndSubDepartment(hexacode, department, subDepartment)
            const byPageNumber = groupBy(bySubDepartment[subDepartment], e => e.pageNumber)
            Object.keys(byPageNumber).forEach(page => {
              if (d !== null) {
                let promise = Promise.resolve()
                if (!d.loaded) {
                  promise = this.$store.dispatch(`devices/${LOAD_PAGES}`, d)
                }
                promise = promise.then(() => {
                  const p = findByHexacodeAndDepartmentAndSubDepartmentAndPage(hexacode, department, subDepartment, page)
                  if (p !== null && !p.loaded) {
                    return this.$store.dispatch(`devices/${LOAD_LINES}`, {
                      hexacode: hexacode,
                      rawHexacode: null,
                      department: department,
                      subDepartment: subDepartment,
                      page: p
                    })
                  }
                })
                promises.push(promise)
              }
            })
          })
        })
      })
      // just show a single message for all the errors that can occur
      Promise.all(promises)
        .catch(e => this.showErrorNotification(this.$t('WidgetDetailsNode.LOADING_DATA_ERROR_MESSAGE')))
    }
  }
}
</script>
