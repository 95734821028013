<template>
  <v-card :loading="loading">
    <v-card-title>
      <v-icon>mdi-file-document</v-icon>{{page.number}}. {{page.name}}
    </v-card-title>
    <v-card-subtitle v-if="$store.state.settings.enablePageRefreshOnRequest && $GRANTS().RND.WRITE()">
      {{ $t('DevicePageView.REAL_TIME_DATA_ENABLED') }}
    </v-card-subtitle>
    <v-divider class="mx-4 pb-5"></v-divider>
    <v-card-text class="pt-5">
      <div v-if="lines.length === 0">{{$t('DevicePageView.NO_LINES_AVAILABLE_MESSAGE')}}</div>
      <DeviceLineView v-for="line in availableLines" :key="line.id" :line="line" @edit="toggleLineEdit"/>
    </v-card-text>
    <DeviceLineDetailsDialog :isVisible="showLineEditDialog" :line="selectedLine" @closed="toggleLineEdit" @update="findData"/>
  </v-card>
</template>
<script>
import {
  EXPAND_TO_PAGE,
  CHANGE_LANGUAGE
} from '@/store/mutations'
import DeviceLineView from '@/components/DeviceLineView.vue'
import DeviceLineDetailsDialog from '@/components/DeviceLineDetailsDialog.vue'
import { findLines, refreshLines } from '@/api/backend'
import { findByHexacode, findByHexacodeAndDepartmentAndSubDepartment } from '@/store/devices'

export default {
  name: 'DevicePageView',
  components: {
    DeviceLineView,
    DeviceLineDetailsDialog
  },
  data() {
    return {
      lines: [],
      device: null,
      department: null,
      loading: true,
      selectedLine: null,
      showLineEditDialog: false,
      subscriptions: {
        unsubscribeFromStore: null
      },
      taskId: null
    }
  },
  computed: {
    page() {
      if (this.department !== null) {
        const page = this.department.pages.find(p => p.number === parseInt(this.$route.params.page))
        if (page !== undefined) {
          return page
        }
      }
      return {
        number: this.$route.params.page,
        name: this.$t('DevicePageView.PAGE_DETAILS_MESSAGE')
      }
    },
    availableLines() {
      return this.lines.filter(l => {
        if (l.isFactorySettings) {
          return this.$store.state.settings.enableFactorySettingsLines
        }
        return true
      })
    }
  },
  created() {
    this.$watch(
      () => this.$store.state.devices.status,
      (value) => {
        if (value === 'LOADED') { // TODO export to constants
          this.findData()
        }
      }
    )
    this.$watch(
      () => this.$route.params,
      () => {
        if (this.$store.state.devices.status === 'LOADED') {
          this.findData()
        }
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
    this.subscriptions.unsubscribeFromStore = this.$store.subscribe((mutation, state) => {
      if (mutation.type === `devices/${CHANGE_LANGUAGE}`) {
        this.findLinesData()
      }
    })
  },
  beforeDestroy() {
    this.subscriptions.unsubscribeFromStore()
    clearTimeout(this.taskId)
  },
  methods: {
    findData() {
      this.loading = true
      const deviceEntry = findByHexacode(this.$route.params.hexacode)
      if (deviceEntry !== null) {
        this.device = deviceEntry.device
        this.department = findByHexacodeAndDepartmentAndSubDepartment(this.$route.params.hexacode, this.$route.params.department, this.$route.params.subDepartment)
        if (this.department !== null) {
          this.$store.dispatch(`devices/${EXPAND_TO_PAGE}`, this.$route.params)
          return this.findLinesData()
        }
        return this.redirectTo404WithMessage(this.$t('DevicePageView.DEPARTMENT_NOT_FOUND'))
      }
      return this.redirectTo404WithMessage(this.$t('DevicePageView.DEVICE_NOT_FOUND'))
    },
    findLinesData() {
      this.loading = true
      if (this.department.status === 'OFF' || this.department.status === 'OFF_ALARM') {
        this.showErrorNotification(this.$t('DevicePageView.DEPARTMENT_OFFLINE_MESSAGE', [this.department.name]))
        this.loading = false
        return
      }
      let request = Promise.resolve([])
      if (this.device.type === 'REAL_DEVICE') {
        request = refreshLines({
          ...this.$route.params,
          rawHexacode: this.device.rawHexacode,
          adf: this.department.adf,
          departmentName: this.department.name
        })
      } else {
        request = findLines(this.$route.params)
      }
      request.then(r => {
        this.lines = r.data
      }).catch(e => this.showErrorNotification(this.$t('DevicePageView.CANNOT_LOAD_LINES_MESSAGE', [this.department.name])))
        .then(() => {
          this.loading = false
          this.scheduleDataRefresh()
        })
    },
    scheduleDataRefresh() {
      clearTimeout(this.taskId)
      let interval = 1000 * 60
      if (this.$store.state.settings.enablePageRefreshOnRequest && this.device.type === 'REAL_DEVICE') {
        interval = 10
      }
      this.taskId = setTimeout(() => {
        if (!this.showLineEditDialog) {
          this.findLinesData()
        }
      }, interval)
    },
    redirectTo404WithMessage(message) {
      this.$router.push({
        name: '404',
        query: {
          message: message
        }
      })
    },
    toggleLineEdit(line) {
      this.showLineEditDialog = !this.showLineEditDialog
      this.selectedLine = line
      if (!this.showLineEditDialog) {
        this.scheduleDataRefresh()
      }
    }
  }
}
</script>
