<template>
  <v-dialog v-model="visible" width="500" persistent :fullscreen="fullscreen" scrollable eager overlay-opacity="0.65">
    <v-card :loading="loading" height="70vh">
      <v-card-title class="justify-space-between">
        <div>
          <v-icon>mdi-file-cog-outline</v-icon>
          <span class="pl-2">{{$t('DeviceConfigurationDialog.DEVICE_CONFIGURATION_MESSAGE')}}</span>
        </div>
        <div>
          <v-btn icon small @click="fullscreen = !fullscreen" color="secondary">
            <v-icon v-if="!fullscreen">mdi-arrow-expand</v-icon>
            <v-icon v-if="fullscreen">mdi-arrow-collapse</v-icon>
          </v-btn>
          <v-btn icon small @click="visible = false" color="secondary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <div class="container container--fluid">
        <div class="row">
          <div class="col-12 px-6">
          <v-text-field v-model="search" :label="$t('DeviceConfigurationDialog.SEARCH_LABEL_MESSAGE')" flat clearable persistent-hint :hint="searchHint"></v-text-field>
          </div>
        </div>
      </div>
      <v-card-text class="target">
        <v-select
          v-model="selectedSortingMode"
          :items="availableSortingModes"
          :label="$t('DeviceConfigurationDialog.SORTING_MODE_SELECT')"
          @change="updateDeviceConfigurationOrder"
        ></v-select>
        <v-select
          v-if="selectedSortingMode === 'NAME'"
          v-model="selectedSortingDirection"
          :items="availableSortingDirections"
          :label="$t('DeviceConfigurationDialog.SORTING_DIRECTION_SELECT')"
          @change="updateDeviceConfigurationOrder"
        ></v-select>
        <SlickList axis="y" v-model="items" helperClass="grey d-flex align-center" appendTo=".target" :lockToContainerEdges="true" :useDragHandle="true">
          <SlickItem v-for="(device, i) in items" :key="`device-${i}`" :index="i" :disabled="disableManualReordering">
            <ConfigurationNode :source="device">
              <template v-slot:prependIcon>
                <v-icon v-handle class="cursor-pointer custom" v-if="!disableManualReordering">mdi-drag-horizontal-variant</v-icon>
              </template>
            </ConfigurationNode>
          </SlickItem>
        </SlickList>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex w-100">
        <div class="pr-1 w-100">
          <v-btn outlined block color="secondary" @click="visible = false">{{$t('DeviceConfigurationDialog.CANCEL_MESSAGE')}}</v-btn>
        </div>
        <div class="pl-1 w-100">
          <v-btn outlined block color="primary" @click="saveDeviceConfiguration">{{$t('DeviceConfigurationDialog.SAVE_MESSAGE')}}</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { LOAD_DEVICES, UPDATE_DEVICE_CONFIGURATION_SETTINGS } from '@/store/mutations'
import ConfigurationNode from '@/components/device-configuration/ConfigurationNode.vue'
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'
import { saveConfiguration } from '@/api/backend.js'

export default {
  name: 'DeviceConfigurationDialog',
  props: {
    isVisible: Boolean
  },
  components: {
    ConfigurationNode, SlickList, SlickItem
  },
  directives: { handle: HandleDirective },
  data: function() {
    return {
      fullscreen: false,
      loading: false,
      search: '',
      devices: [],
      selectedSortingMode: this.$store.state.settings.deviceConfigurationSettings.sortMode,
      selectedSortingDirection: this.$store.state.settings.deviceConfigurationSettings.sortDirection
    }
  },
  computed: {
    visible: {
      get: function() {
        return this.isVisible
      },
      set: function(v) {
        this.$emit('closed')
      }
    },
    items: {
      get() {
        if (this.search === null) {
          return this.devices
        }
        const hint = this.search.toLowerCase()

        var result = this.devices.filter(n => {
          return n.name.toLowerCase().indexOf(hint) >= 0 ||
          n.hexacode.toLowerCase().indexOf(hint) >= 0
        })
        return result
      },
      set(devices) {
        this.devices = devices
      }
    },
    disableManualReordering() {
      return this.selectedSortingMode !== 'CUSTOM' || (
        this.selectedSortingMode === 'CUSTOM' &&
        this.search !== null &&
        this.search.trim().length > 0
      )
    },
    searchHint() {
      if (this.selectedSortingMode === 'CUSTOM' && this.search) {
        return this.$t('DeviceConfigurationDialog.SORTING_DISABLED_DURING_SEACHING')
      }
      return ''
    },
    availableSortingDirections() {
      return [
        { text: this.$t('DeviceConfigurationDialog.SORTING_DIRECTION.ASC'), value: 'ASC' },
        { text: this.$t('DeviceConfigurationDialog.SORTING_DIRECTION.DESC'), value: 'DESC' }
      ]
    },
    availableSortingModes() {
      return [
        { text: this.$t('DeviceConfigurationDialog.SORTING_TYPE.CUSTOM'), value: 'CUSTOM' },
        { text: this.$t('DeviceConfigurationDialog.SORTING_TYPE.NAME'), value: 'NAME' }
      ]
    }
  },
  methods: {
    extractComparableValue(device) {
      if (device.configuration.label !== null) {
        return device.configuration.label
      }
      return device.name
    },
    updateDeviceConfigurationOrder() {
      if (this.selectedSortingMode === 'NAME') {
        this.devices = this.devices.sort((l, r) => {
          const left = this.extractComparableValue(l)
          const right = this.extractComparableValue(r)
          if (this.selectedSortingDirection === 'ASC') {
            return left.localeCompare(right)
          }
          return right.localeCompare(left)
        })
      }
    },
    saveDeviceConfiguration() {
      const devices = []
      this.devices.forEach((device, index) => {
        const entry = {
          hexacode: device.rawHexacode,
          key: device.key,
          order: index,
          data: []
        }
        entry.data.push(device.configuration)
        device.departments.forEach(dep => {
          entry.data.push(dep.configuration)
        })
        if (device.type === 'BUSINESS_GROUP') {
          device.devices.forEach(dev => {
            entry.data.push(dev.configuration)
            dev.departments.forEach(dep => {
              entry.data.push(dep.configuration)
            })
          })
        }
        devices.push(entry)
      })
      this.loading = true
      const request = {
        settings: {
          sortMode: this.selectedSortingMode,
          sortDirection: this.selectedSortingDirection
        },
        devices: devices
      }
      saveConfiguration(request).then(response => {
        this.showSuccessNotification(this.$t('DeviceConfigurationDialog.CONFIGURTION_SAVED_MESSAGE'))
        this.$store.dispatch(UPDATE_DEVICE_CONFIGURATION_SETTINGS, request.settings)
        this.$store.dispatch(`devices/${LOAD_DEVICES}`) // Reload to apply the sorting
      }).catch(e => this.showErrorNotification(this.$t('DeviceConfigurationDialog.CONFIGURTION_SAVE_ERROR_MESSAGE', [e])))
        .then(e => {
          this.loading = false
          this.visible = false
        })
    }
  },
  watch: {
    isVisible: function(newValue, oldValue) {
      if (newValue) {
        this.search = ''
        const devices = this.clone(this.$store.state.devices.devices)
        devices.forEach(device => {
          device.expanded = false
          device.showInput = false
          device.initialLabel = device.configuration.label
          device.departments.forEach(dep => {
            dep.showInput = false
            dep.initialLabel = dep.configuration.label
          })
          if (device.type === 'BUSINESS_GROUP') {
            device.devices.forEach(dev => {
              device.expanded = false
              dev.showInput = false
              dev.initialLabel = dev.configuration.label
              dev.departments.forEach(dep => {
                dep.showInput = false
                dep.initialLabel = dep.configuration.label
              })
            })
          }
        })
        this.devices = devices
        this.selectedSortingMode = this.$store.state.settings.deviceConfigurationSettings.sortMode
        this.selectedSortingDirection = this.$store.state.settings.deviceConfigurationSettings.sortDirection
      }
    }
  }
}
</script>
<style lang="css" scoped>
* >>> .v-list-item__icon.v-list-group__header__prepend-icon {
  margin-right: 0px;
}
</style>
