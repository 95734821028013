export const LOAD_THEME = 'LOAD_THEME'
export const UPDATE_THEME_SETTINGS = 'UPDATE_THEME_SETTINGS'
export const LOAD_LOCAL_STORAGE = 'LOAD_LOCAL_STORAGE'
export const LOAD_DASHBOARDS = 'LOAD_DASHBOARDS'
export const LOAD_DASHBOARDS_AND_GROUPS = 'LOAD_DASHBOARDS_AND_GROUPS'
export const LOAD_DEVICES = 'LOAD_DEVICES'
export const LOAD_PAGES = 'LOAD_PAGES'
export const LOAD_LINES = 'LOAD_LINES'
export const LOADING = 'LOADING'
export const LOAD_ACCOUNT_SETTIINGS = 'LOAD_ACCOUNT_SETTINGS'
export const UPDATE_ACCOUNT_SETTINGS = 'UPDATE_ACCOUNT_SETTINGS'
export const LOGOUT = 'LOGOUT'
export const LOGIN = 'LOGIN'
export const UPDATE_DEVICE_CONFIGURATION_SETTINGS = 'UPDATE_DEVICE_CONFIGURATION_SETTINGS'
export const UPDATE_DASHBOARD_SORTING_SETTINGS = 'UPDATE_DASHBOARD_SORTING_SETTINGS'
export const UPDATE_DASHBOARDS_AND_GROUPS_ORDER = 'UPDATE_DASHBOARDS_AND_GROUPS_ORDER'
export const CREATE_DASHBOARD = 'CREATE_DASHBOARD'
export const DELETE_DASHBOARD = 'DELETE_DASHBOARD'
export const SELECT_DASHBOARD = 'SELECT_DASHBOARD'
export const RENAME_DASHBOARD = 'RENAME_DASHBOARD'
export const TOGGLE_DASHBOARD_EDIT = 'TOGGLE_DASHBOARD_EDIT'
export const UPDATE_FAVORITE_DASHBOARD = 'UPDATE_FAVORITE_DASHBOARD'
export const UPDATE_DEVICES_CONFIGURATION = 'UPDATE_DEVICES_CONFIGURATION'
export const LOAD_DEPARTMENTS_NAMES = 'LOAD_DEPARTMENTS_NAMES'
export const EXPAND_TO_PAGE = 'EXPAND_TO_PAGE'
export const REMOVE_LINES = 'REMOVE_LINES'
export const UPDATE_WIDGET_MARK_AREA_ELEMENTS = 'UPDATE_WIDGET_MARK_AREA_ELEMENTS'
export const UPDATE_WIDGET_POSITION = 'UPDATE_WIDGET_POSITION'
export const UPDATE_WIDGET_ELEMENT = 'UPDATE_WIDGET_ELEMENT'
export const RESTORE_WIDGET_ELEMENT = 'RESTORE_WIDGET_ELEMENT'
export const UPDATE_WIDGET = 'UPDATE_WIDGET'
export const RESTORE_WIDGET = 'RESTORE_WIDGET'
export const UPDATE_DASHBOARD = 'UPDATE_DASHBOARD'
export const RESTORE_DASHBOARD = 'RESTORE_DASHBOARD'

export const HANDLE_DEVICE_STATUS_EVENT = 'HANDLE_DEVICE_STATUS_EVENT'

export const LOAD_MANIFEST = 'LOAD_MANIFEST'
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const DELETE_DASHBOARDS_GROUP = 'DELETE_DASHBOARDS_GROUP'
export const SORT_DASHBOARDS_AND_GROUPS = 'SORT_DASHBOARDS_AND_GROUPS'
export const CREATE_DASHBOARDS_GROUP = 'CREATE_DASHBOARDS_GROUP'
export const UPDATE_DASHBOARDS_AND_GROUPS_SORTING_SETTINGS = 'UPDATE_DASHBOARDS_AND_GROUPS_SORTING_SETTINGS'
export const UPDATE_DASHBOARDS_GROUP = 'UPDATE_DASHBOARDS_GROUP'

export const REGISTER_VAPID_SUBSCRIPTION = 'REGISTER_VAPID_SUBSCRIPTION'
export const DEREGISTER_VAPID_SUBSCRIPTION = 'DEREGISTER_VAPID_SUBSCRIPTION'

export const SWITCH_ACCOUNT = 'SWITCH_ACCOUNT'
export const OPEN_WIDGET_DETAILS_DIALOG = 'OPEN_WIDGET_DETAILS_DIALOG'
