import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '@/layouts/MainLayout.vue'
import Home from '@/components/Home.vue'
import DevicePageView from '@/components/DevicePageView.vue'
import Login from '@/pages/Login.vue'
import store from '@/store'
import Dashboard from '@/components/dashboards/Dashboard.vue'
// import { grantsCheck } from '@/store/security/grants.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: MainLayout,
    meta: {
      grants: {}
    },
    children: [
      {
        path: '/dashboard/:id',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          grants: {}
        }
      }, {
        path: '/:hexacode/:department/:subDepartment/pages/:page',
        name: 'page',
        component: DevicePageView,
        meta: {
          grants: {}
        }
      },
      {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
          grants: {}
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/pages/ForgotPassword.vue')
  },
  {
    path: '/recover-password',
    name: 'recover-password',
    component: () => import('@/pages/RecoverPassword.vue')
  },
  {
    path: '/setup-password',
    name: 'setup-password',
    component: () => import('@/pages/SetupPassword.vue')
  },
  {
    name: 'Unauthorized',
    path: '/unauthorized',
    component: Login
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    name: '404',
    component: () => import('@/pages/Error404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ left: 0, top: 0 }),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.grants)) {
    if (store.getters['security/apiToken'] === null) {
      return next({ name: 'login' })
    }
  }
  if (to.name === 'login' && store.getters['security/apiToken'] !== null) {
    return next({ name: 'home' })
  }
  return next()
})

export default router
