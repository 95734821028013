<template>
<div>
  <v-menu :value="visible" offset-y open-on-click :close-on-content-click="false" left max-height="640" max-width="350" min-width="350" @input="visible = $event">
   <template v-slot:activator="{ attrs }">
      <v-badge bordered color="error" :content="alarmsCounter" :value="alarmsCounter > 0" offset-x="20" offset-y="22">
        <v-btn icon dense plain v-bind="attrs" @click="visible = !visible" color="secondary">
          <v-icon>mdi-bell-outline</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-list>
      <v-list-item v-if="alarmsCounter > 0">
        {{$t("AlarmsMenu.DEPARTMENTS_WITH_ALARMS_MESSAGE", [alarmsCounter])}}
      </v-list-item>
      <v-list-item v-if="alarmsCounter > 0">
        <v-text-field
          v-model="searchHint"
          :label="$t('AlarmsMenu.SEARCH_MESSAGE')"
          flat
          hide-details
          clear-icon="mdi-close-circle-outline"
        ></v-text-field>
      </v-list-item>
      <v-list-item v-else>
        {{$t("AlarmsMenu.NO_ALARMS_MESSAGE")}}
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </v-list>
    <div class="text-center">
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-sheet>
      <div class="overflow-y-auto w-100 px-3" style="max-height: 350px;">
        <DynamicScroller
          :items="alarmsComputed"
          :min-item-size="85"
          :page-mode="true"
          :keyField="'hexacode'"
          style="height: inherit">
          <template v-slot="{ item, index, active }">
            <DynamicScrollerItem
              :item="item"
              :active="active"
              :data-index="index">
              <AlarmNode :data="item" @click="visible = false"/>
            </DynamicScrollerItem>
          </template>
        </DynamicScroller>
      </div>
      <div class="pa-3">
        <v-btn class="mb-1" block outlined color="primary" @click="showAlarmHistoryDialog = true">
          <v-icon>mdi-clipboard-text-clock-outline</v-icon>
          <span>{{$t("AlarmsMenu.ALARM_HISTORY_MESSAGE")}}</span>
        </v-btn>
        <v-btn class="mt-1" block outlined color="primary" @click="testNotification">
          <v-icon>mdi-bell-ring</v-icon>
          <span>{{$t("AlarmsMenu.TEST_ALARM")}}</span>
        </v-btn>
      </div>
    </v-sheet>
  </v-menu>
  <AlarmHistoryDialog :isVisible="showAlarmHistoryDialog" @closed="showAlarmHistoryDialog = !showAlarmHistoryDialog"></AlarmHistoryDialog>
  <NotificationsBlockedDialog :isVisible="showNotificationsBlockedDialog" @closed="showNotificationsBlockedDialog = !showNotificationsBlockedDialog"></NotificationsBlockedDialog>
</div>
</template>
<script>
import AlarmNode from '@/components/alarms/AlarmNode.vue'
import AlarmHistoryDialog from '@/components/alarms/AlarmHistoryDialog.vue'
import { getInitialAlarms, sendTestNotification } from '@/api/backend'
import { CHANGE_LANGUAGE } from '@/store/mutations'
import NotificationsBlockedDialog from '@/components/alarms/NotificationsBlockedDialog.vue'

export default {
  name: 'AlarmsMenu',
  components: {
    AlarmNode,
    AlarmHistoryDialog,
    NotificationsBlockedDialog
  },
  data() {
    return {
      visible: false,
      searchHint: '',
      showAlarmHistoryDialog: false,
      showNotificationsBlockedDialog: false,
      ubsubscribeFromStore: null,
      unsubscribeFromAlarmsEventsStream: null,
      alarmsCounter: 0,
      alarms: [],
      alarmsFastAccess: new Map(),
      loading: false
    }
  },
  computed: {
    alarmsComputed() {
      const hint = this.searchHint.toLowerCase()
      let results = this.alarms
      if (hint !== '') {
        results = this.alarms.filter(alarm =>
          (alarm.hexacode !== null && alarm.hexacode.toLowerCase().indexOf(hint) >= 0) ||
          (alarm.name !== null && alarm.name.toLowerCase().indexOf(hint) >= 0)
        )
      }
      return results
    }
  },
  mounted() {
    this.getInitialAlarms()
    this.unsubscribeFromAlarmsEventsStream = this.$getEventStreamWithFilter(e => e.type === 'DeviceAlarmEntry' || e.type === 'BusinessGroupAlarmEntry').subscribe(this.handleAlarmEvent)
    this.ubsubscribeFromStore = this.$store.subscribeAction((action, state) => {
      if (action.type === CHANGE_LANGUAGE) {
        this.getInitialAlarms()
      }
    })
  },
  beforeDestroy() { // TODO recheck the behaviour subject unsubscribe method???
    // this.unsubscribeFromAlarmsEventsStream.unsubscribe()
    // this.ubsubscribeFromStore.unsubscribe()
  },
  methods: {
    getInitialAlarms() {
      this.loading = true
      getInitialAlarms().then(r => {
        this.handleInitialAlarms(r.data)
        this.loading = false
      })
    },
    handleInitialAlarms(alarms) {
      this.alarms = []
      this.alarmsCounter = 0
      this.alarmsFastAccess = new Map()
      alarms.forEach(a => {
        this.alarms.push(a)
        this.alarmsCounter += a.size
        this.alarmsFastAccess.set(a.hexacode, this.alarms.length - 1)
      })
    },
    handleAlarmEvent(event) {
      const i = this.alarmsFastAccess.get(event.hexacode)
      if (event.empty) { // alarm removed
        if (i !== undefined && i >= 0) {
          this.alarms.splice(i, 1)
        }
      } else { // alarm update received
        if (i !== undefined && i >= 0) { // updated alarm received
          this.alarms.splice(i, 1)
        }
        this.alarms.unshift(event)
      }
      this.handleInitialAlarms([...this.alarms])
    },
    testNotification1() {
      const notificationOptions = {
        icon: '/api/themes/manifest/icon/_192x192.png',
        badge: '/api/themes/manifest/icon/_192x192.png',
        tag: new Date(),
        body: 'Here is your demo notification',
        data: '',
        vibrate: [200, 100, 200, 100, 200, 100, 200]

      }
      if ('serviceWorker' in navigator) {
        Notification.requestPermission().then(status => {
          if (status === 'granted') {
            navigator.serviceWorker.ready.then(registration => {
              registration.showNotification(`Welcome to ${this.$store.state.theme.name}`, notificationOptions)
            })
          } else {
            this.showNotificationsBlockedDialog = true
          }
        })
      }
    },
    testNotification() {
      if ('serviceWorker' in navigator) {
        Notification.requestPermission().then(status => {
          if (status === 'granted') {
            sendTestNotification().then(r => {
              console.log('Test notification sent successfully!')
            }).catch(e => console.log(e))
          } else {
            this.showNotificationsBlockedDialog = true
          }
        })
      }
    }
  }

}
</script>
<style scoped>
* >>> .theme--light.v-badge .v-badge__badge:after,
* >>> .theme--dark.v-badge .v-badge__badge:after {
  border-color: transparent !important;
}
</style>
