import store from '@/store'
export const grantsCheck = function(config) {
  if (config.type === GRANT_CONFIG_TYPE.ALL) {
    for (const grant of config.value) {
      if (!grant()) {
        return false
      }
    }
    return true
  } else if (config.type === GRANT_CONFIG_TYPE.ANY) {
    for (const grant of config.value) {
      if (grant()) {
        return true
      }
    }
    return false
  }
  return false
}
export const hasAccess = function(grant, requiredType) {
  let result = false
  const assignedGrants = store.getters['security/grants']
  if (assignedGrants !== null) {
    const assignedType = assignedGrants[grant]
    if (assignedType === undefined) {
      result = false
    } else if (GRANT_TYPE[assignedType] < GRANT_TYPE[requiredType]) {
      result = false
    } else {
      result = true
    }
  }
  console.log(grant, requiredType, result)
  return result
}
export const GRANT_CONFIG_TYPE = {
  ALL: 'ALL',
  ANY: 'ANY'
}
export const GRANT_TYPE = {
  DENY: 0,
  READ: 1,
  WRITE: 2
}
export const GRANTS_MIXIN = {
  $GRANTS() {
    return GRANTS
  }
}
// replace this with api call
const AVAILABLE_GRANTS = [
  'SYSTEM', // special grant
  'MFA_REQUIRED',
  'SECURITY', 'APPLY_ACL', 'RND',
  'ACCOUNTS',
  'DEVICES', 'DEVICE_TAGS', 'DEVICE_INFO_EXTENDED', 'DEVICE_BLACKLIST', 'DEVICE_MAP', 'DEVICE_ASSIGNED_CLIENTS', 'DEVICE_EXPORT', 'DEVICE_CONTROL_PANEL',
  'ADF_FILES', 'DEALERS', 'SUBDEALERS', 'DOMAINS', 'BUFFERS',
  'EXPORT_MONITOR', 'FTP_ACCOUNTS', 'MAIL_ACCOUNTS', 'SUBSCRIPTION_PLANS', 'ACCESS_GROUPS',
  'SUPPORT', 'VIRTUAL_DEVICES',
  'EXPORT_TEMPLATES'
]

const mapGrant = function(grant) {
  const result = {}
  result[grant] = {
    READ: () => hasAccess(`GRANT_${grant}`, 'READ'),
    WRITE: () => hasAccess(`GRANT_${grant}`, 'WRITE')
  }
  return result
}

export const GRANTS = AVAILABLE_GRANTS.map(mapGrant).reduce((accumulator, target) => {
  Object.keys(target).forEach(key => {
    accumulator[key] = target[key]
  })
  return accumulator
}, {})
