<template>
  <v-dialog v-model="visible" width="600" persistent :fullscreen="fullscreen" scrollable overlay-opacity="0.65">
    <ValidationObserver ref="validator" @submit.prevent="saveAccountSettings" tag="form" eager>
      <v-card :loading="loading">
        <v-card-title class="justify-space-between">
          <div>
            <div>{{$t("AccountSettingsDialog.ACCOUNT_SETTINGS_MESSAGE")}}</div>
          </div>
          <div>
            <v-btn icon small @click="fullscreen = !fullscreen" color="secondary">
              <v-icon v-if="!fullscreen">mdi-arrow-expand</v-icon>
              <v-icon v-if="fullscreen">mdi-arrow-collapse</v-icon>
            </v-btn>
            <v-btn icon small @click="visible = false" color="secondary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider class="mx-4 pb-5"></v-divider>
        <v-card-text class="pt-5">
          <ValidationProvider rules="required" :name="$t('AccountSettingsDialog.LANGUAGE_MESSAGE')" v-slot="{ errors, valid }">
            <v-select
              v-model="personalSettings.language"
              :items="languages"
              item-text="label"
              item-value="item"
              :label='$t("AccountSettingsDialog.LANGUAGE_MESSAGE")'
              return-object
              required
              :error-messages="errors"
              :success="valid"/>
          </ValidationProvider>
          <ValidationProvider rules="required" :name="$t('AccountSettingsDialog.DATE_AND_TIME_FORMAT_MESSAGE')" v-slot="{ errors, valid }">
            <v-select
            v-model="personalSettings.dateFormat"
            :items="dateAndTimeFormats"
            :label="$t('AccountSettingsDialog.DATE_AND_TIME_FORMAT_MESSAGE')"
            return-object
            required
            :error-messages="errors"
            :success="valid"/>
          </ValidationProvider>
          <ValidationProvider rules="email" :name="$t('AccountSettingsDialog.EMAIL_LABEL_MESSAGE')" v-slot="{ errors, valid }">
            <v-text-field v-model="personalSettings.email" :label="$t('AccountSettingsDialog.EMAIL_LABEL_MESSAGE')" :error-messages="errors" :success="valid"></v-text-field>
          </ValidationProvider>
          <ValidationProvider rules="required" :name="$t('AccountSettingsDialog.DEFAULT_PC_NUMBER')" v-slot="{ errors, valid }" v-if="personalSettings.pcNumbers.length > 1 || $GRANTS().APPLY_ACL.WRITE()">
            <v-autocomplete
              v-model="personalSettings.defaultPcNumber"
              :items="availablePcNumbers"
              :label="$t('AccountSettingsDialog.DEFAULT_PC_NUMBER')"
              :error-messages="errors" :success="valid"
              hide-details dense attach></v-autocomplete>
          </ValidationProvider>
          <v-switch
            :disabled="!hasEmail"
            v-model="personalSettings.use2FA"
            :label='$t("AccountSettingsDialog.USE_2FA")'
            color="primary"
            :value="personalSettings.use2FA"
            :false-value="false"
            :true-value="true"
            @change="update2faSettings"
            :hint='$t("AccountSettingsDialog.USE_2FA_HINT")'
            persistent-hint/>
          <v-switch
            v-model="personalSettings.showDepartmentNumbers"
            :label='$t("AccountSettingsDialog.DEPARTMENT_NUMBERS_MESSAGE")'
            color="primary"
            :value="personalSettings.showDepartmentNumbers"
            :false-value="false"
            :true-value="true"
            hide-details/>
          <v-switch
            v-model="personalSettings.showNetworkControllers"
            :label='$t("AccountSettingsDialog.NETWORK_CONTROLLER_MESSAGE")'
            color="primary"
            :value="personalSettings.showNetworkControllers"
            :false-value="false"
            :true-value="true"
            hide-details/>
          <v-switch
            v-model="personalSettings.showDevicesSearchBar"
            :label='$t("AccountSettingsDialog.DEVICES_SEARCH_BAR_MESSAGE")'
            color="primary"
            :value="personalSettings.showDevicesSearchBar"
            :false-value="false"
            :true-value="true"
            hide-details/>
          <v-switch
            v-model="personalSettings.showHexacodesAsDeviceNames"
            :label='$t("AccountSettingsDialog.DEVICE_HEXACODE_MESSAGE")'
            color="primary"
            :value="personalSettings.showHexacodesAsDeviceNames"
            :false-value="false"
            :true-value="true"
            hide-details/>
          <v-switch
            v-if="$GRANTS().RND.WRITE()"
            v-model="personalSettings.enablePageRefreshOnRequest"
            :label='$t("AccountSettingsDialog.ENABLE_REAL_TIME_COMMUNICATION")'
            color="primary"
            :value="personalSettings.enablePageRefreshOnRequest"
            :false-value="false"
            :true-value="true"
            hide-details/>
          <v-switch
            v-if="$GRANTS().RND.WRITE()"
            v-model="personalSettings.enableFactorySettingsLines"
            :label='$t("AccountSettingsDialog.ENABLE_FACTORY_SETTINGS_LINES")'
            color="primary"
            :value="personalSettings.enableFactorySettingsLines"
            :false-value="false"
            :true-value="true"
            hide-details/>
          <v-switch
            v-model="personalSettings.enableAlarmEmailExport"
            :label='$t("AccountSettingsDialog.EMAIL_ALARM_EXPORT_MESSAGE")'
            color="primary"
            :value="personalSettings.enableAlarmEmailExport"
            :false-value="false"
            :true-value="true"
            hide-details/>
          <ValidationProvider rules="email|required" :name="$t('AccountSettingsDialog.EMAIL_ADDRESS_MESSAGE')" v-slot="{ errors, valid }" v-if="personalSettings.enableAlarmEmailExport">
            <v-combobox
              v-model="personalSettings.alarmEmailExportAddresses"
              :label='$t("AccountSettingsDialog.EMAIL_ADDRESS_MESSAGE")'
              chips
              clearable
              multiple
              :error-messages="errors" :success="valid"
              append-icon="">
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip small v-bind="attrs" :input-value="selected" close @click="select" @click:close="removeEmailAddress(item)">{{ item }}</v-chip>
              </template>
            </v-combobox>
          </ValidationProvider>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex w-100">
            <div class="pr-1 w-100">
              <v-btn outlined block color="accent" @click="visible = false">{{$t("AccountSettingsDialog.CANCEL_MESSAGE")}}</v-btn>
            </div>
            <div class="pl-1 w-100">
              <v-btn outlined block color="primary" type="submit">{{$t("AccountSettingsDialog.SAVE_MESSAGE")}}</v-btn>
            </div>
        </v-card-actions>
      </v-card>
      <v-dialog v-model="showQrCode" persistent scrollable overlay-opacity="0.55" max-width="400">
        <OTPQrCode :setupKey="twoFactorAuthSetupKey" @done="showQrCode = !showQrCode" @cancel="reset2FA" v-if="twoFactorAuthSetupKey"/>
      </v-dialog>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { findAccountSettings, saveAccountSettings, findAvailableLanguages, setup2Fa } from '@/api/backend.js'
import { UPDATE_ACCOUNT_SETTINGS } from '@/store/mutations'
import OTPQrCode from '@/components/OTPQrCode.vue'

export default {
  name: 'AccountSettingsDialog',
  props: {
    isVisible: Boolean
  },
  components: {
    ValidationObserver, ValidationProvider, OTPQrCode
  },
  data: function() {
    return {
      fullscreen: false,
      loading: false,
      personalSettings: this.$store.state.settings,
      dateAndTimeFormats: ['DD-MM-YYYY HH:mm', 'MM-DD-YYYY HH:mm', 'YYYY-MM-DD HH:mm'],
      languages: [],
      twoFactorAuthSetupKey: null,
      showQrCode: false
    }
  },
  computed: {
    visible: {
      get: function() {
        return this.isVisible
      },
      set: function(v) {
        this.$emit('closed')
      }
    },
    hasEmail() {
      return this.personalSettings.email !== null && this.personalSettings.email.length > 0
    },
    availablePcNumbers() {
      return this.$store.getters.availablePcNumbers
    }
  },
  methods: {
    async saveAccountSettings() {
      const result = await this.$refs.validator.validate()
      if (!result) {
        return
      }
      this.loading = true
      saveAccountSettings(this.personalSettings).then(r => {
        this.visible = false
        this.showSuccessNotification(this.$t('AccountSettingsDialog.ACCOUNT_SETTINGS_SAVED_MESSAGE'))
        this.$store.dispatch(UPDATE_ACCOUNT_SETTINGS, r.data)
      }).catch(e => this.showErrorNotification(this.$t('AccountSettingsDialog.ACCOUNT_SETTINGS_SAVED_ERROR_MESSAGE')))
        .then(e => { this.loading = false })
    },
    findAccountSettings() {
      this.loading = true
      findAccountSettings().then(r => {
        this.personalSettings = r.data
        this.twoFactorAuthSetupKey = null
      }).catch(e => this.showErrorNotification(this.$t('AccountSettingsDialog.ACCOUNT_SETTINGS_LOADING_ERROR_MESSAGE')))
        .then(e => { this.loading = false })
    },
    findAvailableLanguages() {
      this.loading = true
      findAvailableLanguages().then(r => {
        this.languages = r.data
      }).catch(e => this.showErrorNotification(this.$t('AccountSettingsDialog.LANGUAGES_LOADING_ERROR_MESSAGE')))
        .then(e => { this.loading = false })
    },
    removeEmailAddress(address) {
      const index = this.personalSettings.alarmEmailExportAddresses.indexOf(address)
      if (index >= 0) {
        this.personalSettings.alarmEmailExportAddresses.splice(index, 1)
      }
    },
    update2faSettings() {
      if (this.personalSettings.use2FA) {
        this.loading = true
        setup2Fa().then(r => {
          this.twoFactorAuthSetupKey = r.data
          this.showQrCode = true
        }).catch(e => {
          this.showErrorNotification(this.$t('AccountSettingsDialog.ERROR_LOADING_QR_CODE'))
          this.personalSettings.use2FA = false
        })
          .then(e => {
            this.loading = false
          })
      }
    },
    reset2FA() {
      this.personalSettings.use2FA = false
      this.showQrCode = false
    }
  },
  watch: {
    isVisible: function(newValue, oldValue) {
      if (newValue) {
        this.findAvailableLanguages()
        this.findAccountSettings()
      }
    }
  }
}
</script>
