<template>
  <v-component v-bind:is="component" :widget="widget" :editMode="editMode" :eventStream="eventStream" v-on="$listeners">
    <template v-slot:edit>
      <v-btn icon color="primary" v-if="editMode" @click="$emit('edit', widget)">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
    </template>
    <template v-slot:delete>
      <v-btn icon color="accent" v-if="editMode" @click="$emit('delete', widget)">
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </template>
    <template v-slot:fullscreen>
      <v-btn icon color="primary" @click="toggleFullscreen">
        <v-icon>{{fullscreenIcon}}</v-icon>
      </v-btn>
    </template>
  </v-component>
</template>
<script>
export default {
  name: 'Widget',
  props: {
    widget: Object,
    editMode: Boolean,
    eventStream: Object
  },
  data: () => {
    return {
      fullscreen: false
    }
  },
  computed: {
    component() {
      if (this.widget.type === 'LINE') {
        return () => import('@/components/dashboards/widgets/LineWidget')
      }
      if (this.widget.type === 'PAGE') {
        return () => import('@/components/dashboards/widgets/PageWidget')
      }
      if (this.widget.type === 'TABLE') {
        return () => import('@/components/dashboards/widgets/TableWidget')
      }
      return () => import('@/components/dashboards/widgets/GraphWidget')
    },
    fullscreenIcon() {
      if (this.fullscreen) {
        return 'mdi-arrow-collapse'
      }
      return 'mdi-arrow-expand'
    }
  },
  methods: {
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen
      this.$emit('toggleFullscreen', this.fullscreen)
    }
  }
}
</script>
