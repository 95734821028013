<template>
  <v-dialog max-width="600" v-model="visible" :fullscreen="fullscreen" scrollable persistent overlay-opacity="0.65">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :attrs="attrs" :on="on" />
    </template>
    <v-card :loading="loading">
      <v-card-title class="justify-space-between">
        <div>
          <v-icon>mdi-account-switch-outline</v-icon>
          <span class="pl-2">{{$t('SwitchAccountForm.TITLE')}}</span>
        </div>
        <div>
          <v-btn icon small @click="fullscreen = !fullscreen" color="secondary">
            <v-icon v-if="!fullscreen">mdi-arrow-expand</v-icon>
            <v-icon v-if="fullscreen">mdi-arrow-collapse</v-icon>
          </v-btn>
          <v-btn icon small @click="visible = false" color="secondary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <div class="row pb-5">
          <div class="col-12 col-md-3">
            <v-select v-model="pageLength" :items="pageLengthOptions" hide-details label="Page length" @change="pageNumber=1" item-value="value" item-text="text" attach></v-select>
          </div>
          <v-spacer></v-spacer>
          <div class="col-12 col-md-3 d-flex align-end">
            <v-text-field :value="searchHint" label="Search" hide-details append-icon="mdi-magnify" @input="searchAccounts"></v-text-field>
          </div>
        </div>
        <v-data-table
          :headers="headers"
          :items="accounts"
          :items-per-page.sync="pageLength"
          :sort-by.sync="sortField"
          :sort-desc.sync="sortDesc"
          :page="pageNumber"
          :server-items-length="recordsFiltered"
          :loading="loading"
          :must-sort="true"
          hide-default-footer
          class="accounts-table"
        >
          <template v-slot:[`item.roles`]="{ item }">
            <v-chip dense class="mr-1 mb-1" color="primary" x-small v-for="role in item.roles" :key="item.id + role">{{role}}</v-chip>
          </template>
          <template v-slot:[`item.domains`]="{ item }">
            <v-chip color="blue lighten-2" class="mr-1 mb-1" x-small dark v-for="domain in item.domains" :key="item.id + domain">{{domain}}</v-chip>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <div class="d-flex justify-end">
              <v-btn icon @click="selectAccount(item)">
                <v-icon>mdi-account-sync-outline</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="pageNumber" circle :total-visible="5" :length="Math.ceil(recordsFiltered/pageLength)"></v-pagination>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex w-100">
        <div class="pr-1 w-100">
          <v-btn outlined block color="accent" @click="visible = false" >{{$t('SwitchAccountForm.CANCEL_MESSAGE')}}</v-btn>
        </div>
        <div class="pl-1 w-100">
          <v-btn outlined block color="primary" type="submit">{{$t("SwitchAccountForm.APPLY_MESSAGE")}}</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { switchAccount, paginateAccounts } from '@/api/backend'
import { SWITCH_ACCOUNT } from '@/store/mutations.js'
import { debounce } from '@/mixin.js'
export default {
  name: 'SwitchAccountForm',
  data() {
    return {
      isVisible: false,
      fullscreen: true,
      loading: false,
      searchHint: '',
      accounts: [],
      draw: 1,
      recordsFiltered: 1,
      pageLength: 20,
      pageNumber: 1,
      sortField: ['username'],
      sortDesc: [false],
      pageLengthOptions: [
        { text: '10', value: 10 },
        { text: '20', value: 20 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ],
      headers: [
        { order: 1, align: 'left', text: 'Username', value: 'username', sortable: true, class: 'text-no-wrap', visible: true },
        { order: 2, align: 'left', text: 'Email', value: 'email', sortable: true, class: 'text-no-wrap', visible: true },
        { order: 4, align: 'left', text: 'Roles', value: 'roles', sortable: false, class: 'text-no-wrap', visible: true },
        { order: 5, align: 'left', text: 'Domains', value: 'domains', sortable: false, class: 'text-no-wrap', visible: true },
        { order: 15, align: 'right', text: 'Actions', value: 'id', sortable: false, class: 'text-no-wrap', visible: true }
      ]
    }
  },
  computed: {
    visible: {
      get() {
        return this.isVisible
      },
      set(visible) {
        this.isVisible = visible
        if (visible) {
          this.loadData()
        }
      }
    },
    paginationRequest() {
      const paginationRequest = { }
      paginationRequest.sortField = this.sortField[0]
      paginationRequest.sortDirection = this.sortDesc[0] ? 'DESC' : 'ASC'
      paginationRequest.pageNumber = this.pageNumber - 1
      paginationRequest.pageLength = this.pageLength
      paginationRequest.draw = this.draw
      paginationRequest.searchHint = this.searchHint
      return paginationRequest
    }
  },
  methods: {
    loadData() {
      this.loading = true
      paginateAccounts(this.paginationRequest).then(r => {
        this.accounts = r.data.data
        this.recordsFiltered = r.data.recordsFiltered
      }).catch((e) => this.showErrorNotification(this.$t('SwitchAccountForm.ERROR_MESSAGE')))
        .then((r) => { this.loading = false })
    },
    selectAccount(account) {
      this.loading = true
      switchAccount(account.id).then(r => {
        this.$store.dispatch(`security/${SWITCH_ACCOUNT}`, r.data).then(() => {
          window.location = window.origin
        })
      }).catch((e) => this.showErrorNotification(this.$t('SwitchAccountForm.ERROR_MESSAGE')))
        .then((r) => { this.loading = false })
    },
    searchAccounts: debounce(function(e) {
      this.searchHint = e
    }, 500)
  },
  watch: {
    paginationRequest: function(newValue, oldValue) {
      if (newValue.sortDirection === oldValue.sortDirection &&
      newValue.sortField === oldValue.sortField &&
      newValue.pageNumber === oldValue.pageNumber &&
      this.pageNumber !== 1) { // the page number must be 1 when a filter value changes
        this.pageNumber = 1
      } else {
        this.loadData()
      }
    }
  }
}
</script>
