import LocalStorage from '@/plugins/local-storage.js'
import router from '@/router'
import i18n from '@/i18n.js'
import { logout } from '@/api/backend.js'

import { REGISTER_VAPID_SUBSCRIPTION, DEREGISTER_VAPID_SUBSCRIPTION, LOAD_LOCAL_STORAGE, LOGOUT, LOGIN, SWITCH_ACCOUNT } from '@/store/mutations.js'

export default {
  namespaced: true,
  state: {
    currentUser: null,
    roles: [],
    grants: null
  },
  getters: {
    apiToken(state) {
      if (state.currentUser !== null) {
        return state.currentUser.token.id
      }
      return null
    },
    roles(state) {
      return state.roles
    },
    grants(state) {
      return state.grants
    },
    username(state) {
      if (state.currentUser !== null) {
        return state.currentUser.metadata.username
      }
      return null
    },
    isLoggedInAs(state) {
      return state.currentUser !== null &&
        state.currentUser.token.source !== undefined &&
        state.currentUser.token.source !== null
    }
  },
  mutations: {
    [LOGIN](state, currentUser) {
      state.currentUser = currentUser
      const grants = {}
      state.currentUser.token.authorities.forEach((a) => {
        grants[a.authority] = a.type
      })
      state.grants = grants
      state.currentUser.token.authorities.forEach((a) =>
        state.roles.push(a.authority)
      )
      this._vm.$connect(state.currentUser.token.id)
      LocalStorage.put('web-current-user', currentUser)
      i18n.locale = state.currentUser.metadata.languageCode.toLowerCase()
    },
    [LOGOUT](state) {
      state.currentUser = null
      state.roles = []
      state.grants = null
      LocalStorage.remove('web-current-user')
      this._vm.$disconnect()
      i18n.locale = 'en'
    },
    [LOAD_LOCAL_STORAGE](state) {
      const currentUser = LocalStorage.get('web-current-user')
      if (currentUser !== null && currentUser.token) {
        state.currentUser = currentUser
        state.currentUser.token.authorities.forEach((a) =>
          state.roles.push(a.authority)
        )
        const grants = {}
        state.currentUser.token.authorities.forEach((a) => {
          if (a.type) {
            grants[a.authority] = a.type
          } else if (a.domain) {
            grants[a.authority] = 'READ'
          } else {
            grants[a.authority] = 'READ'
          }
        })
        state.grants = grants
        this._vm.$connect(state.currentUser.token.id)
      }
    },
    [REGISTER_VAPID_SUBSCRIPTION](state, subscription) {
      state.currentUser.metadata.vapidSubscription = subscription
      LocalStorage.put('web-current-user', state.currentUser)
    },
    [DEREGISTER_VAPID_SUBSCRIPTION](state) {
      state.currentUser.metadata.vapidSubscription = null
      LocalStorage.put('web-current-user', state.currentUser)
    }
  },
  actions: {
    [REGISTER_VAPID_SUBSCRIPTION](context, subscription) {
      context.commit(REGISTER_VAPID_SUBSCRIPTION, subscription)
    },
    [DEREGISTER_VAPID_SUBSCRIPTION](context) {
      context.commit(DEREGISTER_VAPID_SUBSCRIPTION)
    },
    [LOAD_LOCAL_STORAGE](context) {
      if (context.state.currentUser !== null) {
        return Promise.resolve()
      }
      return Promise.resolve(context.commit(LOAD_LOCAL_STORAGE))
    },
    [LOGIN](context, currentUser) {
      return Promise.resolve(context.commit(LOGIN, currentUser))
    },
    [LOGOUT](context) {
      return logout()
        .catch(() => {})
        .then(() => context.commit(LOGOUT))
        .then(() => router.push({ name: 'login' })) // put for each domain the domain here
    },
    [SWITCH_ACCOUNT](context, token) {
      context.commit(LOGOUT)
      context.commit(LOGIN, token)
    }
  }
}
