<template>
  <v-btn-toggle class="row w-100 pl-5" :value="type" @change="onTypeSelection">
    <div class="col-12 pb-0 text--secondary">{{$t('WidgetTypeSelector.REALTIME_DATA_SECTION')}}</div>
    <div class="col-4" v-for="(availableType, i) in realtimeAvailableTypes" :key="availableType.value+i">
      <v-btn color="primary" block outlined class="text-truncate w-100 h-100" :value="availableType.value">
        <div class="py-4">
          <component v-bind:is="availableType.component" class="pb-4 text-h2"/>
          <div>{{availableType.name}}</div>
        </div>
      </v-btn>
    </div>
    <div class="col-12 pb-0 text--secondary">{{$t('WidgetTypeSelector.HISTORICAL_DATA_SECTION')}}</div>
    <div class="col-4" v-for="(availableType, i) in historicalAvailableTypes" :key="availableType.value+i">
      <v-btn color="primary" block outlined class="text-truncate w-100 h-100" :value="availableType.value">
        <div class="py-4">
          <component v-bind:is="availableType.component" class="pb-4 text-h2"/>
          <div>{{availableType.name}}</div>
        </div>
      </v-btn>
    </div>
  </v-btn-toggle>
</template>
<script>
import MultipleGridsGraph from '@/components/icons/MultipleGridsGraph.vue'
import TwoYGraph from '@/components/icons/TwoYGraph.vue'
import Graph from '@/components/icons/Graph.vue'
import CardGraph from '@/components/icons/CardGraph.vue'
import Table from '@/components/icons/Table.vue'
import Page from '@/components/icons/Page.vue'
import LineIcon from '@/components/icons/LineIcon.vue'
export default {
  name: 'WidgetTypeSelector',
  components: {
    MultipleGridsGraph,
    TwoYGraph,
    Graph,
    CardGraph,
    Table,
    Page,
    LineIcon
  },
  model: {
    prop: 'type',
    event: 'input'
  },
  props: {
    type: String
  },
  computed: {
    realtimeAvailableTypes() {
      return [
        { name: this.$t('WidgetTypeSelector.TYPE.CARD_CHART'), value: 'CARD_CHART', component: () => import('@/components/icons/CardGraph') },
        { name: this.$t('WidgetTypeSelector.TYPE.LINE'), value: 'LINE', component: () => import('@/components/icons/LineIcon') },
        { name: this.$t('WidgetTypeSelector.TYPE.PAGE'), value: 'PAGE', component: () => import('@/components/icons/Page') },
        { name: this.$t('WidgetTypeSelector.TYPE.TABLE'), value: 'TABLE', component: () => import('@/components/icons/Table') },
        { name: this.$t('WidgetTypeSelector.TYPE.PICTOGRAPH'), value: 'PICTOGRAPH', component: () => import('@/components/icons/Pictograph') },
        { name: this.$t('WidgetTypeSelector.TYPE.BAR_CHART'), value: 'BAR_CHART', component: () => import('@/components/icons/BarChart') }
      ]
    },
    historicalAvailableTypes() {
      return [
        { name: this.$t('WidgetTypeSelector.TYPE.LINE_CHART'), value: 'LINE_CHART', component: () => import('@/components/icons/Graph') },
        { name: this.$t('WidgetTypeSelector.TYPE.MULTIPLE_Y_CHART'), value: 'MULTIPLE_Y_CHART', component: () => import('@/components/icons/TwoYGraph') },
        { name: this.$t('WidgetTypeSelector.TYPE.MULTIGRID_CHART'), value: 'MULTIGRID_CHART', component: () => import('@/components/icons/MultipleGridsGraph') },
        { name: this.$t('WidgetTypeSelector.TYPE.COLLAPSABLE_TABLE'), value: 'COLLAPSABLE_TABLE', component: () => import('@/components/icons/CollapsableTable') }
      ]
    }
  },
  methods: {
    onTypeSelection(type) {
      if (type !== undefined) {
        this.$emit('input', type)
      } else {
        this.$emit('input', null)
      }
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
