<template>
  <v-navigation-drawer app :value="drawer" @input="toggleSidebar" width="300" :class="status" @touchstart.native="showScrollbar" @touchend.native="hideScrollbar"  @mouseleave.native="hideScrollbar" @mouseenter.native="showScrollbar">
    <template v-slot:prepend="">
      <v-list-item class="flex justify-center elevation-4 logo-vertical">
        <img :src="logo" class="logo-vertical" />
      </v-list-item>
      <v-list-item v-if="showDevicesSearchBar">
        <v-text-field
          v-model="search"
          :label="$t('Sidebar.SEARCH_LABEL_MESSAGE')"
          flat
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
        ></v-text-field>
      </v-list-item>
    </template>
    <v-list dense ref="list" expand>
      <DynamicScroller
        ref="scroller"
        :items="items"
        :min-item-size="40"
        :keyField="'id'"
        :style="`max-height: ${height}px`"
        :buffer="200"
        >
        <template v-slot="{ item, index, active }">
          <DynamicScrollerItem
            :item="item"
            :active="active"
            :data-index="index"
            :watchData="true"
            :sizeDependencies="['expanded']"
          >
            <SidebarNode :source="item"/>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
      <v-list-item class="justify-center align-center" v-if="loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import SidebarNode from '@/components/sidebar/SidebarNode.vue'
import elementResizeDetectorMaker from 'element-resize-detector'
export default {
  name: 'Sidebar',
  components: {
    SidebarNode
  },
  props: {
    drawer: Boolean
  },
  data() {
    return {
      search: '',
      height: 100,
      status: 'hide-scrollbar',
      elementResizeDetector: elementResizeDetectorMaker({ strategy: 'scroll' })
    }
  },
  computed: {
    loading() {
      return this.$store.getters['devices/status'] === 'LOADING'
    },
    showDevicesSearchBar() {
      return this.$store.state.settings.showDevicesSearchBar
    },
    dashboards() {
      return this.$store.getters['dashboards/group']
    },
    devices() {
      return this.$store.state.devices.devices
    },
    logo() {
      return this.$store.getters.smallLogo
    },
    nodes() {
      return [this.dashboards, ...this.devices]
    },
    items() {
      if (this.search === null) {
        return this.nodes
      }
      const hint = this.search.toLowerCase()
      return this.nodes.filter(n => {
        if (n.type === undefined) {
          return true
        }
        return n.name.toLowerCase().indexOf(hint) >= 0 ||
          n.hexacode.toLowerCase().indexOf(hint) >= 0
      })
    }
  },
  beforeRouteEnter(to, from, next) {
    this.scrollToItem()
    next()
  },
  mounted() {
    this.elementResizeDetector.listenTo(this.$refs.list.$el.parentElement, this.updateHeight)
    this.$watch(
      () => this.$store.state.devices.status,
      (newValue, oldValue) => {
        if (this.$route.name === 'page' && newValue === 'LOADED') {
          this.scrollToItem()
        }
      }
    )
    this.$watch(
      () => this.$route,
      (newValue, oldValue) => {
        if (newValue.name === 'page') {
          this.scrollToItem()
        }
      }
    )
  },
  methods: {
    showScrollbar() {
      // console.log('mouse enter')
      this.status = 'show-scrollbar'
    },
    hideScrollbar() {
      // console.log('mouse leave')
      this.status = 'hide-scrollbar'
    },
    toggleSidebar(value) {
      this.$emit('update:drawer', value)
    },
    updateHeight(element) {
      this.height = element.offsetHeight - 16
    },
    scrollToItem() {
      var index = this.devices.findIndex(d => d.hexacode === this.$route.params.hexacode)
      if (index >= 0) {
        this.$refs.scroller.scrollToItem(index)
      }
    }
  }
}
</script>
<style lang="css" scoped>
.logo-vertical {
  height: 48px !important;
  min-height: 48px !important;
  max-height: 48px !important;
}
.theme--dark .logo-vertical {
  filter: brightness(0.0) invert(1) grayscale(1)
}
* >>>.v-list-group__header__prepend-icon {
  margin-right: 8px !important;
  margin-top: 6px !important;
  margin-bottom: 0px !important;
}
* >>>.v-list-group__header__append-icon {
  margin-left: 0px !important;
  min-width: 24px !important;
}
* >>>.v-list-item__icon {
  /* margin-right: 0px !important; */
  min-width: 24px !important;
}
* >>>.v-list-group__items {
  padding-left: 8px;
}
/* .hide-scrollbar >>> .vue-recycle-scroller.ready.direction-vertical::-webkit-scrollbar {
  display: none;
} */
/* .show-scrollbar >>> .vue-recycle-scroller.ready.direction-vertical .v-list-item.v-list-item--link {
  padding-right: 0px;
} */

/* * >>> .vue-recycle-scroller.ready.direction-vertical {
  overflow-y: hidden;
}
 .show-scrollbar >>> .vue-recycle-scroller.ready.direction-vertical, * >>> .vue-recycle-scroller.ready.direction-vertical:hover {
  overflow-y: auto !important;
}
.has-scrollbar >>> .vue-recycle-scroller.ready.direction-vertical:hover .v-list-item.v-list-item--link {
  padding-right: 0px;
} */
</style>
