/* eslint-disable camelcase */
import { extend } from 'vee-validate'
import { required, email, between, numeric, double, digits, length, is, regex, min } from 'vee-validate/dist/rules'
import { DateTime } from 'luxon'
import store from './store'
import { validateDashboardsGroupName } from '@/api/backend.js'

// eslint-disable-next-line no-unused-vars
const parser = DateTime
extend('regex', regex)
extend('is', is)
extend('length', length)
extend('digits', digits)
extend('email', email)
extend('between', between)
extend('numeric', numeric)
extend('double', double)
// Add the required rule
extend('required', {
  ...required,
  message: (field) => {
    return `${field} is required`
  }
})
extend('min', {
  ...min,
  message: (field, args) => {
    return `${field} length must be higher than ${args.length}`
  }
})
extend('password', {
  ...regex,
  message: (field) => {
    return `${field} must contain at least an uppercase character, an lowercase character and a digit.`
  }
})
const dateFormat = function(value, args) {
  return DateTime.fromFormat(value, args[0]).invalid === null
}

const uniqueDashboardsGroupName = function(value, args) {
  if (args[0] === 'undefined') {
    args[0] = undefined
  }
  return validateDashboardsGroupName(args[0], value).then(r => ({ valid: r.data }))
}
extend('dateFormat', {
  validate: dateFormat,
  message: (field, params, data) => {
    return `${field} is invalid, use ${params[0]} format`
  }
})
extend('after', {
  validate: (value, args) => {
    let target = args[0]
    if (typeof args[0] === 'string') {
      target = DateTime.fromISO(args[0], { zone: 'utc' })
    }
    const current = DateTime.fromFormat(value, store.state.settings.dateFormat, { zone: 'utc' })
    return current >= target
  },
  message: (field, params, data) => {
    let target = params[0]
    if (typeof params[0] === 'string') {
      target = DateTime.fromISO(params[0], { zone: 'utc' })
    }
    return `${field} value must be after ${target.toFormat(store.state.settings.dateFormat)}`
  }
})
extend('before', {
  validate: (value, args) => {
    let target = args[0]
    if (typeof args[0] === 'string') {
      target = DateTime.fromISO(args[0], { zone: 'utc' })
    }
    const current = DateTime.fromFormat(value, store.state.settings.dateFormat, { zone: 'utc' })
    return current <= target
  },
  message: (field, params, data) => {
    let target = params[0]
    if (typeof params[0] === 'string') {
      target = DateTime.fromISO(params[0], { zone: 'utc' })
    }
    return `${field} value must be before ${target.toFormat(store.state.settings.dateFormat)}`
  }
})
extend('unique_dashboards_group_name', {
  validate: uniqueDashboardsGroupName,
  message: (field, params, data) => {
    return `${field} already exists`
  }
})
extend('buffer_required', {
  validate: (value, args) => {
    const element = args[0]
    let pcNumber = args[1]
    const availablePcNumbers = args[2]
    const allowBufferCreation = args[3]
    const selectedLine = value
    if (selectedLine && selectedLine.availableBuffers) {
      if (element.pcNumber !== null) {
        pcNumber = element.pcNumber
      }
      if (availablePcNumbers.includes(pcNumber)) {
        if (allowBufferCreation) {
          return true
        }
        const buffer = selectedLine.availableBuffers.find(b => b.pcNumber === pcNumber)
        return buffer !== undefined
      }
    }
    return false
  },
  message: (field, params, data) => {
    return 'Buffer not available'
  }
})
