<template>
  <v-dialog v-model="visible" :fullscreen="fullscreen" scrollable width="800" persistent overlay-opacity="0.65">
    <ValidationObserver ref="observer" @submit.prevent="save" tag="form">
      <v-card :loading="loading">
        <v-card-title class="justify-space-between">
          <div>
            <div>{{$t("WidgetMarkAreaElementsDialog.ANNOTATIONS_MESSAGE")}}</div>
          </div>
          <div>
            <v-btn icon small @click="fullscreen = !fullscreen" color="secondary">
              <v-icon v-if="!fullscreen">mdi-arrow-expand</v-icon>
              <v-icon v-if="fullscreen">mdi-arrow-collapse</v-icon>
            </v-btn>
            <v-btn icon small @click="visible = false" color="secondary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-card-text>
          <v-btn block color="primary" class="my-3" @click="add">{{$t("WidgetMarkAreaElementsDialog.ADD_ANNOTATION_MESSAGE")}}</v-btn>
          <WidgetMarkAreaElement v-for="(element, i) in markAreaElements" :element="element" :key="i" @remove="remove(i)"/>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-card-actions class="d-flex w-100">
          <div class="pr-1 w-100">
            <v-btn outlined block color="accent" @click="visible = false">{{$t('WidgetMarkAreaElementsDialog.CANCEL_MESSAGE')}}</v-btn>
          </div>
          <div class="pl-1 w-100">
            <v-btn outlined block color="primary" @click="save">{{$t('WidgetMarkAreaElementsDialog.SAVE_MESSAGE')}}</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
import { DateTime } from 'luxon'
import { ValidationObserver } from 'vee-validate'
import WidgetMarkAreaElement from '@/components/dashboards/widgets/dialog/WidgetMarkAreaElement.vue'
import { saveWidgetMarkAreaElements } from '@/api/backend'
export default {
  name: 'WidgetMarkAreaElementsDialog',
  components: {
    WidgetMarkAreaElement,
    ValidationObserver
  },
  props: {
    isVisible: Boolean,
    widget: Object
  },
  computed: {
    visible: {
      get: function() {
        return this.isVisible
      },
      set: function(v) {
        this.$emit('closed')
      }
    }
  },
  data() {
    return {
      fullscreen: false,
      loading: false,
      markAreaElements: []
    }
  },
  methods: {
    async save() {
      const valid = await this.$refs.observer.validate()
      if (valid) {
        this.loading = true
        saveWidgetMarkAreaElements(this.$store.state.dashboards.selectedDashboard.id, this.widget.id, this.markAreaElements).then(r => {
          this.showSuccessNotification(this.$t('WidgetMarkAreaElementsDialog.ANNOTATION_SAVED_SUCCESSFULLY_MESSAGE'))
            .then(this.$emit('saved', r.data))
        }).catch(e => this.showErrorNotification(this.$t('WidgetMarkAreaElementsDialog.ANNOTATION_SAVED_ERROR_MESSAGE')))
          .then(() => { this.loading = false })
      }
    },
    add() {
      const now = DateTime.now().setZone('utc', { keepLocalTime: true }).set({ second: 0, millisecond: 0 })
      this.markAreaElements.push({
        label: '',
        color: '#FF0000FF',
        endDate: now.toISO(),
        startDate: now.toISO()
      })
    },
    remove(index) {
      this.markAreaElements.splice(index, 1)
    }
  },
  watch: {
    isVisible: function(newValue, oldValue) {
      if (newValue) {
        this.markAreaElements = this.clone(this.widget.markAreaElements)
      }
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
