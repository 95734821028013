<template>
  <v-dialog v-model="isVisible" max-width="500" scrollable persistent overlay-opacity="0.65">
    <v-card elevation="2">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <v-form style="max-width: 500px" @submit.prevent="handleSubmit(sendLoginRequest)">
          <div class="container">
            <div class="row">
              <span class="col-12 d-flex justify-center pt-8">{{$t("TwoFactorAuthentication.ENTER_SECURITY_CODE_MESSAGE")}}</span>
              <div class="col-12">
                <ValidationProvider rules="required|length:6" :name="$t('TwoFactorAuthentication.SECURITY_CODE_LABEL_MESSAGE')" v-slot="{ errors, valid }">
                  <v-otp-input length="6" type="number" :disabled="loading" v-model="securityCode" required :error-messages="errors" :success="valid" @finish="sendLoginRequest"></v-otp-input>
                  <div v-if="!valid" class="text-center error--text">{{errors[0]}}</div>
                  <div v-if="valid" class="text-center error--text">{{message}}</div>
                </ValidationProvider>
              </div>
            </div>
            <div class="row mb-2 d-flex justify-center">
              <div class="col-6 d-flex justify-space-between">
                <v-btn color="primary" @click="isVisible = false">{{$t('TwoFactorAuthentication.CANCEL_MESSAGE')}}</v-btn>
                <v-btn color="primary" type="submit">{{$t('TwoFactorAuthentication.SIGN_IN_MESSAGE')}}</v-btn>
              </div>
            </div>
          </div>
        </v-form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'TwoFactorAuthentication',
  props: {
    visible: Boolean,
    loading: Boolean,
    message: String
  },
  components: {
    ValidationObserver, ValidationProvider
  },
  computed: {
    isVisible: {
      get: function() {
        return this.visible
      },
      set: function(v) {
        this.$emit('closed')
        this.securityCode = null
      }
    }
  },
  data() {
    return {
      securityCode: null
    }
  },
  methods: {
    sendLoginRequest() {
      this.$emit('sendLoginRequest', this.securityCode)
    }
  }
}
</script>
