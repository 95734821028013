<template>
  <v-app-bar app dense>
    <v-app-bar-nav-icon @click="toggleSidebar" color="secondary" class="v-btn--plain"></v-app-bar-nav-icon>
    <div class="d-flex flex-row-reverse text-truncate">
      <div class="text-truncate">
        <span v-for="(item, index) in breadcrumpItems" :key="`b-${index}`" :class="item.class">{{ item.text }}</span>
      </div>
    </div>
    <v-spacer></v-spacer>
    <AlarmsMenu/>
    <AccountMenu/>
  </v-app-bar>
</template>
<script>
import AlarmsMenu from '@/components/alarms/AlarmsMenu.vue'
import AccountMenu from '@/components/AccountMenu.vue'
import {
  findByHexacode,
  findByHexacodeAndNetworkNumber,
  findByHexacodeAndDepartmentAndSubDepartment
} from '@/store/devices'
import { LOAD_DEVICES, LOAD_DEPARTMENTS_NAMES, RENAME_DASHBOARD } from '@/store/mutations'
export default {
  name: 'Headbar',
  components: {
    AccountMenu,
    AlarmsMenu
  },
  props: {
    drawer: Boolean
  },
  data() {
    return {
      spacer: {
        class: 'px-2 d-none d-sm-inline-block',
        text: '/'
      },
      breadcrumpItems: [],
      storeSubscription: null
    }
  },
  created() {
    this.$watch(
      () => this.$route.path,
      (newValue, oldValue) => {
        this.breadcrumpItems = this.computeBreadcrumpItems()
      },
      { immediate: true }
    )
  },
  mounted() {
    this.computeBreadcrumpItems()
    this.storeSubscription = this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === `devices/${LOAD_DEVICES}` || action.type === `devices/${LOAD_DEPARTMENTS_NAMES}` || action.type === `dashboards/${RENAME_DASHBOARD}`) {
          this.breadcrumpItems = this.computeBreadcrumpItems()
        }
      }
    })
  },
  beforeDestroy() {
    this.storeSubscription()
  },
  methods: {
    toggleSidebar() {
      this.$emit('update:drawer', !this.drawer)
    },
    computeBreadcrumpItems() {
      const items = []
      if (this.$route.name === 'page') {
        const device = findByHexacode(this.$route.params.hexacode)
        if (device !== null) {
          items.push({
            class: 'd-none d-sm-inline-block secondary--text',
            text: this.getItemName(device.device)
          })
          items.push(this.spacer)
          if (device.type === 'BUSINESS_GROUP') {
            const mappedDevice = findByHexacodeAndNetworkNumber(this.$route.params.hexacode, this.$route.params.department)
            if (mappedDevice !== null) {
              items.push({
                class: 'd-none d-sm-inline-block secondary--text',
                text: this.getItemName(mappedDevice)
              })
              items.push(this.spacer)
            }
          }
          const department = findByHexacodeAndDepartmentAndSubDepartment(this.$route.params.hexacode, this.$route.params.department, this.$route.params.subDepartment)
          if (department !== null) {
            items.push({
              class: 'font-weight-bold secondary--text',
              text: this.getItemName(department)
            })
          }
        }
      } else if (this.$store.state.dashboards.selectedDashboard !== null) {
        items.push({
          class: 'font-weight-bold secondary--text',
          text: this.$store.state.dashboards.selectedDashboard.name
        })
      }
      return items
    },
    getItemName(device) {
      if (device.configuration.label !== null) {
        return device.configuration.label
      } else {
        return device.name
      }
    }
  }
}
</script>
