<template>
  <v-dialog v-model="visible" :fullscreen="fullscreen" scrollable width="800" persistent overlay-opacity="0.80">
    <v-card :loading="loading">
      <v-card-title class="justify-space-between">
        <div>
          <div>{{$t('WidgetElementDetailsDialog.WIDGET_DETAILS_MESSAGE')}}</div>
        </div>
        <div>
          <v-btn icon small @click="fullscreen = !fullscreen" color="secondary">
            <v-icon v-if="!fullscreen">mdi-arrow-expand</v-icon>
            <v-icon v-if="fullscreen">mdi-arrow-collapse</v-icon>
          </v-btn>
          <v-btn icon small @click="visible = false" color="secondary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-divider class="mx-4 pb-4"></v-divider>
      <v-card-text>
        <div class="container container--fluid">
          <div class="row">
            <div class="col-12">
              <v-text-field v-model="name" :label="$t('WidgetElementDetailsDialog.CUSTOM_ELEMENT_LABEL_MESSAGE')" hide-details dense clearable></v-text-field>
            </div>
          </div>
          <component v-bind:is="component" :element="elementData" :selectedLine="selectedLine" :allowBufferCreation="allowBufferCreation" :hasCompareTo="hasCompareTo" :type="type" :options="options"></component>
        </div>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-actions class="d-flex w-100">
        <div class="pr-1 w-100">
          <v-btn outlined block color="accent" @click="visible = false">{{$t('WidgetElementDetailsDialog.CANCEL_MESSAGE')}}</v-btn>
        </div>
        <div class="pl-1 w-100">
          <v-btn outlined block color="primary" @click="save">{{$t('WidgetElementDetailsDialog.SAVE_MESSAGE')}}</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import SvgIcon from '@jamescoyle/vue-icon'
import ColorPickerMenu from '@/components/ColorPickerMenu.vue'
export default {
  name: 'WidgetElementDetailsDialog',
  components: {
    ColorPickerMenu, SvgIcon
  },
  props: {
    isVisible: Boolean,
    type: String,
    element: Object,
    selectedLine: Object,
    options: Object,
    hasCompareTo: Boolean,
    allowBufferCreation: Boolean
  },
  computed: {
    visible: {
      get: function() {
        return this.isVisible
      },
      set: function(v) {
        this.$emit('closed')
      }
    },
    component() {
      if (this.type === 'PAGE') {
        return () => import('@/components/dashboards/widgets/dialog2/widget-element-details/PageWidgetElementDetails.vue')
      } else if (this.type === 'TABLE') {
        return () => import('@/components/dashboards/widgets/dialog2/widget-element-details/TableWidgetElementDetails.vue')
      } else if (this.type === 'LINE_CHART') {
        return () => import('@/components/dashboards/widgets/dialog2/widget-element-details/LineGraphWidgetElementDetails.vue')
      } else if (this.type === 'MULTIPLE_Y_CHART') {
        return () => import('@/components/dashboards/widgets/dialog2/widget-element-details/MultipleYGraphWidgetElementDetails.vue')
      } else if (this.type === 'MULTIGRID_CHART') {
        return () => import('@/components/dashboards/widgets/dialog2/widget-element-details/MultigridGraphWidgetElementDetails.vue')
      } else if (this.type === 'PICTOGRAPH') {
        return () => import('@/components/dashboards/widgets/dialog2/widget-element-details/PictographGraphWidgetElementDetails.vue')
      } else if (this.type === 'CARD_CHART') {
        return () => import('@/components/dashboards/widgets/dialog2/widget-element-details/CardGraphWidgetElementDetails.vue')
      } else if (this.type === 'BAR_CHART') {
        return () => import('@/components/dashboards/widgets/dialog2/widget-element-details/BarChartWidgetElementDetails.vue')
      } else if (this.type === 'COLLAPSABLE_TABLE') {
        return () => import('@/components/dashboards/widgets/dialog2/widget-element-details/CollapsableTableWidgetElementDetails.vue')
      }
      // line default
      return () => import('@/components/dashboards/widgets/dialog2/widget-element-details/LineWidgetElementDetails.vue')
    },
    defaultName() {
      if (this.selectedLine != null) {
        return this.selectedLine.name
      }
      return null
    }
  },
  data() {
    return {
      elementData: null,
      lineData: null,
      fullscreen: false,
      loading: false,
      name: ''
    }
  },
  methods: {
    save() {
      if (this.name === this.defaultName || this.name === null || this.name.trim().length === 0) {
        this.elementData.name = null
      } else if (this.name !== this.defaultName && this.name !== null && this.name.trim().length > 0) {
        this.elementData.name = this.name.trim()
      }
      if (this.elementData.buffer && this.elementData.buffer !== null) {
        const buffer = this.selectedLine.availableBuffers.find(b => b.pcNumber === this.elementData.buffer.pcNumber)
        if (buffer && (buffer.interval !== this.elementData.buffer.interval || buffer.start !== this.elementData.buffer.start)) { // if we have the buffer and different data
          this.elementData.buffer.status = 'CREATE_BUFFER'
        }
        if (!this.$store.getters.availablePcNumbers.includes(this.elementData.buffer.pcNumber) && this.elementData.buffer.status === 'CREATE_BUFFER') { // in case we no longer have access to the linked pc number
          this.elementData.buffer.pcNumber = this.$store.state.settings.defaultPcNumber
          this.elementData.pcNumber = null
          this.elementData.useDefaultPcNumber = true
        }
      }
      this.$emit('update', this.elementData)
      this.visible = false
    }
  },
  watch: {
    isVisible: function(newValue, oldValue) {
      if (newValue) {
        this.name = this.element.name
        this.elementData = this.clone(this.element)
        if (!this.name) {
          this.name = this.defaultName
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
