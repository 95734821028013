<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <v-dialog width="500" v-model="visible" :fullscreen="fullscreen" scrollable persistent>
      <v-form @submit.prevent="handleSubmit(saveDashboardsGroup)">
        <v-card :loading="loading">
          <v-card-title class="justify-space-between">
            <div>
              <v-icon>mdi-format-list-group</v-icon>
              <span class="pl-2">{{title}}</span>
            </div>
            <div>
              <v-btn icon small @click="fullscreen = !fullscreen" color="secondary">
                <v-icon v-if="!fullscreen">mdi-arrow-expand</v-icon>
                <v-icon v-if="fullscreen">mdi-arrow-collapse</v-icon>
              </v-btn>
              <v-btn icon small @click="visible = false" color="secondary">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-text>
            <div class="row pt-10">
              <ValidationProvider class="col-12" :rules="`required|unique_dashboards_group_name:${group.id}`" :name="$t('DashboardsGroupDialog.GROUP_NAME_MESSGE')" v-slot="{ errors, valid }">
                <v-text-field
                  :label="$t('DashboardsGroupDialog.GROUP_NAME_MESSGE')"
                  v-model="group.name"
                  dense
                  required
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
              <v-autocomplete
                class="col-12"
                v-model="group.dashboards"
                :items="availableDashboards"
                :label="$t('DashboardsGroupDialog.DASHBOARDS_MESSAGE')"
                item-text="name"
                dense
                chips
                small-chips
                multiple
                return-object>
              </v-autocomplete>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="d-flex w-100">
            <div class="pr-1 w-100">
              <v-btn outlined block color="accent" @click="visible = false">{{$t('DashboardsGroupDialog.CANCEL_MESSAGE')}}</v-btn>
            </div>
            <div class="pl-1 w-100">
              <v-btn outlined block color="primary" type="submit">{{$t('DashboardsGroupDialog.SAVE_MESSAGE')}}</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </ValidationObserver>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'DashboardsGroupDialog',
  props: {
    isVisible: Boolean,
    title: String,
    loading: Boolean,
    dashboardsGroup: Object
  },
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      fullscreen: false,
      group: {
        name: '',
        dashboards: []
      },
      availableDashboards: []
    }
  },
  computed: {
    visible: {
      get: function() {
        return this.isVisible
      },
      set: function(v) {
        this.$emit('closed')
      }
    }
  },
  methods: {
    saveDashboardsGroup() {
      this.$emit('save', this.group)
    }
  },
  watch: {
    isVisible(newValue, oldValue) {
      this.$emit('input', newValue)
      if (newValue) {
        this.group = {
          name: '',
          dashboards: []
        }
        if (this.dashboardsGroup) {
          this.group = this.clone(this.dashboardsGroup)
        }
        this.group.dashboards.forEach(d => this.availableDashboards.push(d))
        this.$store.state.dashboards.dashboardsAndGroups
          .filter(g => g.dashboards === undefined)
          .forEach(d => this.availableDashboards.push(this.clone(d)))
      }
    }
  }
}
</script>
