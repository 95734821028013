/**
 * Return the bottom coordinate of the layout.
 *
 * @param  {Array} layout Layout array.
 * @return {Number}       Bottom coordinate.
 */
export function bottom(layout) {
  let max = 0; let bottomY
  for (let i = 0, len = layout.length; i < len; i++) {
    bottomY = layout[i].y + layout[i].h
    if (bottomY > max) {
      max = bottomY
    }
  }
  return max
}

export function autoSetItemPosition(item, items, columnsNumber) {
  const rowsNumber = bottom(items)
  // walk through each row and column looking for a place it will fit
  for (var rowIndex = 0; rowIndex < rowsNumber; ++rowIndex) {
    for (var colIndex = 0; colIndex < columnsNumber; ++colIndex) {
      if (colIndex + item.w > columnsNumber) {
        continue
      }
      const pos = {
        x: colIndex,
        y: rowIndex,
        w: item.w,
        h: item.h
      }
      if (!getFirstCollision(items, pos)) {
        return pos
      }
    }
  }
  return {
    x: 0,
    y: rowsNumber,
    w: item.w,
    h: item.h
  }
};

export function cloneLayout(layout) {
  const newLayout = Array(layout.length)
  for (let i = 0, len = layout.length; i < len; i++) {
    newLayout[i] = cloneLayoutItem(layout[i])
  }
  return newLayout
}

// Fast path to cloning, since this is monomorphic
export function cloneLayoutItem(layoutItem) {
  return JSON.parse(JSON.stringify(layoutItem))
}

/**
 * Given two layoutitems, check if they collide.
 *
 * @return {Boolean}   True if colliding.
 */
export function collides(l1, l2) {
  if (l1.i === l2.i) return false // same element
  if (l1.x + l1.w <= l2.x) return false // l1 is left of l2
  if (l1.x >= l2.x + l2.w) return false // l1 is right of l2
  if (l1.y + l1.h <= l2.y) return false // l1 is above l2
  if (l1.y >= l2.y + l2.h) return false // l1 is below l2
  return true // boxes overlap
}

/**
 * Given a layout, compact it. This involves going down each y coordinate and removing gaps
 * between items.
 *
 * @param  {Array} layout Layout.
 *   vertically.
 * @param {Object} minPositions
 * @return {Array}       Compacted Layout.
 */
export function compact(layout) {
  const compareWith = []
  // We go through the items by row and column.
  const sorted = sortLayoutItemsByRowCol(layout)
  // Holding for new items.
  const out = Array(layout.length)

  for (let i = 0, len = sorted.length; i < len; i++) {
    let l = sorted[i]

    l = compactItem(compareWith, l)

    // Add to comparison array. We only collide with items before this one.
    compareWith.push(l)

    // Add to output array to make sure they still come out in the right order.
    out[layout.indexOf(l)] = l

    // Clear moved flag, if it exists.
    l.moved = false
  }

  return out
}

/**
 * Compact an item in the layout.
 */
export function compactItem(compareWith, l) {
  while (l.y > 0 && !getFirstCollision(compareWith, l)) {
    l.y--
  }

  // Move it down, and keep moving it down if it's colliding.
  let collides
  while ((collides = getFirstCollision(compareWith, l))) {
    l.y = collides.y + collides.h
  }
  return l
  /* l.y = Math.min(bottom(compareWith), l.y)
  while (l.y > 0 && !getFirstCollision(l, compareWith)) {
    l.y--
  }
  let intersection
  while ((intersection = getFirstCollision(l, compareWith))) {
    l.y = intersection.y + intersection.h
  }
  l.y = Math.max(l.y, 0)
  l.x = Math.max(l.x, 0)
  return l */
}

/**
 * Given a layout, make sure all elements fit within its bounds.
 *
 * @param  {Array} layout Layout array.
 * @param  {Number} bounds Number of columns.
 */
// export function correctBounds(layout, columnsNumber) {
//   const outOfBounds = []
//   for (let i = 0, len = layout.length; i < len; i++) {
//     const l = layout[i]
//     // Overflows right
//     if (l.x + l.w > columnsNumber) {
//       // l.x = bounds.cols - l.w
//       /* l.x = 0
//       l.y = l.y + l.h
//       for (let y = i + 1, length = layout.length; y < length; y++) {
//         const next = layout[y]
//         next.x += next.w
//       } */
//       l.x = columnsNumber - l.w
//       while (true) {
//         const f1 = getFirstCollision(layout, l)
//         if (f1) {
//           const f2 = getFirstCollision(outOfBounds, l)
//           if (f2) {
//             if (l.x + l.w < columnsNumber) {
//               l.x += 1
//             }
//             break
//           } else {
//             l.x -= 1
//             if (l.x <= 0) { // skip some steps here
//               l.x = 0
//               break
//             }
//           }
//         } else {
//           break
//         }
//       }
//       outOfBounds.push(l)
//     }
//     // Overflows left
//     if (l.x < 0) {
//       l.x = 0
//       l.w = columnsNumber
//     }
//   }
//   return layout
// }

/**
 * Get a layout item by ID. Used so we can override later on if necessary.
 *
 * @param  {Array}  layout Layout array.
 * @param  {String} id     ID
 * @return {LayoutItem}    Item at ID.
 */
export function getLayoutItem(layout, id) {
  for (let i = 0, len = layout.length; i < len; i++) {
    if (layout[i].i === id) return layout[i]
  }
  return null
}

/**
 * Returns the first item this layout collides with.
 * It doesn't appear to matter which order we approach this from, although
 * perhaps that is the wrong thing to do.
 *
 * @param  {Object} layoutItem Layout item.
 * @return {Object|undefined}  A colliding layout item, or undefined.
 */
export function getFirstCollision(layout, layoutItem) {
  for (let i = 0, len = layout.length; i < len; i++) {
    if (collides(layout[i], layoutItem)) {
      return layout[i]
    }
  }
}

export function getAllCollisions(layout, layoutItem) {
  return layout.filter((l) => collides(l, layoutItem))
}

/**
 * Move an element. Responsible for doing cascading movements of other elements.
 *
 * @param  {Array}      layout Full layout to modify.
 * @param  {LayoutItem} l      element to move.
 * @param  {Number}     [x]    X position in grid units.
 * @param  {Number}     [y]    Y position in grid units.
 * @param  {Boolean}    [isUserAction] If true, designates that the item we're moving is
 *                                     being dragged/resized by th euser.
 */
export function moveElement(layout, l, x, y, isUserAction) {
  // Short-circuit if nothing to do.
  // if (l.y === y && l.x === x) return layout;

  const movingUp = y && l.y > y
  // This is quite a bit faster than extending the object
  if (typeof x === 'number') l.x = x
  if (typeof y === 'number') l.y = y
  l.moved = true

  // If this collides with anything, move it.
  // When doing this comparison, we have to sort the items we compare with
  // to ensure, in the case of multiple collisions, that we're getting the
  // nearest collision.
  let sorted = sortLayoutItemsByRowCol(layout)
  if (movingUp) sorted = sorted.reverse()
  const collisions = getAllCollisions(sorted, l)

  // Move each item that collides away from this element.
  for (let i = 0, len = collisions.length; i < len; i++) {
    const collision = collisions[i]
    // console.log('resolving collision between', l.i, 'at', l.y, 'and', collision.i, 'at', collision.y);

    // Short circuit so we can't infinite loop
    if (collision.moved) continue

    // This makes it feel a bit more precise by waiting to swap for just a bit when moving up.
    if (l.y > collision.y && l.y - collision.y > collision.h / 4) continue

    layout = moveElementAwayFromCollision(layout, l, collision, isUserAction)
  }

  return layout
}

/**
 * This is where the magic needs to happen - given a collision, move an element away from the collision.
 * We attempt to move it up if there's room, otherwise it goes below.
 *
 * @param  {Array} layout            Full layout to modify.
 * @param  {LayoutItem} collidesWith Layout item we're colliding with.
 * @param  {LayoutItem} itemToMove   Layout item we're moving.
 * @param  {Boolean} [isUserAction]  If true, designates that the item we're moving is being dragged/resized
 *                                   by the user.
 */
export function moveElementAwayFromCollision(layout, collidesWith, itemToMove, isUserAction) {
  const preventCollision = false // we're already colliding
  // If there is enough space above the collision to put this element, move it there.
  // We only do this on the main collision as this can get funky in cascades and cause
  // unwanted swapping behavior.
  if (isUserAction) {
    // Make a mock item so we don't modify the item here, only modify in moveElement.
    const fakeItem = {
      x: itemToMove.x,
      y: itemToMove.y,
      w: itemToMove.w,
      h: itemToMove.h,
      i: '-1'
    }
    fakeItem.y = Math.max(collidesWith.y - itemToMove.h, 0)
    if (!getFirstCollision(layout, fakeItem)) {
      return moveElement(layout, itemToMove, undefined, fakeItem.y, preventCollision)
    }
  }

  // Previously this was optimized to move below the collision directly, but this can cause problems
  // with cascading moves, as an item may actually leapflog a collision and cause a reversal in order.
  return moveElement(layout, itemToMove, undefined, itemToMove.y + 1, preventCollision)
}

/**
 * Get layout items sorted from top left to right and down.
 *
 * @return {Array} Array of layout objects.
 * @return {Array}        Layout, sorted.
 */
export function sortLayoutItemsByRowCol(layout, current = 12) {
  return [].concat(layout).sort(function(a, b) {
    if (a.y === b.y && a.x === b.x) {
      return 0
    }
    if (a.x === b.x) {
      return a.y - b.y
    }
    if (a.y === b.y) {
      return a.x - b.x
    }
    /* const bottomLeftA = a.y + a.h
    const bottomLeftB = b.y + b.h
    if (b.y < bottomLeftA && bottomLeftA <= bottomLeftB && a.x > b.x) {
      return 1
    } */

    if (a.y > b.y || (a.y === b.y && a.x > b.x)) {
      return 1
    }

    return -1
  })

  // return [].concat(layout).sort((a, b) => { // row order http://en.wikipedia.org/wiki/Row-major_order
  //   return (a.x * current + a.y) - (b.x * current + b.y)
  // })
}
