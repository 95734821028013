<template>
  <v-dialog max-width="800" v-model="visible" :fullscreen="fullscreen" scrollable persistent overlay-opacity="0.65">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :attrs="attrs" :on="on" />
    </template>
    <ValidationObserver ref="observer" @submit.prevent="importDashboard" tag="form" eager>
      <v-card :loading="loading">
        <v-card-title class="justify-space-between">
          <div>
            <v-icon>mdi-application-import</v-icon>
            <span class="pl-2">{{$t('DashboardImportDialog.TITLE')}}</span>
          </div>
          <div>
            <v-btn icon small @click="fullscreen = !fullscreen" color="secondary">
              <v-icon v-if="!fullscreen">mdi-arrow-expand</v-icon>
              <v-icon v-if="fullscreen">mdi-arrow-collapse</v-icon>
            </v-btn>
            <v-btn icon small @click="visible = false" color="secondary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-card-text>
          <div class="container" v-if="step === 1">
            <div class="row">
              <div class="col-12">
                <ValidationProvider rules="required" name="file-input" v-slot="{ errors, valid }">
                  <v-file-input
                    show-size
                    :label="$t('DashboardImportDialog.FILE_INPUT_LABEL')"
                    v-model="file"
                    required
                    :error-messages="errors"
                    :success="valid">
                  </v-file-input>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div v-if="step === 2">
            <ValidationProvider :rules="`required`" :name="$t('DashboardImportDialog.DASHBOARD_TITLE')" v-slot="{ errors, valid }">
              <v-text-field v-model="dashboard.name" :label="$t('DashboardImportDialog.DASHBOARD_TITLE')" dense :error-messages="errors" :success="valid"></v-text-field>
            </ValidationProvider>
            <v-divider v-if="entries.length > 0" class="my-4"></v-divider>
            <div v-if="entries.length > 0">{{$t('DashboardImportDialog.DASHBOARD_DEVICES')}}</div>
            <div v-if="entries.length === 0">{{$t('DashboardImportDialog.NO_DASHBOARD_DEVICES')}}</div>
            <div class="content pt-4">
              <DashboardImportDeviceReplacementSelect
                v-for="entry in entries"
                :key="`${entry.hexacode}-${entry.networkNumber}`"
                :hexacode="entry.hexacode"
                :networkNumber="entry.networkNumber"
                :widgetElements="entry.elements" />
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex w-100">
          <div class="pr-1 w-100">
            <v-btn outlined block color="accent" @click="visible = false" >{{$t('DashboardImportDialog.CANCEL_MESSAGE')}}</v-btn>
          </div>
          <div class="pl-1 w-100">
            <v-btn outlined block color="primary" type="submit">{{$t("DashboardImportDialog.APPLY_MESSAGE")}}</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
import { saveDashboard } from '@/api/backend'
import {
  CREATE_DASHBOARD,
  TOGGLE_DASHBOARD_EDIT
} from '@/store/mutations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DashboardImportDeviceReplacementSelect from '@/components/dashboards/import/DashboardImportDeviceReplacementSelect.vue'
export default {
  name: 'DashboardImportDialog',
  components: {
    ValidationProvider, ValidationObserver, DashboardImportDeviceReplacementSelect
  },
  data() {
    return {
      isVisible: false,
      fullscreen: false,
      file: null,
      loading: false,
      step: 1,
      dashboard: null,
      entries: [],
      constainsBuffers: false
    }
  },
  computed: {
    availableDevices() {
      return this.$store.state.devices.devices
    },
    visible: {
      get() {
        return this.isVisible
      },
      set(visible) {
        this.file = null
        this.loading = false
        this.step = 1
        this.dashboard = null
        this.entries = []
        this.constainsBuffers = false
        this.isVisible = visible
      }
    }
  },
  methods: {
    updateDevice(device, event) {
      if (event === null) {
        device.selectedHexacode = null
        device.selectedRawHexacode = null
        return
      }
      device.selectedHexacode = event.mappingHexacode
      device.selectedRawHexacode = event.rawMappingHexacode
    },
    importDashboard() {
      this.loading = true
      if (this.step === 1) {
        this.extractDashboardData()
      }
      if (this.step === 2) {
        this.saveDashboard()
      }
    },
    saveDashboard() {
      this.loading = true
      this.validateData()
        .then(() => this.askForBuffersCreation())
        .then(shouldCreateBuffers => this.submitDashboardData(shouldCreateBuffers))
        .then(() => { this.visible = false })
        .catch(e => { this.loading = false })
        .then(() => { this.loading = false })
    },
    validateData() {
      return this.$refs.observer.validate().then(isValid => {
        if (isValid) {
          return true
        }
        throw new Error('Validation failed')
      })
    },
    askForBuffersCreation() {
      if (!this.constainsBuffers) {
        return Promise.resolve(false)
      }
      return this.showConfirmationDialog(this.$t('DashboardImportDialog.AUTO_CREATE_BUFFERS'), true)
        .then(r => { return true })
        .catch(e => { return false })
    },
    submitDashboardData(shouldCreateBuffers) {
      this.updateDashboardBuffers(shouldCreateBuffers)
      const haveFavoriteDashboard = this.$store.getters['dashboards/favorite']
      if (haveFavoriteDashboard === null || !haveFavoriteDashboard.favorite) {
        this.dashboard.favorite = true
      } else {
        this.dashboard.favorite = false
      }
      return saveDashboard(this.dashboard).then(r => {
        this.showSuccessNotification(this.$t('AccountMenu.DASHBOARD_SAVE_SUCCESS'))
        this.$store.commit(`dashboards/${CREATE_DASHBOARD}`, r.data) // create dashboard, then sort them to apply and save the new order and afterwards select, navigate and open it in edit mode
        this.$router.push({
          name: 'dashboard',
          params: {
            id: r.data.id
          }
        }).then(() => this.$store.dispatch(`dashboards/${TOGGLE_DASHBOARD_EDIT}`, true))
      }).catch(e => this.showErrorNotification(this.$t('DashboardImportDialog.SAVE_DASHBOARD_ERROR_MESSAGE')))
    },
    updateDashboardBuffers(shouldCreateBuffers) {
      this.dashboard.widgets.forEach(widget => {
        widget.elements.forEach(element => {
          if (shouldCreateBuffers && element.buffer) {
            element.buffer.status = 'CREATE_BUFFER'
            if (this.$store.state.settings.defaultPcNumber === element.buffer.pcNumber) {
              element.pcNumber = null
              element.useDefaultPcNumber = true
            } else {
              element.pcNumber = element.buffer.pcNumber
              element.useDefaultPcNumber = false
            }
          } else {
            element.buffer = null
            element.pcNumber = null
            element.useDefaultPcNumber = true
          }
        })
      })
    },
    /**
     * Extract dashboard data from the selected file and create the linked devices list from it
     */
    extractDashboardData() {
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = event => resolve(JSON.parse(event.target.result))
        reader.onerror = (error) => reject(error)
        reader.readAsText(this.file)
      }).then(dashboard => {
        this.step = 2
        // group them by hexacode and network number(to handle properly the business groups). For VS and RD the network number will always be 0
        dashboard.widgets.forEach(widget => {
          widget.elements.forEach(element => {
            if (element.buffer != null && widget.type !== 'PAGE' && widget.type !== 'LINE' && widget.type !== 'TABLE') {
              this.constainsBuffers = true
            }
            let entry = this.entries.find(e => e.hexacode === element.hexacode && e.networkNumber === element.department)
            if (entry === undefined) {
              entry = {
                hexacode: element.hexacode,
                networkNumber: element.department,
                elements: []
              }
              this.entries.push(entry)
            }
            entry.elements.push(element)
          })
        })
        this.dashboard = dashboard
        this.dashboard.id = null
        this.dashboard.order = this.$store.getters['dashboards/nextDashboardOrder']
        this.loading = false
      }).catch(e => {
        this.loading = false
        this.showErrorNotification(this.$t('DashboardImportDialog.INVALID_FILE'))
      })
    }
  }
}
</script>
