<template>
  <div class="row pt-10 justify-center align-center h-100">
    <div class="col-12 col-md-6 col-lg-4">
      <v-card class="mx-auto">
        <v-card-title class="text--secondary">{{$t('Home.WELCOME_MESSAGE', [username])}}</v-card-title>
        <v-card-text>
          <v-divider class="pt-5"></v-divider>
          {{$t('Home.DASHBOARD_START_MESSAGE')}}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="createDashboard()">{{$t('Home.CREATE_DASHBOARD_MESSAGE')}}</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>
<script>
import { CREATE_DASHBOARD, TOGGLE_DASHBOARD_EDIT, OPEN_WIDGET_DETAILS_DIALOG } from '@/store/mutations'
export default {
  name: 'Home',
  computed: {
    username() {
      return this.$store.getters['security/username'] // TODO review getters access
    },
    selectedDashboard() {
      return this.$store.state.dashboards.selectedDashboard
    }
  },
  methods: {
    createDashboard() {
      this.showTextPromptDialog(this.$t('Home.ENTER_DASHBOARD_TITLE_MESSAGE'), 'Title', 'required', '')
        .then(r => this.$store.dispatch(`dashboards/${CREATE_DASHBOARD}`, r))
        .then(r => {
          this.showSuccessNotification(this.$t('Home.DASHBOARD_SAVED_MESSAGE'))
          this.$router.push({
            name: 'dashboard',
            params: {
              id: r.data.id
            }
          }).catch(e => console.log(e))
            .then(() => this.$store.commit(`dashboards/${TOGGLE_DASHBOARD_EDIT}`, true))
            .then(() => this.$store.commit(`dashboards/${OPEN_WIDGET_DETAILS_DIALOG}`, true))
        })
        .catch(e => this.showErrorNotification(this.$t('Home.TRY_AGAIN_MESSAGE', [e])))
    },
    redirect() {
      if (this.selectedDashboard) {
        this.$router.push({
          name: 'dashboard',
          params: {
            id: this.selectedDashboard.id
          }
        })
      }
    }
  },
  mounted() {
    this.redirect()
  },
  watch: {
    selectedDashboard: function(n, v) {
      this.redirect()
    }
  }
}
</script>
