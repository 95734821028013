import { BehaviorSubject } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { subscribeToPushNotifications, unsubscribeToPushNotifications, getVapidKey } from '@/api/backend.js'
export default {
  install(Vue, options = {
    store: null
  }) {
    // eslint-disable-next-line no-unused-vars
    let serviceWorkerRegistration = null // this can be used to send notifications directly from the vue app
    const emitter = new BehaviorSubject({
      type: null,
      payload: null
    })
    const urlBase64ToUint8Array = function(base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4)
      const base64 = (base64String + padding)
      // eslint-disable-next-line no-useless-escape
        .replace(/\-/g, '+')
        .replace(/_/g, '/')
      const rawData = window.atob(base64)
      const outputArray = new Uint8Array(rawData.length)
      for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
      }
      return outputArray
    }
    const sendSubscriptionToTheServer = function() {
      return navigator.serviceWorker.ready.then(registration => {
        serviceWorkerRegistration = registration
        return registration.pushManager.getSubscription()
          .then(subscription => {
            if (subscription) {
              return subscription
            }
            return getVapidKey().then(vapidKey => {
              return registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(vapidKey.data)
              })
            })
          }).then(subscription => {
            const vapidSubscriptionRequest = {
              endpoint: subscription.endpoint,
              keys: {
                p256dh: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh')))),
                auth: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth'))))
              }
            }
            return subscribeToPushNotifications(vapidSubscriptionRequest).then(r => {
              return vapidSubscriptionRequest
            })
          })
      })
    }
    Vue.prototype.$registerPushSubscription = function() {
      navigator.serviceWorker.addEventListener('message', event => emitter.next(event.data))
      return sendSubscriptionToTheServer()
    }
    Vue.prototype.$unregisterPushSubscription = function() {
      navigator.serviceWorker.removeEventListener('message', event => emitter.next(event.data))
      return unsubscribeToPushNotifications()
    }
    Vue.prototype.$serviceWorkerMessageStream = function(type = null) {
      if (type !== null) {
        return emitter.pipe(filter(e => e.type === type)).pipe(map(e => e.payload))
      }
      return emitter
    }
    Vue.prototype.$serviceWorkerRefresh = function() {
      serviceWorkerRegistration.active.postMessage({ action: 'refresh' })
    }
  }
}
