<template>
  <v-dialog max-width="600" v-model="visible" :fullscreen="fullscreen" scrollable persistent overlay-opacity="0.65">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :attrs="attrs" :on="on" />
    </template>
    <ValidationObserver ref="observer" @submit.prevent="submitBugReport" tag="form" eager>
      <v-card :loading="loading">
        <v-card-title class="justify-space-between">
          <div>
            <v-icon>mdi-shield-bug-outline</v-icon>
            <span class="pl-2">{{$t('BugReportForm.TITLE')}}</span>
          </div>
          <div>
            <v-btn icon small @click="fullscreen = !fullscreen" color="secondary">
              <v-icon v-if="!fullscreen">mdi-arrow-expand</v-icon>
              <v-icon v-if="fullscreen">mdi-arrow-collapse</v-icon>
            </v-btn>
            <v-btn icon small @click="visible = false" color="secondary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-card-text>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <ValidationProvider rules="email" :name="$t('BugReportForm.EMAIL_INPUT_LABEL')" v-slot="{ errors, valid }">
                  <v-text-field
                    v-model="bugReport.email"
                    :label="$t('BugReportForm.EMAIL_INPUT_LABEL')"
                    :error-messages="errors"
                    :success="valid"
                    required
                  ></v-text-field>
                </ValidationProvider>
              </div>
              <div class="col-12 pt-0">
                <v-checkbox :disabled="!hasEmail" v-model="sendMeACopy" dense :label="$t('BugReportForm.SEND_ME_A_COPY')" hide-details class="mt-0"></v-checkbox>
              </div>
              <div class="col-12">
                <ValidationProvider rules="required" :name="$t('BugReportForm.TITLE_INPUT_LABEL')" v-slot="{ errors, valid }">
                  <v-text-field
                    v-model="bugReport.title"
                    :label="$t('BugReportForm.TITLE_INPUT_LABEL')"
                    :error-messages="errors"
                    :success="valid"
                    required
                  ></v-text-field>
                </ValidationProvider>
              </div>
              <div class="col-12">
                <ValidationProvider rules="required" :name="$t('BugReportForm.CONTENT_INPUT_LABEL')" v-slot="{ errors, valid }">
                  <v-textarea
                    v-model="bugReport.description"
                    :label="$t('BugReportForm.CONTENT_INPUT_LABEL')"
                    :hint="$t('BugReportForm.CONTENT_INPUT_HINT')"
                    :error-messages="errors"
                    :success="valid"
                    required
                  ></v-textarea>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex w-100">
          <div class="pr-1 w-100">
            <v-btn outlined block color="accent" @click="visible = false" >{{$t('BugReportForm.CANCEL_MESSAGE')}}</v-btn>
          </div>
          <div class="pl-1 w-100">
            <v-btn outlined block color="primary" type="submit">{{$t("BugReportForm.APPLY_MESSAGE")}}</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
import { submitBugReport } from '@/api/backend'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
export default {
  name: 'BugReportForm',
  components: {
    ValidationProvider, ValidationObserver
  },
  data() {
    return {
      isVisible: false,
      fullscreen: false,
      loading: false,
      sendMeACopy: true,
      bugReport: this.emptyBugReport()
    }
  },
  computed: {
    visible: {
      get() {
        return this.isVisible
      },
      set(visible) {
        this.isVisible = visible
        this.bugReport = this.emptyBugReport()
      }
    },
    hasEmail() {
      return this.bugReport.email !== null && this.bugReport.email.trim().length > 0
    }
  },
  methods: {
    emptyBugReport() {
      return {
        title: '',
        email: this.$store.state.settings.email,
        description: '',
        status: 'OPEN'
      }
    },
    submitBugReport() {
      this.loading = true
      submitBugReport(this.bugReport, this.sendMeACopy).then(() => {
        this.showSuccessNotification(this.$t('BugReportForm.SAVE_SUCCESS'))
      }).catch(() => this.showErrorNotification(this.$t('BugReportForm.SAVE_ERROR')))
        .then(() => {
          this.loading = false
          this.visible = false
        })
    },
    validateData() {
      return this.$refs.observer.validate().then(isValid => {
        if (isValid) {
          return true
        }
        throw new Error('Validation failed')
      })
    }
  }
}
</script>
