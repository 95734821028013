
import Vue from 'vue'
import GenericPrompt from '@/components/GenericPrompt.vue'
import vuetify from './plugins/vuetify'
import GenericNotification from '@/components/GenericNotification.vue'
import DownloadNotification from '@/components/DownloadNotification.vue'
import clonedeep from 'lodash.clonedeep'
import { v4 as randomUUID } from 'uuid'
import { GRANTS_MIXIN } from '@/store/security/grants.js'

export const STATUS = {
  ON: {
    color: 'success',
    icon: 'mdi-check',
    isOnline: true,
    onlineEquivalent: 'ON',
    offlineEquivalent: 'OFF',
    equivalent: 'OFF',
    alarmClearEquivalent: 'ON',
    alarmEquivalent: 'ON_ALARM'
  },
  ON_ALARM: {
    color: 'error',
    icon: 'mdi-bell',
    isOnline: true,
    onlineEquivalent: 'ON_ALARM',
    offlineEquivalent: 'OFF_ALARM',
    equivalent: 'OFF_ALARM',
    alarmClearEquivalent: 'ON',
    alarmEquivalent: 'ON_ALARM'
  },
  OFF: {
    color: 'info',
    icon: 'mdi-close',
    isOnline: false,
    onlineEquivalent: 'ON',
    offlineEquivalent: 'OFF',
    equivalent: 'ON',
    alarmClearEquivalent: 'OFF',
    alarmEquivalent: 'OFF_ALARM'
  },
  OFF_ALARM: {
    color: 'info',
    icon: 'mdi-bell',
    isOnline: false,
    onlineEquivalent: 'ON_ALARM',
    offlineEquivalent: 'OFF_ALARM',
    equivalent: 'ON_ALARM',
    alarmClearEquivalent: 'OFF',
    alarmEquivalent: 'OFF_ALARM'
  }
}
/**
 * Shades a hex color, if the color contains transparency it will be ignored
 * @see {@link https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#--version-2-rgb-- | GitHub}
 * @param color  the hex color(7/9 chars)
 * @param percent ranges from -1 to 1. Negative means a darker shade, positive otherwise
 * @returns a shaded hex color(7/9 chars)
 */
export const shadeHexColor = function(color, percent) {
  let transparency = ''
  if (color.length > 7) {
    transparency = color.substring(7)
    color = color.substring(0, 7)
  }
  var f = parseInt(color.slice(1), 16)
  var t = percent < 0 ? 0 : 255
  var p = percent < 0 ? percent * -1 : percent
  var R = f >> 16
  var G = f >> 8 & 0x00FF
  var B = f & 0x0000FF
  return '#' + (0x1000000 +
    (Math.round((t - R) * p) + R) * 0x10000 +
    (Math.round((t - G) * p) + G) * 0x100 +
    (Math.round((t - B) * p) + B)
  ).toString(16).slice(1) + transparency
}
export const debounce = function(fn, delay) {
  var timeoutID = null
  return function() {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function() {
      fn.apply(that, args)
    }, delay)
  }
}
// TODO move them to export function and import when usable
Vue.mixin({
  methods: {
    ...GRANTS_MIXIN,
    randomUUID,
    shadeHexColor,
    $translateDataStatus(status) {
      if (status === 'DEVICE_NOT_FOUND') {
        return this.$t('DataStatus.DEVICE_NOT_FOUND')
      } else if (status === 'DEVICE_CAN_RECEIVED') {
        return this.$t('DataStatus.DEVICE_CAN_RECEIVED')
      } else if (status === 'ADF_NOT_FOUND') {
        return this.$t('DataStatus.ADF_NOT_FOUND')
      } else if (status === 'UNAUTHORIZED') {
        return this.$t('DataStatus.UNAUTHORIZED')
      } else if (status === 'LINE_NOT_PRESENT') {
        return this.$t('DataStatus.LINE_NOT_PRESENT')
      } else if (status === 'LINE_NOT_PRESENT_IN_ADF') {
        return this.$t('DataStatus.LINE_NOT_PRESENT_IN_ADF')
      } else if (status === 'DEPARTMENT_NOT_PRESENT') {
        return this.$t('DataStatus.DEPARTMENT_NOT_PRESENT')
      } else if (status === 'PAGE_NOT_PRESENT') {
        return this.$t('DataStatus.PAGE_NOT_PRESENT')
      } else if (status === 'NO_DATA') {
        return this.$t('DataStatus.NO_DATA')
      } else if (status === 'ERROR') {
        return this.$t('DataStatus.ERROR')
      }
      return status
    },
    $translate(tag, params) {
      if (params !== undefined) {
        return this.$t(tag, params)
      }
      return this.$t(tag)
    },
    $translatePlural(tag, count, params) {
      return this.$tc(tag, count, params)
    },
    getStatus(deviceStatus) {
      return STATUS[deviceStatus]
    },
    getDomain() {
      // if (process.env.NODE_ENV !== 'development') {
      //   return window.location.pathname.split('/')[1]
      // }
      return window.location.host.split('.')[0]
    },
    getSubdomain(hostname) {
      if (hostname !== 'localhost') {
        return window.location.host.split('.')[0]
      } else {
        var splittedHref = window.location.href.split('/')
        return splittedHref[splittedHref.length - 1]
      }
    },
    setDomainTheme(theme) {
      const dark = theme.dark
      const light = theme.light
      Object.keys(dark).forEach(i => {
        this.$vuetify.theme.themes.dark[i] = dark[i]
      })
      Object.keys(light).forEach(i => {
        this.$vuetify.theme.themes.light[i] = light[i]
      })
    },
    showDialog(config) {
      const Component = Vue.extend(GenericPrompt)
      const instance = new Component({
        vuetify,
        propsData: {
          ...config
        }
      })
      instance.$mount()
      // const element = document.getElementById('app')
      // this.$el
      // element.appendChild(instance.$el)
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        instance.$on('confirmed', r => {
          resolve(r)
          instance.$destroy()
        })
        // eslint-disable-next-line no-unused-vars
        instance.$on('cancelled', r => {
          if (config.triggerCancellation === true) {
            reject(new Error('cancelled', r))
          }
          instance.$destroy()
        })
      })
    },
    showSelectPromptDialog(title, label, rules, selectOptions, itemText = 'label', value = null) {
      const config = {
        type: 'select',
        title: title,
        selectValue: value,
        inputLabel: label,
        selectOptions: selectOptions,
        selectItemText: itemText,
        validationRules: rules
      }
      return this.showDialog(config)
    },
    showTextPromptDialog(title, label, rules, value = '') {
      const config = {
        type: 'prompt',
        title: title,
        inputValue: value,
        inputLabel: label,
        validationRules: rules
      }
      return this.showDialog(config)
    },
    showFilePromptDialog(title, label, rules, value = null) {
      const config = {
        type: 'file',
        title: title,
        inputValue: value,
        inputLabel: label,
        validationRules: rules
      }
      return this.showDialog(config)
    },
    showErrorNotification(message) {
      const config = {
        title: message,
        type: 'error'
      }
      return this.showNotification(config)
    },
    showSuccessNotification(message) {
      const config = {
        title: message,
        type: 'success'
      }
      return this.showNotification(config)
    },
    showWarningNotification(message) {
      const config = {
        title: message,
        type: 'warning'
      }
      return this.showNotification(config)
    },
    showDownloadNotification() {
      const config = {
        type: 'download'
      }
      return this.showNotification(config)
    },
    showNotification(config) {
      let Component
      if (config.type === 'download') {
        Component = Vue.extend(DownloadNotification)
      } else {
        Component = Vue.extend(GenericNotification)
      }
      const instance = new Component({
        vuetify,
        propsData: {
          ...config
        }
      })
      instance.$mount()
      this.$root.$el.appendChild(instance.$el)
      return new Promise((resolve) => {
        if (config.type === 'download') {
          resolve(instance)
        } else {
          instance.$on('hidden', () => {
            resolve()
            instance.$destroy()
          })
        }
      })
    },
    showConfirmationDialog(message, triggerCancellation) {
      const config = {
        type: 'confirmation',
        text: message,
        cancelText: this.$t('ConfirmationDialog.NO_MESSAGE'),
        okText: this.$t('ConfirmationDialog.YES_MESSAGE'),
        triggerCancellation: triggerCancellation
      }
      return this.showDialog(config)
    },
    clone(target) {
      return clonedeep(target)
    },
    downloadFile(element) {
      var downloadElement = document.createElement('a')
      var href = window.URL.createObjectURL(new Blob([element.data], { type: 'application/octet-stream' }))
      downloadElement.href = href
      downloadElement.download = element.headers.filename
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    }
  }
})
