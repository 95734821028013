<template>
  <v-dialog v-model="visible" :fullscreen="fullscreen" scrollable width="800" persistent overlay-opacity="0.80">
    <ValidationObserver ref="observer" @submit.prevent="saveWidget" tag="form">
      <v-card :loading="loading" v-if="widgetData !== null">
        <v-card-title class="justify-space-between">
          <div>
            <div>{{$t('WidgetDetailsDialog.WIDGET_DETAILS_MESSAGE')}}</div>
          </div>
          <div>
            <v-btn icon small @click="fullscreen = !fullscreen" color="secondary">
              <v-icon v-if="!fullscreen">mdi-arrow-expand</v-icon>
              <v-icon v-if="fullscreen">mdi-arrow-collapse</v-icon>
            </v-btn>
            <v-btn icon small @click="visible = false" color="secondary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-card-text>
          <div class="container container--fluid">
            <WidgetTypeSelector v-model="widgetData.type" @input="onTypeChange" v-if="widgetData.type === null"/>
            <div class="row" v-else>
              <div class="col-12">
                <ValidationProvider :rules="`required`" :name="$t('WidgetDetailsDialog.WIDGET_TYPE_LABEL')" v-slot="{ errors, valid }">
                  <v-select
                    v-model="widgetData.type"
                    :items="availableTypes"
                    item-text="name"
                    item-value="value"
                    :label="$t('WidgetDetailsDialog.WIDGET_TYPE_LABEL')"
                    dense :error-messages="errors" :success="valid">
                    <template v-slot:selection="{ item }">
                      <component :is="item.component"></component>
                      <div class="pl-2">{{item.name}}</div>
                    </template>
                    <template v-slot:item="{ item }">
                      <component :is="item.component"></component>
                      <div class="pl-2">{{item.name}}</div>
                    </template>
                  </v-select>
                </ValidationProvider>
              </div>
              <div class="col-12">
                <ValidationProvider :rules="`required`" :name="$t('WidgetDetailsDialog.WIDGET_NAME_LABEL_MESSAGE')" v-slot="{ errors, valid }">
                  <v-text-field v-model="widgetData.name" :label="$t('WidgetDetailsDialog.WIDGET_NAME_LABEL_MESSAGE')" dense :error-messages="errors" :success="valid"></v-text-field>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <WidgetDetailsNode :widget="widgetData" v-if="widgetData.type !== null"/>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-card-actions class="d-flex w-100">
          <div class="pr-1 w-100">
            <v-btn outlined block color="accent" @click="visible = false">{{$t('WidgetDetailsDialog.CANCEL_MESSAGE')}}</v-btn>
          </div>
          <div class="pl-1 w-100" v-if="widgetData.type !== null">
            <v-btn outlined block color="primary" type="submit" :disabled="disableSave">{{$t('WidgetDetailsDialog.SAVE_MESSAGE')}}</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import WidgetTypeSelector from './WidgetTypeSelector.vue'
import WidgetDetailsNode from '@/components/dashboards/widgets/dialog2/WidgetDetailsNode.vue'
import { defaultSymbol } from '@/components/dashboards/widgets/PictographWidgetConfig.js'
import { v4 as uuidv4 } from 'uuid'
import { DateTime } from 'luxon'
export default {
  name: 'WidgetDetailsDialog',
  components: {
    WidgetTypeSelector,
    ValidationObserver,
    ValidationProvider,
    WidgetDetailsNode
  },
  props: {
    isVisible: Boolean,
    widget: Object
  },
  computed: {
    visible: {
      get: function() {
        return this.isVisible
      },
      set: function(v) {
        this.$emit('closed')
      }
    },
    availableTypes() {
      return [
        { header: this.$t('WidgetTypeSelector.REALTIME_DATA_SECTION'), divider: true },
        ...this.realtimeAvailableTypes,
        { header: this.$t('WidgetTypeSelector.HISTORICAL_DATA_SECTION'), divider: true },
        ...this.historicalAvailableTypes
      ]
    },
    realtimeAvailableTypes() {
      return [
        { name: this.$t('WidgetTypeSelector.TYPE.CARD_CHART'), value: 'CARD_CHART', component: () => import('@/components/icons/CardGraph') },
        { name: this.$t('WidgetTypeSelector.TYPE.LINE'), value: 'LINE', component: () => import('@/components/icons/LineIcon') },
        { name: this.$t('WidgetTypeSelector.TYPE.PAGE'), value: 'PAGE', component: () => import('@/components/icons/Page') },
        { name: this.$t('WidgetTypeSelector.TYPE.TABLE'), value: 'TABLE', component: () => import('@/components/icons/Table') },
        { name: this.$t('WidgetTypeSelector.TYPE.PICTOGRAPH'), value: 'PICTOGRAPH', component: () => import('@/components/icons/Pictograph') },
        { name: this.$t('WidgetTypeSelector.TYPE.BAR_CHART'), value: 'BAR_CHART', component: () => import('@/components/icons/BarChart') }
      ]
    },
    historicalAvailableTypes() {
      return [
        { name: this.$t('WidgetTypeSelector.TYPE.LINE_CHART'), value: 'LINE_CHART', component: () => import('@/components/icons/Graph') },
        { name: this.$t('WidgetTypeSelector.TYPE.MULTIPLE_Y_CHART'), value: 'MULTIPLE_Y_CHART', component: () => import('@/components/icons/TwoYGraph') },
        { name: this.$t('WidgetTypeSelector.TYPE.MULTIGRID_CHART'), value: 'MULTIGRID_CHART', component: () => import('@/components/icons/MultipleGridsGraph') },
        { name: this.$t('WidgetTypeSelector.TYPE.COLLAPSABLE_TABLE'), value: 'COLLAPSABLE_TABLE', component: () => import('@/components/icons/CollapsableTable') }
      ]
    }
  },
  data() {
    return {
      fullscreen: false,
      loading: false,
      disableSave: false,
      showWidgetElementDetails: false,
      selectedWidgetElement: null,
      widgetData: this.emptyWidget()
    }
  },
  mounted() {
    if (this.$vuetify.breakpoint.mobile) {
      this.fullscreen = true
    }
  },
  methods: {
    async saveWidget() {
      if (this.widgetData.type !== null) {
        const valid = await this.$refs.observer.validate()
        if (valid) {
          this.$emit('save', this.clone(this.widgetData))
        }
        return
      }
      this.disableSave = true
      this.showErrorNotification(this.$t('WidgetDetailsDialog.SELECT_WIDGET_TYPE_MESSAGE'))
        .then(() => { this.disableSave = false })
    },
    onTypeChange() {
      if (this.widget === null) {
        let type = 'LAST_24_HOURS'
        let start = DateTime.now()
        const offset = start.offset
        const end = start.toUTC().plus({ minutes: offset })
        if (this.widgetData.type === 'CARD_CHART') {
          start = start.plus({ days: -7 }).toUTC().plus({ minutes: offset })
          type = 'LAST_7_DAYS'
        } else {
          start = start.plus({ hours: -24 }).toUTC().plus({ minutes: offset })
        }
        this.widgetData.interval = {
          start: start,
          end: end,
          type: type,
          compareTo: null
        }
      }
    },
    emptyWidget() {
      const start = DateTime.now().plus({ hours: -24 })
      const end = DateTime.now()
      const offset = start.offset
      let primary = this.$vuetify.theme.themes.light.primary
      if (primary.length === 7) {
        primary += 'FF'
      }
      return {
        id: uuidv4(),
        type: null,
        name: null,
        position: {
          x: 0,
          y: 0,
          w: 3,
          h: 4,
          i: null
        },
        interval: {
          start: start.toUTC().plus({ minutes: offset }),
          end: end.toUTC().plus({ minutes: offset }),
          type: 'LAST_24_HOURS',
          compareTo: null
        },
        elements: [],
        markAreaElements: [],
        dataPresentation: 'COLUMNS',
        fixedTitlesAndDescriptions: false,
        autoSort: false,
        min: 0.0,
        max: 1.0,
        showAxis: true,
        collapseOn: 'NONE',
        pageLength: 10,
        showTimestamps: true,
        timestampsIndex: -1,
        showUnits: true,
        defaultSymbol: defaultSymbol,
        defaultColor: primary,
        defaultBackgroundColor: '#dededfFF',
        showAverageValue: false,
        showLowestValue: false,
        showHighestValue: false,
        compareTypeTooltip: 'NONE',
        showCurrentValue: false,
        enableLineEdit: false
      }
    }
  },
  watch: {
    isVisible: function(newValue, oldValue) {
      this.widgetData.type = null
      if (newValue) {
        if (this.widget !== null) {
          this.widgetData = this.clone(this.widget)
          this.widgetData.elements.sort((l, r) => l.order - r.order)
        } else {
          this.widgetData = this.emptyWidget()
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
