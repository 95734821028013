<template>
  <div ref="item" class="vue-grid-item expand-transition-enter-active" :class="classObj" :style="style">
    <slot></slot>
    <v-icon v-if="editMode" ref="handle" class="vue-resizable-handle" color="primary">mdi-resize-bottom-right</v-icon>
  </div>
</template>
<style scoped>
.vue-grid-item {
  transition-property: transform;
  -ms-touch-action: none;
  touch-action: none;
  user-select: none;
}
.vue-grid-item.resizing {
    opacity: 0.6;
    z-index: 3;
}
.vue-grid-item.vue-draggable-dragging {
    user-select: none;
    transition:none;
    z-index: 3;
}
.vue-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
}
</style>
<script>
import { getControlPosition, createCoreData } from './draggableUtils'

import '@interactjs/auto-start'
import '@interactjs/auto-scroll'
import '@interactjs/actions/drag'
import '@interactjs/actions/resize'
import '@interactjs/modifiers'
import '@interactjs/dev-tools'
import interact from '@interactjs/interact'

export default {
  name: 'GridItem',
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    x: {
      type: Number,
      required: true
    },
    y: {
      type: Number,
      required: true
    },
    w: {
      type: Number,
      required: true
    },
    h: {
      type: Number,
      required: true
    },
    i: {
      required: true
    },
    containerWidth: {
      type: Number,
      default: 1904
    },
    columnsNumber: {
      type: Number,
      default: 12
    },
    columnWidth: {
      type: Number,
      default: 50
    },
    rowHeight: {
      type: Number,
      default: 50
    },
    margin: {
      type: Number,
      default: 10
    }
  },
  data: function() {
    return {
      minWidth: 2,
      minHeight: 3,
      isDragging: false,
      dragging: null,
      isResizing: false,
      resizing: null,
      lastX: NaN,
      lastY: NaN,
      lastW: NaN,
      lastH: NaN,

      dragEventSet: false,
      resizeEventSet: false,

      previousW: null,
      previousH: null,
      previousX: null,
      previousY: null
    }
  },
  created() {

  },
  beforeDestroy() {
    if (this.interactObj) {
      this.interactObj.unset() // destroy interact intance
    }
  },
  mounted() {
    if (this.editMode) {
      this.tryMakeDraggable()
      this.tryMakeResizable() // triggered be another means
    }
  },
  watch: {
    editMode: function() {
      this.tryMakeDraggable()
      this.tryMakeResizable()
    },
    columnsNumber: function() {
      this.tryMakeResizable()
      this.tryMakeDraggable()
    },
    containerWidth: function() {
      this.tryMakeResizable()
      this.tryMakeDraggable()
    }
  },
  computed: {
    innerX() {
      if (this.x + this.w > this.columnsNumber) {
        return 0
      }
      return this.x
    },
    innerY() {
      return this.y
    },
    innerW() {
      if (this.x + this.w > this.columnsNumber && this.w > this.columnsNumber) {
        return this.columnsNumber
      }
      return this.w
    },
    innerH() {
      return this.h
    },
    style() {
      const pos = this.calcPosition(this.innerX, this.innerY, this.innerW, this.innerH)
      if (this.isDragging) {
        pos.top = this.dragging.top
        pos.left = this.dragging.left
      }
      if (this.isResizing) {
        pos.width = this.resizing.width
        pos.height = this.resizing.height
      }
      const translate = 'translate3d(' + pos.left + 'px,' + pos.top + 'px, 0)'
      const style = {
        transform: translate,
        WebkitTransform: translate,
        MozTransform: translate,
        msTransform: translate,
        OTransform: translate,
        width: pos.width + 'px',
        height: pos.height + 'px',
        position: 'absolute'
      }
      return style
    },
    classObj() {
      return {
        'vue-resizable': this.editMode,
        resizing: this.isResizing,
        'vue-draggable-dragging': this.isDragging,
        cssTransforms: true,
        'disable-userselect': this.isDragging,
        'no-touch': this.isAndroid && this.editMode
      }
    },
    isAndroid() {
      return navigator.userAgent.toLowerCase().indexOf('android') !== -1
    }
  },
  methods: {
    handleResize(event) {
      const { x, y } = getControlPosition(event)
      const newSize = { width: 0, height: 0 }
      switch (event.type) {
        case 'resizestart': {
          // this.tryMakeResizable()
          this.previousW = this.innerW
          this.previousH = this.innerH
          const pos = this.calcPosition(this.innerX, this.innerY, this.innerW, this.innerH)
          newSize.width = pos.width
          newSize.height = pos.height
          this.resizing = newSize
          this.isResizing = true
          break
        }
        case 'resizemove': {
          const coreEvent = createCoreData(this.lastW, this.lastH, x, y)
          newSize.width = this.resizing.width + coreEvent.deltaX
          newSize.height = this.resizing.height + coreEvent.deltaY
          this.resizing = newSize
          break
        }
        case 'resizeend': {
          const pos = this.calcPosition(this.innerX, this.innerY, this.innerW, this.innerH)
          newSize.width = pos.width
          newSize.height = pos.height
          this.resizing = null
          this.isResizing = false
          break
        }
      }

      // Get new width and height
      const pos = this.calcWH(newSize.height, newSize.width)
      if (pos.w < this.minWidth) {
        pos.w = this.minWidth
      }
      if (pos.h < this.minHeight) {
        pos.h = this.minHeight
      }

      this.lastW = x
      this.lastH = y
      this.$emit('resizeEvent', event.type, this.i, this.innerX, this.innerY, pos.h, pos.w)
    },
    handleDrag(event) {
      if (this.isResizing) return
      // Get the current drag point from the event. This is used as the offset.
      const { x, y } = getControlPosition(event)

      const newPosition = { top: 0, left: 0 }
      switch (event.type) {
        case 'dragstart': {
          this.previousX = this.innerX
          this.previousY = this.innerY

          const parentRect = event.target.offsetParent.getBoundingClientRect()
          const clientRect = event.target.getBoundingClientRect()

          const cLeft = clientRect.left
          const pLeft = parentRect.left
          const cTop = clientRect.top
          const pTop = parentRect.top
          // difference between the parent and current el coordinates
          newPosition.left = cLeft - pLeft
          newPosition.top = cTop - pTop
          this.dragging = newPosition
          this.isDragging = true
          break
        }
        case 'dragend': {
          if (!this.isDragging) return
          const parentRect = event.target.offsetParent.getBoundingClientRect()
          const clientRect = event.target.getBoundingClientRect()

          const cLeft = clientRect.left
          const pLeft = parentRect.left
          const cTop = clientRect.top
          const pTop = parentRect.top

          newPosition.left = cLeft - pLeft
          newPosition.top = cTop - pTop

          this.dragging = null
          this.isDragging = false
          break
        }
        case 'dragmove': {
          const coreEvent = createCoreData(this.lastX, this.lastY, x, y)
          newPosition.left = this.dragging.left + coreEvent.deltaX
          newPosition.top = this.dragging.top + coreEvent.deltaY
          this.dragging = newPosition
          break
        }
      }

      // Get new XY
      const pos = this.calcXY(newPosition.top, newPosition.left)

      this.lastX = x
      this.lastY = y
      const dx = pos.x - this.previousX
      this.$emit('dragEvent', event.type, this.i, pos.x, pos.y, this.innerH, this.innerW, dx)
    },
    /**
     * Translate grid coordonates/sizes to pixels
     * @param {Number} x column number
     * @param {Number} y row number
     * @param {Number} w width in grid units
     * @param {Number} h height in grid units
     */
    calcPosition: function(x, y, w, h) {
      const out = {
        left: Math.round(this.columnWidth * x + (x + 1) * this.margin),
        top: Math.round(this.rowHeight * y + (y + 1) * this.margin),
        width: w === Infinity ? w : Math.round(this.columnWidth * w + Math.max(0, w - 1) * this.margin),
        height: h === Infinity ? h : Math.round(this.rowHeight * h + Math.max(0, h - 1) * this.margin)
      }

      return out
    },
    /**
     * Translate x and y coordinates from pixels to grid units.
     * @param  {Number} top  Top position (relative to parent) in pixels.
     * @param  {Number} left Left position (relative to parent) in pixels.
     * @return {Object} x and y in grid units.
     */
    calcXY(top, left) {
      let x = Math.round((left - this.margin) / (this.columnWidth + this.margin))
      let y = Math.round((top - this.margin) / (this.rowHeight + this.margin))
      // Capping
      x = Math.max(Math.min(x, this.columnsNumber - this.innerW), 0)
      y = Math.max(y, 0)
      return { x, y }
    },
    /**
     * Given a height and width in pixel values, calculate grid units.
     * @param  {Number} height Height in pixels.
     * @param  {Number} width  Width in pixels.
     * @return {Object} w, h as grid units.
     */
    calcWH(height, width) {
      let w = Math.round((width + this.margin) / (this.columnWidth + this.margin))
      let h = Math.round((height + this.margin) / (this.rowHeight + this.margin))
      // Capping
      w = Math.max(Math.min(w, this.columnsNumber - this.innerX), 0)
      h = Math.max(h, 0)
      return { w, h }
    },
    tryMakeDraggable() {
      const self = this
      if (this.interactObj === null || this.interactObj === undefined) {
        this.interactObj = interact(this.$refs.item)
      }
      if (this.editMode) {
        const opts = {
          allowFrom: '.drag-handler',
          ignoreFrom: 'a, button',
          autoScroll: true
        }
        this.interactObj.draggable(opts)
        if (!this.dragEventSet) {
          this.dragEventSet = true
          this.interactObj.on('dragstart dragmove dragend', function(event) {
            self.handleDrag(event)
          })
        }
      } else {
        this.interactObj.draggable({
          enabled: false
        })
      }
    },
    tryMakeResizable: function() {
      const self = this
      if (this.interactObj === null || this.interactObj === undefined) {
        this.interactObj = interact(this.$refs.item)
      }
      if (this.editMode) {
        const minimum = this.calcPosition(0, 0, this.minWidth, this.minHeight)
        const opts = {
          autoScroll: true,
          edges: {
            left: false,
            right: '.vue-resizable-handle',
            bottom: '.vue-resizable-handle',
            top: false
          },
          restrictSize: {
            min: {
              height: minimum.height,
              width: minimum.width
            }
          }
        }

        this.interactObj.resizable(opts)
        if (!this.resizeEventSet) {
          this.resizeEventSet = true
          this.interactObj
            .on('resizestart resizemove resizeend', function(event) {
              self.handleResize(event)
            })
        }
      } else {
        this.interactObj.resizable({
          enabled: false
        })
      }
    }
  }
}
</script>
