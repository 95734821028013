<template>
  <div class="row">
    <div class="col-6">
      <ValidationProvider rules="required" :name="$t('DashboardImportDeviceReplacementSelect.REPLACEMENT_DEVICE_FOR', [hexacode])" v-slot="{ errors, valid }" slim :skipIfEmpty="false">
        <v-select
          :items="devices"
          label="Replace with"
          v-model="device"
          clearable
          return-object
          :item-text="computeDeviceLabel"
          :item-value="computeDeviceSelectKey"
          :error-messages="errors"
          :success="valid"
          dense
          @change="updateElements">
          <template v-slot:prepend>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon :color="statusConstant[status].color" v-bind="attrs" v-on="on">{{statusConstant[status].icon}}</v-icon>
              </template>
              <span>{{statusConstant[status].message}}</span>
            </v-tooltip>
          </template>
          <template v-slot:prepend-item>
            <v-list-item>
              <v-list-item-content>
                <v-text-field label="Search" v-model="searchHint" hide-details dense></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:item="{item}">
            <span v-if="item.networkNumber" class="px-2 border-left">{{item.key}}</span>
            <span>{{computeDeviceLabel(item)}}</span>
          </template>
          <template v-slot:selection="{item}">
            <span class="text-truncate">
              <span v-if="item.networkNumber" class="pr-1">{{item.key}}</span>
              {{computeDeviceLabel(item)}}
            </span>
          </template>
        </v-select>
      </ValidationProvider>
    </div>
    <div class="col-6">
      <DashboardImportDepartmentReplacementSelect
        v-for="entry in departmentEntries"
        :key="`${hexacode}-${entry.department}-${entry.subDepartment}`"
        :device="device"
        :loading="departmentsLoading"
        :departmentNumber="entry.department"
        :subDepartmentNumber="entry.subDepartment"
        :widgetElements="entry.elements" />
    </div>
  </div>
</template>
<script>
import { LOAD_DEPARTMENTS_NAMES } from '@/store/mutations'
import { findByHexacode } from '@/store/devices'
import { ValidationProvider } from 'vee-validate'
import DashboardImportDepartmentReplacementSelect from '@/components/dashboards/import/DashboardImportDepartmentReplacementSelect.vue'
export default {
  name: 'DashboardImportDeviceReplacementSelect',
  components: {
    ValidationProvider, DashboardImportDepartmentReplacementSelect
  },
  props: {
    hexacode: String,
    networkNumber: Number,
    widgetElements: Array
  },
  computed: {
    status() {
      return this.device !== null ? 'OK' : 'FAIL'
    },
    devices() {
      const hint = this.searchHint.toLowerCase()
      const filtered = this.availableDevices.filter(d => d.name.toLowerCase().includes(hint) || d.hexacode.toLowerCase().includes(hint))
      const results = [] // add the devices under the business group when one it's matched
      filtered.forEach(e => {
        results.push(e)
        if (e.type === 'BUSINESS_GROUP') {
          e.devices.forEach(device => {
            results.push(device)
          })
        }
      })
      return results
    },
    availableDevices() {
      const devices = []
      this.$store.state.devices.devices.forEach(entry => {
        if (entry.type === 'BUSINESS_GROUP') {
          devices.push(entry)
          entry.devices.forEach(device => {
            devices.push(device)
          })
        } else {
          devices.push(entry)
        }
      })
      return devices
    },
    statusConstant() {
      return {
        OK: {
          color: 'success',
          message: this.$t('DashboardImportDeviceReplacementSelect.DEVICE_MATCHED'),
          icon: 'mdi-check'
        },
        FAIL: {
          color: 'warning',
          message: this.$t('DashboardImportDeviceReplacementSelect.DEVICE_NOT_AVAILABLE'),
          icon: 'mdi-alert-outline'
        }
      }
    }
  },
  data() {
    return {
      searchHint: '',
      device: null,
      departmentsLoading: false,
      departmentEntries: []
    }
  },
  created() {
    const entry = findByHexacode(this.hexacode)
    if (entry !== null) {
      if (entry.type === 'BUSINESS_GROUP' && this.networkNumber > 0) {
        const deviceEntry = entry.devices.get(this.networkNumber)
        if (deviceEntry !== undefined) {
          this.device = deviceEntry.device
        }
      } else {
        this.device = entry.device
      }
    }
    this.departmentEntries = []
    this.widgetElements.forEach(element => {
      let entry = this.departmentEntries.find(e => e.department === element.department && e.subDepartment === element.subDepartment)
      if (entry === undefined) {
        entry = {
          department: element.department,
          subDepartment: element.subDepartment,
          elements: []
        }
        this.departmentEntries.push(entry)
      }
      entry.elements.push(element)
    })
    this.triggerDepartmentNamesLoading(this.device)
  },
  methods: {
    computeDeviceLabel(device) {
      if (device.configuration !== undefined && device.configuration.label !== null) {
        return device.configuration.label
      }
      return device.name
    },
    computeDeviceSelectKey(device) {
      return device.key + device.hexacode
    },
    updateElements(device) {
      if (device === null) {
        this.widgetElements.forEach(e => {
          e.hexacode = null
          e.rawHexacode = null
          e.networkNumber = null
        })
      } else {
        this.widgetElements.forEach(e => {
          e.hexacode = device.hexacode
          e.rawHexacode = device.rawHexacode
          if (device.networkNumber != null) { // if we have a device inside a BG
            e.department = device.networkNumber
          } else if (device.type !== 'BUSINESS_GROUP') { // if we have a RD or VS
            e.department = 0
          } else { // just reset for other cases
            e.department = this.networkNumber
          }
        })
        this.triggerDepartmentNamesLoading(device)
      }
    },
    triggerDepartmentNamesLoading(device) {
      this.departmentsLoading = true
      this.$store.dispatch(`devices/${LOAD_DEPARTMENTS_NAMES}`, device)
        .then(() => { this.departmentsLoading = false })
    }
  }
}
</script>
