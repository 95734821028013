<template>
  <v-app>
    <v-alert
      :value="!online"
      :elevation="1"
      color="primary"
      icon="mdi-web-off"
      style='z-index:2000; position: absolute'
      class="w-100"
      dark
      dismissible>
      {{$t('MainLayout.NO_CONNECTION')}}
    </v-alert>
    <Sidebar :drawer.sync="drawer"/>
    <Headbar :drawer.sync="drawer"/>
    <v-main style="padding-bottom: 0px;">
      <v-container fluid class="h-100">
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer app></v-footer>
    <div class="d-none hidden"></div>
  </v-app>
</template>

<script>
import Sidebar from '@/components/sidebar/Sidebar.vue'
import Headbar from '@/components/headbar/Headbar.vue'

import { LOAD_DEVICES, LOAD_ACCOUNT_SETTIINGS, LOAD_DASHBOARDS_AND_GROUPS, REGISTER_VAPID_SUBSCRIPTION, DEREGISTER_VAPID_SUBSCRIPTION } from '@/store/mutations'
import store from '@/store'
export default {
  name: 'MainLayout',
  components: {
    Sidebar,
    Headbar
  },
  data() {
    return {
      drawer: true,
      online: false
    }
  },
  beforeRouteEnter(to, from, next) {
    const currentDashboardId = to.params.id
    next()
    Promise.all([
      store.dispatch(LOAD_ACCOUNT_SETTIINGS),
      store.dispatch(`dashboards/${LOAD_DASHBOARDS_AND_GROUPS}`, currentDashboardId),
      store.dispatch(`devices/${LOAD_DEVICES}`)
    ]).catch(e => {
      console.log(e)
    })
  },
  methods: {
    hasNetwork(online) {
      this.online = online
    }
  },
  mounted() {
    this.hasNetwork(navigator.onLine)

    window.addEventListener('online', () => {
      this.hasNetwork(true)
    })
    window.addEventListener('offline', (e) => {
      this.hasNetwork(false)
    })
    if ('serviceWorker' in navigator) {
      if (Notification.permission === 'granted') {
        this.$registerPushSubscription().then(subscription => {
          this.$store.dispatch(`security/${REGISTER_VAPID_SUBSCRIPTION}`, subscription)
        })
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(status => {
          if (status === 'granted') {
            this.$registerPushSubscription().then(subscription => {
              this.$store.dispatch(`security/${REGISTER_VAPID_SUBSCRIPTION}`, subscription)
            })
          } else if (this.$store.state.security.currentUser.metadata.vapidSubscription !== null) {
            this.$unregisterPushSubscription().then(subscription => {
              this.$store.dispatch(`security/${DEREGISTER_VAPID_SUBSCRIPTION}`, null)
            })
          }
        })
      } else if (Notification.permission === 'denied' && this.$store.state.security.currentUser.metadata.vapidSubscription !== null) {
        this.$unregisterPushSubscription().then(subscription => {
          this.$store.dispatch(`security/${DEREGISTER_VAPID_SUBSCRIPTION}`, null)
        })
      }
      this.$serviceWorkerMessageStream('Update').subscribe(event => {
        this.showDialog({ type: 'alert', text: this.$t('MainLayout.NEW_VERSION_AVAILABLE') })
          .then(() => this.$serviceWorkerRefresh())
      })
      this.$serviceWorkerMessageStream('notification-click').subscribe(event => {
        const route = {
          name: 'page',
          params: {
            hexacode: event.hexacode,
            department: event.department,
            subDepartment: event.subDepartment,
            page: event.page
          }
        }
        this.$router.push(route)
      })
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({ action: 'clear-badge' })
      }
    }
  }
}
</script>
