<template>
  <div :style="style" class="dashboard-grid expand-transition-enter-active">
      <GridItem
        v-for="position in layout"
        :key="position.i"
        :x="position.x"
        :y="position.y"
        :w="position.w"
        :h="position.h"
        :i="position.i"
        :editMode="editMode"
        :margin="margin"
        :breakpoint="breakpoint"
        :containerWidth="containerWidth"
        :columnsNumber="columnsNumber"
        :columnWidth="columnWidth"
        :rowHeight="rowHeight"
        :class="position.fullscreen ? 'fullscreen' : ''"
        @resizeEvent="hadleResizeEvent"
        @dragEvent="handleDragEvent">
        <slot name="widget" :position="position" :widget="widgets.get(position.i)" :toggleFullscreen="toggleFullscreen"></slot>
      </GridItem>
    <GridItem
      class="dashboard-grid-placeholder"
      v-show="showPlaceholder"
      :x="placeholder.x"
      :y="placeholder.y"
      :w="placeholder.w"
      :h="placeholder.h"
      :i="placeholder.i"
      :editMode="editMode"
      :margin="margin"
      :breakpoint="breakpoint"
      :containerWidth="containerWidth"
      :columnsNumber="columnsNumber"
      :columnWidth="columnWidth"
      :rowHeight="rowHeight"
      @resizeEvent="hadleResizeEvent"
      @dragEvent="handleDragEvent"/>
  </div>
</template>
<script>
import GridItem from '@/components/dashboards/grid/GridItem.vue'
import elementResizeDetectorMaker from 'element-resize-detector'
import { bottom, compact, getLayoutItem, moveElement, autoSetItemPosition } from './utils'
import { translateLayout } from './responsiveUtils'
export default {
  name: 'DashboardGrid',
  components: {
    GridItem
  },
  props: {
    editMode: Boolean,
    dashboard: Object
  },
  data() {
    return {
      fullscreen: false,
      widgets: new Map(),
      layout: [],
      breakpoints: {
        xl: 1904, lg: 1264, md: 960, sm: 600, xs: 0
      },
      thresholds: {
        xl: 12, lg: 12, md: 6, sm: 6, xs: 2
      },
      margin: 10,
      rowHeight: 60,
      columnWidth: 50,
      columnsNumber: 12,
      containerWidth: 1904,
      breakpoint: 'xl',
      lastBreakpoint: 'xl',
      originalLayout: null,
      showPlaceholder: false,
      layoutSet: new Map(),
      placeholder: {
        x: 0,
        y: 0,
        w: 0,
        h: 0,
        i: -1
      },
      elementResizeDetector: elementResizeDetectorMaker({
        strategy: 'scroll'
      })
    }
  },
  computed: {
    height() {
      return bottom(this.layout) * (this.rowHeight + this.margin) + this.margin
    },
    style() {
      const style = {}
      if (this.editMode) {
        const image = `<rect width="${this.columnWidth}" height="${this.rowHeight}" rx="4" style="fill:#afafaf5e; transform: translate(10px, 10px)" />`
        let svg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${this.columnWidth + this.margin}" height="${this.rowHeight + this.margin}">${image}</svg>`
        svg = Buffer.from(svg).toString('base64')
        style.backgroundImage = `url('data:image/svg+xml;base64,${svg}')`
      }
      style.height = `${this.height - this.margin}px`
      return style
    }
  },
  created() {
    this.$watch(
      () => this.dashboard.widgets,
      (newValue, oldValue) => {
        this.$nextTick(() => {
          this.computeWidthAndBreakpoints(this.$el.offsetWidth)
          this.initLayout()
        })
      },
      { immediate: true }
    )
  },
  mounted() {
    this.elementResizeDetector.listenTo(this.$el, (element) => {
      if (this.containerWidth !== element.offsetWidth) {
        this.$nextTick(() => {
          this.computeWidthAndBreakpoints(element.offsetWidth)
          if (this.lastBreakpoint !== this.breakpoint) {
            this.layout = translateLayout(this.layout, this.breakpoint, this.lastBreakpoint, this.thresholds)
            this.originalLayout = new Map()
            this.clone(this.layout).forEach(item => {
              this.originalLayout.set(item.i, item)
            })
          }
        })
      }
    })
  },
  beforeUnmount() {
    this.elementResizeDetector.removeAllListeners(this.$el)
  },
  methods: {
    computeWidthAndBreakpoints(width) {
      this.containerWidth = width
      let breakpoint = 'xs'
      if (this.containerWidth >= 1904) {
        breakpoint = 'xl'
      } else if (this.containerWidth >= 1264) {
        breakpoint = 'lg'
      } else if (this.containerWidth >= 960) {
        breakpoint = 'md'
      } else if (this.containerWidth >= 600) {
        breakpoint = 'sm'
      } else {
        breakpoint = 'xs'
      }
      this.lastBreakpoint = this.breakpoint
      this.breakpoint = breakpoint
      this.columnsNumber = this.thresholds[this.breakpoint]
      this.columnWidth = (this.containerWidth - (this.margin * (this.columnsNumber + 1))) / this.columnsNumber
    },
    toggleFullscreen(position, value) {
      this.$set(position, 'fullscreen', value)
    },
    updateLayout() {
      this.layout.forEach(p => {
        const widget = this.widgets.get(p.i)
        widget.position.x = p.x
        widget.position.y = p.y
        widget.position.w = p.w
        widget.position.h = p.h
      })
      this.dashboard.breakpoint = this.breakpoint
    },
    handleDragEvent(type, id, x, y, h, w, dx, dy) {
      const l = getLayoutItem(this.layout, id)
      if (l !== null) {
        if (type === 'dragmove' || type === 'dragstart') {
          this.placeholder.i = id
          this.placeholder.x = l.x
          this.placeholder.y = l.y
          this.placeholder.w = w
          this.placeholder.h = h
          this.showPlaceholder = true
        } else {
          this.showPlaceholder = false
        }
        // Move the element to the dragged location.
        this.layout = moveElement(this.layout, l, x, y, true)
        compact(this.layout)
        if (type === 'dragend') {
          const original = this.originalLayout.get(id)
          if (original.x !== l.x || original.y !== l.y) {
            this.updateLayout()
          }
        }
      }
    },
    hadleResizeEvent(type, id, x, y, h, w) {
      const l = getLayoutItem(this.layout, id)
      if (l !== null) {
        l.w = w
        l.h = h
        if (type === 'resizestart' || type === 'resizemove') {
          this.placeholder.i = id
          this.placeholder.x = x
          this.placeholder.y = y
          this.placeholder.w = l.w
          this.placeholder.h = l.h
          this.showPlaceholder = true
        } else {
          this.showPlaceholder = false
        }

        compact(this.layout)
        if (type === 'resizeend') {
          const original = this.originalLayout.get(id)
          if (original.w !== l.w || original.h !== l.h) {
            this.updateLayout()
          }
        }
      }
    },
    initLayout() {
      const widgets = new Map()
      const layoutMap = new Map()
      const layout = []
      let newItem = null
      this.dashboard.widgets.forEach(widget => {
        if (widget.position.i === null) { // new item was added
          widget.position.i = this.randomUUID()
          newItem = widget
          return
        }
        widgets.set(widget.position.i, widget)
        layout.push({
          name: widget.name,
          ...widget.position
        })
      })
      if (newItem !== null) { // add the new item at the end
        const position = autoSetItemPosition(newItem.position, layout, this.columnsNumber)
        newItem.position.x = position.x
        newItem.position.y = position.y
        widgets.set(newItem.position.i, newItem)
        layout.push({
          name: newItem.name,
          ...newItem.position
        })
      }
      this.widgets = widgets
      this.lastBreakpoint = this.dashboard.breakpoint
      this.layout = translateLayout(layout, this.breakpoint, this.lastBreakpoint, this.thresholds)
      this.clone(this.layout).forEach(item => {
        layoutMap.set(item.i, item)
      })
      this.originalLayout = layoutMap
    }
  }
}
</script>
<style lang="scss" scoped>
.fullscreen {
  width: 100vw !important;
  height: 100vh !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 10 !important;
  transform: translate3d(0px, 0px, 0px) !important;
  transition: none !important;
}
.dashboard-grid {
  position: relative;
  background-repeat: repeat;
}
.dashboard-grid-placeholder {
  background: red;
  opacity: 0.2;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
</style>
