<template>
  <v-component v-bind:is="component" :device="source">
    <template v-for="(index, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
  </v-component>
</template>
<script>
export default {
  name: 'ConfigurationNode',
  props: {
    source: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    component() {
      if (this.source.type === 'BUSINESS_GROUP') {
        return () => import('@/components/device-configuration/ConfigurationBussinessGroupNode')
      }
      return () => import('@/components/device-configuration/ConfigurationDeviceNode')
    }
  }

}
</script>
<style lang="css" scoped>
  * >>> .btn-config {
    text-transform: none !important;
    letter-spacing: normal !important;
    min-width: inherit !important;
    justify-content: flex-start !important;
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
</style>
