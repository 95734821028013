import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import i18n from './i18n'
import WebSocket from '@/plugins/websocket.js'
import Notifications from '@/plugins/notifications.js'
import DynamicScroller from '@/components/virtual-scroller/DynamicScroller.vue'
import DynamicScrollerItem from '@/components/virtual-scroller/DynamicScrollerItem.vue'

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import '@mdi/font/css/materialdesignicons.css'
import '@/assets/scss/utils.scss'
import '@/assets/scss/font.scss'
import '@/assets/scss/theme.scss'
import '@/registerServiceWorker'
import '@/vee-validate'
import '@/mixin.js'

import { LOAD_LOCAL_STORAGE, LOAD_THEME } from '@/store/mutations'

import { darkTheme } from '@/chart.dark.js'
import { whiteTheme } from '@/chart.white.js'
import { registerTheme, use } from 'echarts/core'

import { CanvasRenderer } from 'echarts/renderers'
import { LineChart, BarChart, PictorialBarChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  GridComponent,
  DataZoomSliderComponent,
  DataZoomInsideComponent,
  LegendComponent,
  MarkLineComponent,
  MarkAreaComponent,
  MarkPointComponent
} from 'echarts/components'

use([
  CanvasRenderer,
  LegendComponent,
  PictorialBarChart,
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  GridComponent,
  DataZoomSliderComponent,
  DataZoomInsideComponent,
  MarkLineComponent,
  MarkPointComponent,
  MarkAreaComponent
])

Vue.config.devtools = true
Vue.config.productionTip = false
Vue.component('DynamicScroller', DynamicScroller)
Vue.component('DynamicScrollerItem', DynamicScrollerItem)
Vue.use(WebSocket, {
  pingTimeout: 1000 * 30,
  pongTimeout: 1000 * 30,
  reconnectTimeout: 1000 * 15,
  pingMessage: 'heartbeat',
  store: store
})
Vue.use(Notifications, {
  store: store
})

store.dispatch(LOAD_LOCAL_STORAGE)
store.dispatch(LOAD_THEME, vuetify).then(r => {
  registerTheme('light', whiteTheme(vuetify.framework))
  registerTheme('dark', darkTheme(vuetify.framework))
})

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
