<template>
  <v-list-item :ripple="!line.readOnly" :link="!line.readOnly" dense class="border-bottom">
    <div class="row align-center my-0 w-100" v-if="line.status === 'OK'" @click="triggerLineEdit">
      <div class="col-6 text-truncate text-left py-0">{{prefix}}. {{name}}</div>
      <div class="col-6 py-0">
        <div class="row align-center my-0">
          <div class="col-6 text-right text--primary text-truncate py-0">{{line.value}}</div>
          <div class="col-6 d-flex justify-space-between align-center py-0 pl-0 text-left">
            <div class="text-truncate text--secondary">{{line.unit}}</div>
            <v-icon v-if="!line.readOnly" color="primary">mdi-square-edit-outline</v-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12 text-center">{{translatedStatus}}</div>
    </div>
  </v-list-item>
</template>
<script>
export default {
  name: 'DeviceLineView',
  props: {
    line: Object,
    number: {
      type: Number,
      default: null
    }
  },
  computed: {
    translatedStatus() {
      return this.$translateDataStatus(this.line.status)
    },
    name() {
      if (!this.line.customName) {
        return this.line.name
      }
      return this.line.customName
    },
    prefix() {
      if (this.number !== null) {
        return this.number + 1
      }
      return this.line.lineNumber
    }
  },
  methods: {
    triggerLineEdit() {
      if (!this.line.readOnly) {
        this.$emit('edit', this.line)
      }
    }
  }
}
</script>
