import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '@/i18n.js'
import dashboards from './dashboards'
import security from './security'
import devices from './devices'
import LocalStorage from '@/plugins/local-storage.js'
import { GRANTS } from '@/store/security/grants.js'
import { findAccountSettings, loadTheme } from '@/api/backend.js'
import {
  CHANGE_LANGUAGE, LOGOUT, UPDATE_DASHBOARDS_AND_GROUPS_SORTING_SETTINGS, UPDATE_DASHBOARD_SORTING_SETTINGS, UPDATE_DEVICE_CONFIGURATION_SETTINGS,
  UPDATE_THEME_SETTINGS, LOAD_THEME, LOAD_LOCAL_STORAGE, LOAD_ACCOUNT_SETTIINGS, UPDATE_ACCOUNT_SETTINGS
} from '@/store/mutations.js'
Vue.use(Vuex)

const domain = window.location.host.split('.')[0]
console.log(`Domain: ${domain}`)
function updateElement(selector, attribute, value) {
  const element = document.querySelectorAll(selector)
  if (element.length > 0) {
    element[0].setAttribute(attribute, value)
  }
}

export const themeSizes = {
  EXTRA_SMALL: {
    fontSize: 12,
    listItemMinHeight: 32.4,
    listItemDenseMinHeight: 27,
    dataTableRegularRowHeight: 32.4,
    dataTableDenseRowHeight: 27,
    dataTableMobileRowMinHeight: 27
  },
  SMALL: {
    fontSize: 14,
    listItemMinHeight: 37.8,
    listItemDenseMinHeight: 31.5,
    dataTableRegularRowHeight: 37.8,
    dataTableDenseRowHeight: 31.5,
    dataTableMobileRowMinHeight: 31.5
  },
  NORMAL: {
    fontSize: 16,
    listItemMinHeight: 43.2,
    listItemDenseMinHeight: 36,
    dataTableRegularRowHeight: 43.2,
    dataTableDenseRowHeight: 36,
    dataTableMobileRowMinHeight: 36
  },
  LARGE: {
    fontSize: 18,
    listItemMinHeight: 48.6,
    listItemDenseMinHeight: 40.5,
    dataTableRegularRowHeight: 48.6,
    dataTableDenseRowHeight: 40.5,
    dataTableMobileRowMinHeight: 40.5
  },
  EXTRA_LARGE: {
    fontSize: 20,
    listItemMinHeight: 54,
    listItemDenseMinHeight: 45,
    dataTableRegularRowHeight: 54,
    dataTableDenseRowHeight: 45,
    dataTableMobileRowMinHeight: 45
  }
}
export const themeFonts = {
  ROBOTO: {
    fontFamily: "'Roboto', sans-serif;",
    importRule: () => import('@fontsource/roboto/500.css')
  },
  OPEN_SANS: {
    fontFamily: "'Open Sans', sans-serif;",
    importRule: () => import('@fontsource/open-sans/500.css')
  },
  LATO: {
    fontFamily: "'Lato', sans-serif;",
    importRule: () => import('@fontsource/lato/400.css')
  },
  MONTSERRAT: {
    fontFamily: "'Montserrat', sans-serif;",
    importRule: () => import('@fontsource/montserrat/500.css')
  },
  OSWALD: { // ugly
    fontFamily: "'Oswald', sans-serif;",
    importRule: () => import('@fontsource/oswald/400.css')
  },
  SOURCE_SANS_PRO: {
    fontFamily: "'Source Sans Pro', sans-serif;",
    importRule: () => import('@fontsource/source-sans-pro/600.css')
  },
  SLABO_27PX: { // ugly
    fontFamily: "'Slabo 27px', sans-serif;",
    importRule: () => import('@fontsource/slabo-27px/400.css')
  },
  RALEWAY: {
    fontFamily: "'Raleway', sans-serif;",
    importRule: () => import('@fontsource/raleway/500.css')
  },
  PT_SANS: {
    fontFamily: "'PT Sans', sans-serif;",
    importRule: () => import('@fontsource/pt-sans/400.css')
  },
  MERRIWEATHER: {
    fontFamily: "'Merriweather', sans-serif;",
    importRule: () => import('@fontsource/merriweather/400.css')
  },
  NUNITO_SANS: {
    fontFamily: "'Nunito Sans', sans-serif;",
    importRule: () => import('@fontsource/nunito-sans/400.css')
  },
  PROMPT: {
    fontFamily: "'Prompt', sans-serif;",
    importRule: () => import('@fontsource/prompt/400.css')
  },
  WORK_SANS: {
    fontFamily: "'Work Sans', sans-serif;",
    importRule: () => import('@fontsource/work-sans/500.css')
  }
}
function updateThemeSettings(themeSettings) {
// based on theme size we have a number of preselected options
  const fontData = themeFonts[themeSettings.themeFont]
  const sizeData = themeSizes[themeSettings.themeSize]
  // we must add the ratio here as the icons are always bigger than text
  const iconRatio = 24 / 16
  const iconDenseRation = 20 / 16
  fontData.importRule()

  let element = document.getElementById('variables')
  if (element === null) {
    element = document.createElement('style')
    element.id = 'variables'
    document.head.appendChild(element)
  }
  const styleSheet = element.sheet
  while (styleSheet.cssRules.length > 0) {
    styleSheet.deleteRule(0)
  }
  const rule = `:root {
    --font-family: ${fontData.fontFamily} !important;
    --font-size: ${sizeData.fontSize}px !important;
    --icon-font-size: ${sizeData.fontSize * iconRatio}px !important;
    --icon-font-size-no-units: ${sizeData.fontSize * iconRatio};
    --icon-font-size-dense: ${sizeData.fontSize * iconDenseRation}px !important;
    --list-item-min-height: ${sizeData.listItemMinHeight}px !important;
    --list-dense-min-height: ${sizeData.listItemDenseMinHeight}px !important;
    --data-table-regular-row-height: ${sizeData.dataTableRegularRowHeight}px !important;
    --data-table-dense-row-height: ${sizeData.dataTableDenseRowHeight}px !important;
    --data-table-mobile-row-min-height: ${sizeData.dataTableMobileRowMinHeight}px !important;
  }`
  styleSheet.insertRule(rule, 0)
}
function defaultSettings() {
  return {
    showDevicesSearchBar: false,
    showHexacodesAsDeviceNames: true,
    showDepartmentNumbers: true,
    showNetworkControllers: true,
    enablePageRefreshOnRequest: false,
    enableFactorySettingsLines: false,
    themeSize: 'NORMAL',
    themeFont: 'ROBOTO',
    email: '',
    lineHeight: 'NORMAL',
    fontSize: 16,

    dateFormat: 'dd-LL-yyyy HH:mm',
    language: {
      code: 'EN',
      label: 'English'
    },
    dashboardsSettings: {
      sortMode: 'CUSTOM',
      sortDirection: 'ASC'
    },
    deviceConfigurationSettings: {
      sortMode: 'NAME',
      sortDirection: 'ASC'
    },
    defaultPcNumber: 1,
    pcNumbers: [1],
    minBufferInterval: 288
  }
}
export default new Vuex.Store({
  strict: true,
  state: {
    theme: {
      name: domain,
      loginLogo: null,
      sidebarLogo: null,
      manifest: null,
      favicons: [],
      theme: {
        dark: {
          accent: '#FF4081',
          error: '#FF5252',
          info: '#2196F3',
          primary: '#2196F3',
          secondary: '#242424',
          success: '#4CAF50',
          warning: '#FB8C00',
          sidebar: '#FFFFFF',
          sidebar_secondary: '#FFFFFF'
        },
        light: {
          accent: '#82B1FF',
          error: '#FF5252',
          info: '#2196F3',
          primary: '#1976D2',
          secondary: '#424242',
          success: '#4CAF50',
          warning: '#FB8C00',
          sidebar: '#000000de',
          sidebar_secondary: '#000000de'
        }
      }
    },
    domain: domain,
    settings: defaultSettings()
  },
  getters: {
    logo(state) {
      if (state.theme !== null) {
        return state.theme.loginLogo
      }
      return null
    },
    smallLogo(state) {
      if (state.theme !== null) {
        return state.theme.sidebarLogo
      }
      return null
    },
    availablePcNumbers(state) {
      let pcNumbers = state.settings.pcNumbers
      if (GRANTS.APPLY_ACL.WRITE()) {
        pcNumbers = Array.from({ length: 99 }, (value, index) => index + 1)
      }
      return pcNumbers
    }
  },
  mutations: {
    [LOAD_THEME](state, theme) {
      state.theme = theme
      updateElement('[name="theme-color"]', 'content', theme.manifest.theme_color)
      updateElement('[name="apple-mobile-web-app-title"]', 'content', state.domain)
      updateElement('[name="apple-mobile-web-status-bar-style"]', 'content', 'black')
      updateElement('[rel="mask-icon"]', 'color', theme.manifest.theme_color)
      updateElement('[name="msapplication-TileColor"]', 'content', theme.manifest.theme_color)
      const title = document.getElementsByTagName('title')
      if (title.length > 0) {
        title[0].innerHTML = state.domain.charAt(0).toUpperCase() + state.domain.slice(1)
      }
    },
    [UPDATE_THEME_SETTINGS](state, themeSettings) {
      state.settings.themeSize = themeSettings.themeSize
      state.settings.themeFont = themeSettings.themeFont
      updateThemeSettings(themeSettings)
    },
    [UPDATE_ACCOUNT_SETTINGS](state, settings) {
      state.settings = { ...state.settings, ...settings }
      state.settings.dateFormat = state.settings.dateFormat
        .replaceAll('DD', 'dd')
        .replaceAll('MM', 'LL')
        .replaceAll('YYYY', 'yyyy')
        .replaceAll('hh', 'HH')

      updateThemeSettings({
        themeSize: settings.themeSize,
        themeFont: settings.themeFont
      })
      i18n.locale = settings.language.code.toLowerCase()
    },
    [LOGOUT](state) {
      state.settings = defaultSettings()
    },
    [UPDATE_DEVICE_CONFIGURATION_SETTINGS](state, settings) {
      state.settings.deviceConfigurationSettings.sortMode = settings.sortMode
      state.settings.deviceConfigurationSettings.sortDirection = settings.sortDirection
    },
    [UPDATE_DASHBOARD_SORTING_SETTINGS](state, settings) {
      state.settings.dashboardsSettings.sortMode = settings.sortMode
      state.settings.dashboardsSettings.sortDirection = settings.sortDirection
    },
    [UPDATE_DASHBOARDS_AND_GROUPS_SORTING_SETTINGS](state, settings) {
      state.settings.dashboardsSettings.sortMode = settings.sortMode
      state.settings.dashboardsSettings.sortDirection = settings.sortDirection
    }
  },
  actions: {
    [LOGOUT](context) {
      context.commit(LOGOUT)
      context.commit(`devices/${LOGOUT}`)
      context.commit(`dashboards/${LOGOUT}`)
      context.dispatch(`security/${LOGOUT}`)
    },
    [LOAD_LOCAL_STORAGE](context) {
      return context.dispatch(`security/${LOAD_LOCAL_STORAGE}`)
    },
    [LOAD_THEME](context, $vuetify) {
      return loadTheme(domain)
        .then(r => {
          context.commit(LOAD_THEME, r.data)
          Object.keys(r.data.theme.light).forEach(key => {
            $vuetify.framework.theme.themes.light[key] = r.data.theme.light[key]
          })
          Object.keys(r.data.theme.dark).forEach(key => {
            $vuetify.framework.theme.themes.dark[key] = r.data.theme.dark[key]
          })
          const theme = LocalStorage.get('theme')
          if (theme !== null) {
            $vuetify.framework.theme.dark = (theme === 'dark')
          }
        })
    },
    [UPDATE_THEME_SETTINGS](context, themeSettings) {
      context.commit(UPDATE_THEME_SETTINGS, themeSettings)
    },
    [LOAD_ACCOUNT_SETTIINGS](context) {
      return findAccountSettings()
        .then(r => context.commit(UPDATE_ACCOUNT_SETTINGS, r.data))
    },
    [UPDATE_ACCOUNT_SETTINGS](context, settings) {
      const oldLanguageCode = context.state.settings.language.code
      context.commit(UPDATE_ACCOUNT_SETTINGS, settings)
      if (oldLanguageCode !== null && oldLanguageCode !== context.state.settings.language.code) {
        context.dispatch(CHANGE_LANGUAGE, settings.language)
      }
    },
    [UPDATE_DEVICE_CONFIGURATION_SETTINGS](context, settings) {
      context.commit(UPDATE_DEVICE_CONFIGURATION_SETTINGS, settings)
    },
    [CHANGE_LANGUAGE](context, language) {
      // refresh devices
      // refresh alarms
      // refresh widgets
      context.dispatch(`devices/${CHANGE_LANGUAGE}`)
    }
  },
  modules: { security, devices, dashboards }
})
