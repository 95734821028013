import Vue from 'vue'
import { findDevices, findPages, findLines, loadDepartmentsNames } from '@/api/backend'
import {
  LOGOUT, EXPAND_TO_PAGE, LOAD_DEVICES, LOAD_PAGES, LOAD_LINES, LOADING, UPDATE_DEVICES_CONFIGURATION, LOAD_DEPARTMENTS_NAMES,
  CHANGE_LANGUAGE, HANDLE_DEVICE_STATUS_EVENT, REMOVE_LINES
} from '@/store/mutations'
import store from '..'
import { DateTime } from 'luxon'
import { STATUS } from '@/mixin.js'

export function findByHexacode(hexacode) {
  const entry = store.state.devices.all.get(hexacode)
  if (entry !== undefined) {
    return entry
  }
  return null
}
export function findByHexacodeAndNetworkNumber(hexacode, networkNumber) {
  networkNumber = parseInt(networkNumber)
  const entry = findByHexacode(hexacode)
  if (entry !== null && entry.type === 'BUSINESS_GROUP') {
    const deviceEntry = entry.devices.get(networkNumber)
    if (deviceEntry !== undefined) {
      return deviceEntry.device
    }
  }
  return null
}
export function findByHexacodeAndDepartmentAndSubDepartment(hexacode, department, subDepartment) {
  department = parseInt(department)
  subDepartment = parseInt(subDepartment)
  const entry = findByHexacode(hexacode)
  let result = null
  if (entry !== null) {
    if (entry.type === 'BUSINESS_GROUP') {
      if (department === 0 && subDepartment === 0) {
        return entry.departments[0]
      }
      const deviceEntry = entry.devices.get(department)
      if (deviceEntry !== undefined) {
        result = deviceEntry.departments.get(subDepartment)
      }
    } else {
      const departmentEntry = entry.departments.get(department)
      if (departmentEntry !== undefined) {
        result = departmentEntry.get(subDepartment)
      }
    }
  }
  if (result !== undefined) {
    return result
  }
  return null
}
export function findByHexacodeAndDepartmentAndSubDepartmentAndPage(hexacode, department, subDepartment, page) {
  page = parseInt(page)
  const departmentEntry = findByHexacodeAndDepartmentAndSubDepartment(hexacode, department, subDepartment)
  if (departmentEntry !== null && departmentEntry.pages !== undefined) {
    const pageEntry = departmentEntry.pages.find(p => p.number === page)
    if (pageEntry !== undefined) {
      return pageEntry
    }
  }
  return null
}
export function findByHexacodeAndDepartmentAndSubDepartmentAndPageAndLine(hexacode, department, subDepartment, page, line) {
  page = parseInt(page)
  line = parseInt(line)
  const departmentEntry = findByHexacodeAndDepartmentAndSubDepartment(hexacode, department, subDepartment)
  if (departmentEntry !== null && departmentEntry.pages !== undefined) {
    const pageEntry = departmentEntry.pages.find(p => p.number === page)
    if (pageEntry !== undefined) {
      const lineEntry = pageEntry.lines.find(l => l.lineNumber === line)
      if (lineEntry !== undefined) {
        return lineEntry
      }
    }
  }
  return null
}
export default {
  namespaced: true,
  state: {
    status: 'LOADING',
    devices: [],
    all: new Map(),
    lastExpandedBusinessGroup: null,
    lastExpandedDevice: null,
    lastExpandedDepartment: null
  },
  getters: {
    status(state) {
      return state.status
    },
    devices(state) {
      return state.devices
    }
  },
  mutations: {
    [CHANGE_LANGUAGE](state) {
      state.devices.forEach(device => {
        // delete device[LOAD_DEPARTMENTS_NAMES]
        device.expanded = false
        if (device.type === 'BUSINESS_GROUP') {
          device.devices.forEach(d => {
            // delete d[LOAD_DEPARTMENTS_NAMES]
            d.expanded = false
            d.departments.forEach(department => {
              if (department.pages.length > 0) {
                department.pages.splice(0, department.pages.length)
              }
              department.expanded = false
              department.loaded = false
            })
          })
        }
        device.departments.forEach(department => {
          if (department.pages.length > 0) {
            department.pages.splice(0, department.pages.length)
          }
          department.expanded = false
          department.loaded = false
        })
      })
      // expand back the currently selected device/bg/department
      if (state.lastExpandedBusinessGroup !== null) {
        state.lastExpandedBusinessGroup.expanded = true
      }
      if (state.lastExpandedDevice !== null) {
        state.lastExpandedDevice.expanded = true
      }
      if (state.lastExpandedDepartment !== null) {
        state.lastExpandedDepartment.expanded = true
      }
    },
    [LOGOUT](state) {
      state.devices = []
      state.all = new Map()
      state.lastExpandedBusinessGroup = null
      state.lastExpandedDevice = null
      state.lastExpandedDepartment = null
      state.status = 'LOADING'
    },
    /**
     * Build all needed properties ahead
     * hexacode -> department -> subDepartment
     * device                    department
     * hexacode -> network number -> subDepartmentNumber
     * device      device            department
     */
    [LOAD_DEVICES](state, devices) { // TODO add refresh task for sidebar or add event handling for permission added/removed, device updated/added/removed
      devices.forEach(device => {
        device.expanded = false
        let entry = null
        if (device.type === 'BUSINESS_GROUP') {
          entry = {
            device: device,
            type: device.type,
            departments: [],
            devices: new Map()
          }
          device.departments.forEach(department => {
            department.expanded = false
            department.pages = []
            department.loaded = false
            entry.departments.push(department)
          })
          device.devices.forEach(d => {
            d.expanded = false
            const deviceEntry = {
              device: d,
              departments: new Map()
            }
            d.departments.forEach(department => { // normalization as network number === subDepartment
              department.expanded = false
              department.pages = []
              department.loaded = false
              deviceEntry.departments.set(department.subDepartment, department)
            })
            entry.devices.set(d.networkNumber, deviceEntry)
          })
        } else {
          entry = {
            device: device,
            type: device.type,
            departments: new Map()
          }
          const departmentEntry = new Map()
          device.departments.forEach(department => {
            department.expanded = false
            department.pages = []
            department.loaded = false
            department.loadedOn = null
            departmentEntry.set(department.subDepartment, department)
            entry.departments.set(department.department, departmentEntry)
          })
        }
        state.all.set(device.hexacode, entry)
      })
      state.devices = devices
      state.status = 'LOADED'
    },
    [LOAD_PAGES](state, data) {
      data.pages.forEach(page => {
        page.loaded = false
        page.loadedOn = null
        page.loadingPromise = null
        page.lines = []
      })
      data.department.loaded = true
      data.department.loadedOn = DateTime.now()
      data.department.pages = data.pages
    },
    [LOAD_LINES](state, data) {
      data.page.loaded = true
      data.page.loadedOn = DateTime.now()
      Vue.set(data.page, 'lines', data.lines)
    },
    [REMOVE_LINES](state, dashboard) {
      dashboard.widgets.forEach(widget => {
        widget.elements.forEach(element => {
          const page = findByHexacodeAndDepartmentAndSubDepartmentAndPage(element.hexacode, element.department, element.subDepartment, element.pageNumber)
          if (page !== null) {
            page.loaded = false
            page.loadedOn = null
            page.loadingPromise = null
            page.lines = []
          }
        })
      })
    },
    [LOADING](state, { object, value }) {
      Vue.set(object, 'loading', value)
    },
    [UPDATE_DEVICES_CONFIGURATION](state, data) {
      this.commit(`devices/${LOAD_DEVICES}`, data)
    },
    [LOAD_DEPARTMENTS_NAMES](state, data) {
      Vue.set(data.device, LOAD_DEPARTMENTS_NAMES, DateTime.now())
      data.departmentsNames.forEach(d => {
        data.device.departments.forEach(department => {
          if (d.key === department.key) {
            Vue.set(department, 'name', d.label)
          }
        })
      })
    },
    [HANDLE_DEVICE_STATUS_EVENT](state, event) {
      const type = event.type
      const payload = event.payload
      if (type === 'DeviceStatus') {
        const deviceEntry = findByHexacode(payload.hexacode)
        if (deviceEntry !== null) {
          deviceEntry.device.status = payload.status
          if (STATUS[payload.status].isOnline) {
            deviceEntry.device.departments.forEach(d => {
              console.log(d.hexacode, STATUS[d.status])
              d.status = STATUS[d.status].onlineEquivalent
            })
          } else {
            deviceEntry.device.departments.forEach(d => {
              console.log(d.hexacode, STATUS[d.status])
              d.status = STATUS[d.status].offlineEquivalent
            })
          }
        }
      } else if (type === 'DepartmentStatus') {
        const department = findByHexacodeAndDepartmentAndSubDepartment(payload.hexacode, payload.department, payload.subDepartment)
        if (department !== null) {
          department.status = payload.status
        }
      } else if (type === 'MappingStatus') {
        const device = findByHexacodeAndNetworkNumber(payload.hexacode, payload.networkNumber)
        if (device !== null) {
          device.status = payload.status
        }
      }
    },

    /**
     * Expands or collapses the given entry and remembers the last modified entry
     * @param device - BG/VS or department to expand/collapse
     */
    expand(state, device) {
      device.expanded = !device.expanded
      // console.log(device.name + ':' + device.expanded)
      if (device.type === 'BUSINESS_GROUP') {
        if (state.lastExpandedBusinessGroup !== null && state.lastExpandedBusinessGroup.id !== device.id) {
          state.lastExpandedBusinessGroup.expanded = false
        }
        if (state.lastExpandedDevice !== null && state.lastExpandedDevice.networkNumber === undefined) {
          state.lastExpandedDevice.expanded = false
        }
        state.lastExpandedBusinessGroup = device
      } else if (device.type === undefined) { // department
        if (state.lastExpandedDepartment !== null && state.lastExpandedDepartment.id !== device.id) {
          state.lastExpandedDepartment.expanded = false
        }
        state.lastExpandedDepartment = device
      } else { // device
        if (state.lastExpandedDevice !== null && state.lastExpandedDevice.id !== device.id) {
          // state.lastExpandedDevice.expanded = false
          Vue.set(state.lastExpandedDevice, 'expanded', false)
          if (device.networkNumber === undefined && state.lastExpandedBusinessGroup !== null) {
            state.lastExpandedBusinessGroup.expanded = false
          }
        }
        state.lastExpandedDevice = device
      }
    },
    markPromiseAsComplete(state, target) {
      target.loadingPromise = null
    },
    markPromiseAsLoading(state, { target, promise }) {
      target.loadingPromise = promise
    }
  },
  actions: {
    expand(context, device) {
      context.commit('expand', device)
      if (device.expanded && device.type !== undefined) {
        context.dispatch(LOAD_DEPARTMENTS_NAMES, device)
      }
    },
    [EXPAND_TO_PAGE](context, data) {
      const deviceEntry = findByHexacode(data.hexacode)
      if (deviceEntry !== null) {
        if (!deviceEntry.device.expanded) {
          this.dispatch('devices/expand', deviceEntry.device)
        }
        if (deviceEntry.type === 'BUSINESS_GROUP') {
          const device = findByHexacodeAndNetworkNumber(data.hexacode, data.department)
          if (device !== null && !device.expanded) {
            this.dispatch('devices/expand', device)
          }
        }
        const departmentEntry = findByHexacodeAndDepartmentAndSubDepartment(data.hexacode, data.department, data.subDepartment)
        if (departmentEntry !== null) {
          if (!departmentEntry.expanded) {
            this.dispatch('devices/expand', departmentEntry)
          }
          if (departmentEntry.status.indexOf('OFF') < 0) {
            this.dispatch(`devices/${LOAD_PAGES}`, departmentEntry)
          }
        }
      }
    },
    [LOAD_DEVICES](context) {
      return findDevices()
        .then(r => context.commit(LOAD_DEVICES, r.data))
        .catch(e => console.log(e))
    },
    [LOAD_PAGES](context, department) {
      const pagesRequest = {
        adf: department.adf,
        rawHexacode: department.rawHexacode,
        hexacode: department.hexacode,
        department: department.department,
        subDepartment: department.subDepartment
      }
      return findPages(pagesRequest)
        .then(r => context.commit(LOAD_PAGES, { department: department, pages: r.data }))
    },
    [LOAD_LINES](context, data) {
      if (data.page.loadingPromise !== null) {
        return data.page.loadingPromise
      }
      const linesRequest = {
        rawHexacode: data.rawHexacode,
        hexacode: data.hexacode,
        department: data.department,
        subDepartment: data.subDepartment,
        page: data.page.number
      }
      const promise = findLines(linesRequest)
        .then(r => context.commit(LOAD_LINES, { page: data.page, lines: r.data }))
        .finally(r => context.commit('markPromiseAsComplete', data.page))
      context.commit('markPromiseAsLoading', { target: data.page, promise: promise })
      return promise
    },
    [UPDATE_DEVICES_CONFIGURATION](context, data) {
      context.commit(UPDATE_DEVICES_CONFIGURATION, data)
    },
    force_load_department_names(context, device) {
      const request = []
      if (device.departments !== undefined) {
        device.departments.forEach(department => {
          request.push({
            key: department.key,
            adf: department.adf,
            hexacode: department.hexacode,
            rawHexacode: department.rawHexacode,
            department: department.department,
            subDepartment: department.subDepartment
          })
        })
        // context.commit(LOADING, { object: device, value: true })
        return loadDepartmentsNames(request).then(r => {
          context.commit(LOAD_DEPARTMENTS_NAMES, { device: device, departmentsNames: r.data })
        }).catch(e => console.log(e))
        // .then(() => context.commit(LOADING, { object: device, value: false }))
      }
    },
    [LOAD_DEPARTMENTS_NAMES](context, device) {
      // force a department name load every 5 minutes
      if (device[LOAD_DEPARTMENTS_NAMES] !== undefined) {
        const lastRequest = device[LOAD_DEPARTMENTS_NAMES]
        if (DateTime.now().minus({ minute: 5 }) < lastRequest) {
          return Promise.resolve()
        }
      }
      const request = []
      if (device.departments !== undefined) {
        device.departments.forEach(department => {
          request.push({
            key: department.key,
            adf: department.adf,
            hexacode: department.hexacode,
            rawHexacode: department.rawHexacode,
            department: department.department,
            subDepartment: department.subDepartment
          })
        })
        // context.commit(LOADING, { object: device, value: true })
        return loadDepartmentsNames(request).then(r => {
          context.commit(LOAD_DEPARTMENTS_NAMES, { device: device, departmentsNames: r.data })
        }).catch(e => console.log(e))
        // .then(() => context.commit(LOADING, { object: device, value: false }))
      }
    },
    [HANDLE_DEVICE_STATUS_EVENT](context, deviceStatusEvent) {
      context.commit(HANDLE_DEVICE_STATUS_EVENT, deviceStatusEvent)
    },
    [CHANGE_LANGUAGE](context, language) {
      context.commit(CHANGE_LANGUAGE)
      if (context.state.lastExpandedBusinessGroup !== null) {
        this.dispatch('devices/force_load_department_names', context.state.lastExpandedBusinessGroup)
      }
      if (context.state.lastExpandedDevice !== null) {
        this.dispatch('devices/force_load_department_names', context.state.lastExpandedDevice)
      }
      if (context.state.lastExpandedDepartment !== null) {
        this.dispatch(`devices/${LOAD_PAGES}`, context.state.lastExpandedDepartment)
      }
      // clear LOAD_DEPARTMENTS_NAMES flag from all departments
      // clear pages from loaded departments
      // refresh department names for the expanded device/bg
      // refresh pages/lines for the expanded department(if any)
    },
    [REMOVE_LINES](context, dashboard) {
      context.commit(REMOVE_LINES, dashboard)
    }
  }
}
