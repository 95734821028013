<template>
  <v-app>
    <v-main>
      <v-container fluid class="h-100">
        <div class="align-center d-flex flex-column h-100 justify-center">
          <img :src="logo" style="max-width: 300px" class="pb-10 pb-md-16 login-logo" />
          <ValidationObserver ref="observer" style="max-width: 500px" @submit.prevent="sendLoginRequest(null)" tag="form">
            <v-card elevation="2" :loading="loading">
              <div class="container">
                <div class="row">
                  <div class="col-12 pt-8 active">
                    <ValidationProvider rules="required" :name="$t('Login.USERNAME_LABEL_MESSAGE')" v-slot="{ errors }">
                      <v-text-field v-model="username" :label="$t('Login.USERNAME_LABEL_MESSAGE')" required dense :error-messages="errors" focus>
                        <v-icon slot="prepend"> mdi-account-circle-outline </v-icon>
                        <v-icon v-if="username" slot="append" @click="username = ''">mdi-close</v-icon>
                      </v-text-field>
                    </ValidationProvider>
                  </div>
                  <div class="col-12 active">
                    <ValidationProvider rules="required" :name="$t('Login.PASSWORD_LABEL_MESSAGE')" v-slot="{ errors }">
                      <v-text-field v-model="password" :label="$t('Login.PASSWORD_LABEL_MESSAGE')" required dense :type="isPasswordVisible ? 'password' : 'text'" :error-messages="errors">
                        <v-icon slot="prepend"> mdi-lock-outline </v-icon>
                        <v-icon slot="append" @click="isPasswordVisible = !isPasswordVisible">{{ isPasswordVisible ?
                          "mdi-eye-off-outline" : "mdi-eye-outline" }}</v-icon>
                      </v-text-field>
                    </ValidationProvider>
                  </div>
                  <div v-if="errorMessage" class="col-12 text-center red--text">{{ errorMessage }}</div>
                  <div class="col-12 d-flex justify-space-between align-center">
                    <v-checkbox v-model="rememberMe" :label="$t('Login.REMEMBER_ME_LABEL_MESSAGE')" dense hide-details class="pa-0 ma-0" />
                    <router-link class="font-weight-regular text-decoration-none" to="/forgot-password">{{
                      $t('Login.FORGOT_PASSWORD_MESSAGE') }}</router-link>
                  </div>
                  <div class="col-12">
                    <v-btn block outlined elevation="2" color="primary" type="submit">{{ $t('Login.SIGN_IN_MESSAGE') }}</v-btn>
                  </div>
                </div>
              </div>
            </v-card>
          </ValidationObserver>
          <TwoFactorAuthentication :visible="showMfa" @sendLoginRequest="sendLoginRequest" :loading="loading" :message="errorMessage" @closed="showMfa = !showMfa" />
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { LOGIN } from '@/store/mutations'
import { login } from '@/api/backend.js'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import TwoFactorAuthentication from '@/pages/TwoFactorAuthentication.vue'

export default {
  name: 'Login',
  components: {
    ValidationObserver,
    ValidationProvider,
    TwoFactorAuthentication
  },
  data() {
    return {
      username: '',
      password: '',
      rememberMe: false,
      isPasswordVisible: true,
      errorMessage: '',
      showMfa: false,
      loading: false
    }
  },
  computed: {
    logo() {
      return this.$store.getters.logo
    }
  },
  methods: {
    sendLoginRequest(mfaCode) {
      this.loading = true
      login(this.username.trim(), this.password.trim(), this.$store.state.domain, this.rememberMe, mfaCode)
        .then(r => {
          if (r.status === 202) {
            this.showMfa = true
            if (mfaCode !== null) {
              this.errorMessage = this.$t('Login.INVALID_MFA_CODE')
            }
          } else {
            return this.$store.dispatch(`security/${LOGIN}`, r.data)
              .then(() => this.$router.push({ name: 'home' }))
          }
        })
        .catch((e) => {
          if (e.response !== undefined && e.response.status === 401) {
            if (mfaCode !== null) {
              this.errorMessage = this.$t('Login.INVALID_MFA_CODE')
            } else {
              this.errorMessage = this.$t('Login.INVALID_CREDENTIALS_MESSAGE')
            }
          } else if (e.response !== undefined && e.response.status === 423) {
            this.errorMessage = e.response.headers['x-locked-message']
          } else if (mfaCode !== null) {
            this.showErrorNotification(this.$t('Login.CREDENTIALS_ERROR_MESSAGE'))
          } else {
            this.errorMessage = this.$t('Login.CREDENTIALS_ERROR_MESSAGE')
          }
        }).then(r => {
          this.loading = false
        })
    }
  }
}
</script>
<style scoped>
.active>>>.v-text-field .v-label {
  max-width: 133% !important;
  transform: translateY(-18px) scale(0.75) !important;
  pointer-events: auto !important;
}

.theme--dark .login-logo {
  filter: brightness(0.0) invert(1) grayscale(1)
}
</style>
