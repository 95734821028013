<template>
<div class="d-flex align-center justify-space-between">
  <div class="d-flex align-center">
    <div>
      <ColorPickerMenu v-model="element.color"/>
    </div>
    <div class="pl-4 limit-width">
      <ValidationProvider rules="required" :name="$t('WidgetMarkAreaElement.NAME_LABEL_MESSAGE')" v-slot="{ errors, valid }">
        <v-text-field :label="$t('WidgetMarkAreaElement.NAME_LABEL_MESSAGE')" v-model="element.label" clearable :error-messages="errors" :success="valid"></v-text-field> <!-- add direction icon for the text -->
      </ValidationProvider>
    </div>
    <div class="pl-4 limit-width">
      <DateTimePicker v-model="element.startDate" :label="$t('DateTimePicker.START_DATE_LABEL_MESSAGE')"/>
    </div>
    <div class="pl-4 pr-4 limit-width">
      <DateTimePicker v-model="element.endDate" :label="$t('DateTimePicker.END_DATE_LABEL_MESSAGE')" :rules="rules" :min="element.startDate"/>
    </div>
  </div>
  <v-btn icon color="accent" @click="$emit('remove')">
    <v-icon>mdi-trash-can</v-icon>
  </v-btn>
</div>
</template>
<script>
import { ValidationProvider } from 'vee-validate'
import DateTimePicker from '@/components/DateTimePicker.vue'
import ColorPickerMenu from '@/components/ColorPickerMenu.vue'
export default {
  name: 'WidgetMarkAreaElement',
  components: {
    ValidationProvider,
    ColorPickerMenu,
    DateTimePicker
  },
  props: {
    element: Object
  },
  computed: {
    rules() {
      return `after:${this.element.startDate}`
    }
  }
}
</script>
<style lang="scss" scoped>
.limit-width {
  max-width: 200px;
}
</style>
