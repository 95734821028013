<template>
  <v-dialog v-model="visible" width="600" persistent :fullscreen="fullscreen" scrollable overlay-opacity="0.65">
    <ValidationObserver ref="observer" @submit.prevent="save" tag="form">
      <v-card :loading="loading">
        <v-card-title class="justify-space-between">
          <div>
            <div>{{$t("DeviceLineDetailsDialog.LINE_DETAILS_MESSAGE")}}</div>
          </div>
          <div>
            <v-btn icon small @click="fullscreen = !fullscreen" color="secondary">
              <v-icon v-if="!fullscreen">mdi-arrow-expand</v-icon>
              <v-icon v-if="fullscreen">mdi-arrow-collapse</v-icon>
            </v-btn>
            <v-btn icon small @click="visible = false" color="secondary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider class="mx-4 pb-5"></v-divider>
        <v-card-text class="pt-5">
          <ValidationProvider rules="required" :name="$t('DeviceLineDetailsDialog.LINE_VALUE_MESSAGE')" v-slot="{ errors, valid }">
            <v-select
              v-if="options.length > 0"
              v-model="value"
              :items="options"
              item-text="label"
              item-value="index"
              :label="name"
              return-object
              required
              :error-messages="errors"
              :success="valid"/>
            <v-text-field
              v-else
              v-model="value"
              :label="name"
              :error-messages="errors" :success="valid"/>
          </ValidationProvider>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex w-100">
          <div class="pr-1 w-100">
            <v-btn outlined block color="accent" @click="visible = false">{{$t('DeviceLineDetailsDialog.CANCEL_MESSAGE')}}</v-btn>
          </div>
          <div class="pl-1 w-100">
            <v-btn outlined block color="primary" type="submit">{{$t("DeviceLineDetailsDialog.SAVE_MESSAGE")}}</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { saveLine } from '@/api/backend.js'
export default {
  name: 'DeviceLineDetailsDialog',
  props: {
    isVisible: Boolean,
    line: Object
  },
  components: {
    ValidationObserver, ValidationProvider
  },
  data: function() {
    return {
      fullscreen: false,
      loading: false,
      name: null,
      value: null,
      options: []
    }
  },
  computed: {
    visible: {
      get: function() {
        return this.isVisible
      },
      set: function(v) {
        this.$emit('closed')
      }
    }
  },
  methods: {
    save() {
      this.loading = true
      const requestBody = {
        hexacode: this.line.hexacode,
        department: this.line.department,
        subDepartment: this.line.subDepartment,
        page: (this.line.page !== null && this.line.page !== undefined) ? this.line.page : this.line.pageNumber,
        line: (this.line.number !== null && this.line.number !== undefined) ? this.line.number : this.line.lineNumber, // edit it's from widget element or from device page
        value: this.value
      }
      if (typeof this.value === 'object') {
        requestBody.value = this.value.index
      }
      saveLine(requestBody).then(r => {
        this.$emit('update', requestBody)
        this.visible = false
        if (r.status === 200) {
          this.showSuccessNotification(this.$t('DeviceLineDetailsDialog.LINE_UPDATED_MESSAGE'))
          return
        }
        this.showWarningNotification(this.$t('DeviceLineDetailsDialog.LINE_UPDATED_NOT_ACCEPTED_MESSAGE'))
      }).catch(e => this.showErrorNotification(this.$t('DeviceLineDetailsDialog.UPDATE_ERROR_MESSAGE')))
        .then(() => { this.loading = false })
    }
  },
  watch: {
    isVisible: function(newValue, oldValue) {
      if (newValue) {
        this.name = this.line.name
        this.value = this.line.value
        this.options = this.line.options
        if (this.options.length > 0) {
          const option = this.options.find(o => o.label === this.value)
          if (option !== undefined) {
            this.value = option.index
          }
        }
      }
    }
  }
}
</script>
