import api from '@/plugins/axios.js'

export function login(username, password, domain, rememberMe, mfaCode) {
  if (mfaCode !== null) {
    return api.post('/login', {
      username: username,
      password: password,
      domain: domain,
      rememberMe: rememberMe,
      mfaCode: mfaCode,
      type: 'DOMAIN_MFA'
    })
  } else {
    return api.post('/login', {
      username: username,
      password: password,
      domain: domain,
      rememberMe: rememberMe,
      type: 'DOMAIN'
    })
  }
}
export function logout() {
  return api.post('/logout')
}
export function findAccountSettings() {
  return api.get('/api/v2/account/settings')
}
export function saveAccountSettings(accountSettings) {
  return api.post('/api/v2/account/settings', accountSettings)
}
export function updateThemeSettings(themeSettings) {
  return api.post('/api/v2/account/settings/theme', themeSettings)
}
export function saveConfiguration(requestData) {
  return api.post('/api/v2/account/settings/devices', requestData)
}
export function findAvailableLanguages() {
  return api.get('/api/v2/account/settings/languages')
}

export function findDevices() {
  return api.get('/api/v2/devices/')
}
export function loadDepartmentsNames(requestData) {
  return api.post('/api/v2/devices/departments/names', requestData)
}
export function findPages(pagesRequest) {
  return api.post('/api/v2/devices/departments/pages', pagesRequest)
}
export function findLines(linesRequest) {
  return api.post('/api/v2/devices/departments/pages/lines', linesRequest)
}
export function refreshLines(linesRefreshRequest) {
  return api.post('/api/v2/devices/departments/pages/lines/refresh', linesRefreshRequest)
}
export function saveLine(lineUpdateRequest) {
  return api.put('/api/v2/devices/departments/pages/lines', lineUpdateRequest)
}
// theme api
// export function loadTheme(domain) {
//   return api.get(`/themes/${domain}`)
// }
// dashboards api
export function findDashboardById(id) {
  return api.get(`/api/v2/dashboards/${id}`)
}
export function saveDashboard(dashboard) {
  return api.put('/api/v2/dashboards/', dashboard)
}
export function createDashboard(dashboard) {
  return api.post('/api/v2/dashboards/', dashboard)
}
export function deleteDashboard(id) {
  return api.delete(`/api/v2/dashboards/${id}`)
}
export function deleteDashboardsGroup(id) {
  return api.delete(`/api/v2/dashboards/groups/${id}`)
}
export function updateFavoriteDashboard(id) {
  return api.post(`/api/v2/dashboards/${id}/favorite`)
}
export function renameDashboard(id, name) {
  return api.post(`/api/v2/dashboards/${id}/rename`, null, {
    params: {
      name: name
    }
  })
}
export function sortDashboardsAndGroups(sortingData) {
  return api.post('/api/v2/dashboards/update-sorting-settings', sortingData)
}
export function createDashboardsGroup(dashboardsGroup) {
  return api.post('/api/v2/dashboards/groups', dashboardsGroup)
}
export function updateDashboardsGroup(dashboardsGroup) {
  return api.put('/api/v2/dashboards/groups', dashboardsGroup)
}
export function findWidgetData(requestBody) {
  return api.post('/api/v2/dashboards/widgets', requestBody)
}
export function downloadWidgetData(request, exportType, fileName) {
  return api.post('/api/v2/dashboards/widgets/download', request, {
    params: {
      exportType: exportType,
      fileName: fileName
    },
    responseType: 'blob'
  })
}

export function saveWidgetMarkAreaElements(dashboardId, widgetId, elements) {
  return api.post('/api/v2/dashboards/widgets/mark-area-elements', elements, {
    params: {
      dashboard: dashboardId,
      widget: widgetId
    }
  })
}

export function getInitialAlarms() {
  return api.get('/api/v2/alarms/')
}
export function getAlarmHistory() {
  return api.get('/api/v2/alarms/history')
}

export function subscribeToPushNotifications(vapidSubscription) {
  return api.post('/api/v2/notifications/subscribe', vapidSubscription)
}

export function unsubscribeToPushNotifications(n) {
  return api.get('/api/v2/notifications/unsubscribe')
}

export function getVapidKey() {
  return api.get('/api/v2/notifications/key')
}

export function loadTheme(domain) {
  return api.get('/themes')
}
export function getDashboardElements() {
  return api.get('/api/v2/dashboards/')
}
export function validateDashboardsGroupName(id, name) {
  return api.get('/api/v2/dashboards/groups/validate/name', {
    params: {
      id: id,
      name: name
    }
  })
}
export function sendTestNotification() {
  return api.get('/api/v2/notifications/test-notification')
}
export function submitFeatureRequest(featureRequest, sendMeACopy) {
  return api.post('/api/v2/support/feature-request', featureRequest, {
    params: {
      sendMeACopy: sendMeACopy
    }
  })
}
export function submitBugReport(bugReport, sendMeACopy) {
  return api.post('/api/v2/support/bug-report', bugReport, {
    params: {
      sendMeACopy: sendMeACopy
    }
  })
}
export function paginateAccounts(paginationRequest) {
  return api.post('/api/v2/account/paginate', paginationRequest)
}
export function switchAccount(account) {
  return api.post(`/api/v2/account/switch/${account}`)
}
export function switchBackAccount() {
  return api.post('/api/v2/account/switch-back')
}
export function recoverPassword(email) {
  return api.put('/api/v2/account/recover-password', null, {
    params: {
      email: email
    }
  })
}
export function validateRecoverPasswordToken(token) {
  return api.get('/api/v2/account/recover-password', {
    params: {
      token: token
    }
  })
}
export function finishRecoverPassword(token, passwordSetup) {
  return api.post('/api/v2/account/recover-password', passwordSetup, {
    params: {
      token: token
    }
  })
}

export function setup2Fa() {
  return api.get('/api/v2/account/settings/2fa')
}
export function verify2Fa(secret, code) {
  return api.post('/api/v2/account/verify-2fa', secret, {
    params: {
      code: code
    }
  })
}
