<template>
  <v-dialog v-model="visible" width="800" persistent :fullscreen="fullscreen" scrollable overlay-opacity="0.65">
    <v-card>
      <v-card-title class="justify-space-between">
        <div>
          <div>{{$t("AlarmHistoryDialog.TITLE_MESSAGE")}}</div>
        </div>
        <div>
          <v-btn icon small @click="fullscreen = !fullscreen" color="secondary">
            <v-icon v-if="!fullscreen">mdi-arrow-expand</v-icon>
            <v-icon v-if="fullscreen">mdi-arrow-collapse</v-icon>
          </v-btn>
          <v-btn icon small @click="visible = false" color="secondary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-divider class="mx-4 pb-5"></v-divider>
      <v-card-actions>
        <div class="row">
          <div class="col-12 col-md-3">
            <v-select v-model="pageLength" :items="pageLengthOptions" hide-details :label="$t('AlarmHistoryDialog.PAGE_LENGTH_MESSAGE')" @change="pageNumber=1" item-value="value" item-text="text" attach></v-select>
          </div>
          <v-spacer></v-spacer>
          <div class="col-12 col-md-3 d-flex align-end">
            <v-text-field v-model="searchHint" :label="$t('AlarmHistoryDialog.SEARCH_MESSAGE')" hide-details append-icon="mdi-magnify"></v-text-field>
          </div>
        </div>
      </v-card-actions>
      <v-card-text class="pt-5">
        <div class="row">
        <div class="col-12">
          <v-data-table
            :headers="headers"
            :items="alarms"
            :loading="loading"
            :must-sort="true"
            :items-per-page="pageLength"
            :page="pageNumber"
            :search="searchHint"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            dense
            hide-default-footer
            class="alarms-history-table">
          <template v-slot:[`item.occuredAt`]="{ item }">
            <span v-if="item.occuredAt">{{ formatter.fromISO(item.occuredAt, { zone: 'utc' }).toFormat($store.state.settings.dateFormat) }}</span>
          </template>
          <template v-slot:[`item.clearedAt`]="{ item }">
            <span v-if="item.clearedAt">{{ formatter.fromISO(item.clearedAt, { zone: 'utc' }).toFormat($store.state.settings.dateFormat) }}</span>
          </template>
          </v-data-table>
        </div>
      </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <div class="col-12 text-center pt-2">
          <v-pagination v-model="pageNumber" circle :total-visible="5" :length="totalPages"></v-pagination>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DateTime } from 'luxon'
import { getAlarmHistory } from '@/api/backend.js'

export default {
  name: 'AlarmHistoryDialog',
  props: {
    isVisible: Boolean
  },
  data() {
    return {
      fullscreen: false,
      loading: false,
      pageNumber: 1,
      pageLength: 10,
      searchHint: '',
      sortDesc: true,
      sortBy: 'occuredAt',
      pageLengthOptions: [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ],
      formatter: DateTime,
      alarms: []
    }
  },
  computed: {
    visible: {
      get: function() {
        return this.isVisible
      },
      set: function(v) {
        this.$emit('closed')
      }
    },
    totalPages() {
      return Math.floor(this.alarms.length / this.pageLength)
    },
    headers() {
      return [
        { align: 'center', text: this.$t('AlarmHistoryDialog.HEADER.BUSINESS_GROUP'), value: 'businessGroupName', sortable: true, class: 'text-no-wrap' },
        { align: 'center', text: this.$t('AlarmHistoryDialog.HEADER.DEVICE'), value: 'deviceName', sortable: true, class: 'text-no-wrap' },
        { align: 'center', text: this.$t('AlarmHistoryDialog.HEADER.DEPARTMENT'), value: 'departmentName', sortable: true, class: 'text-no-wrap' },
        { align: 'center', text: this.$t('AlarmHistoryDialog.HEADER.CODE'), value: 'translation', sortable: true, class: 'text-no-wrap' }, // code or translation?
        { align: 'center', text: this.$t('AlarmHistoryDialog.HEADER.OCCURED_AT'), value: 'occuredAt', sortable: true, class: 'text-no-wrap' },
        { align: 'center', text: this.$t('AlarmHistoryDialog.HEADER.CLEARED_AT'), value: 'clearedAt', sortable: true, class: 'text-no-wrap' }
      ]
    }
  },
  methods: {
    loadData() {
      this.loading = true
      getAlarmHistory().then(r => {
        this.alarms = r.data
      }).catch(e => this.showErrorNotification(this.$t('AlarmHistoryDialog.LOADING_ERROR', { error: e })))
        .then(() => { this.loading = false })
    }
  },
  watch: {
    visible: function(newValue, oldValue) {
      if (newValue) {
        this.alarms = []
        this.loadData()
      }
    }
  }
}
</script>
