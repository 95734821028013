<template>
  <v-card elevation="2" :loading="loading">
    <v-form>
      <div class="container">
        <div class="row" v-if="step === 1">
          <span class="col-12 d-flex justify-center">{{ $t('OTPQrCode.TITLE') }}</span>
          <div class="col-12 d-flex justify-center">
            <img height="200" width="200" v-bind:src="img"/>
          </div>
          <div class="col-12 d-flex justify-space-around">
            <v-btn text color="primary" @click="step = 2">
              {{ $t('OTPQrCode.MANUAL_SETUP_QUESTION') }}
            </v-btn>
          </div>
        </div>
        <div class="row" v-else-if="step === 2">
          <div class="col-12 d-flex justify-center font-weight-black">{{ $t('OTPQrCode.MANUAL_SETUP_QUESTION') }}</div>
          <div class="col-12 d-flex justify-center">{{ $t('OTPQrCode.MANUAL_SETUP_INSTRUCTIONS') }}</div>
          <div class="col-12 d-flex justify-center">
            <v-text-field :value="setupKey.secret" readonly append-outer-icon="mdi-content-copy" @click:append-outer="copyToClipboard"></v-text-field>
          </div>
        </div>
        <div class="row" v-else-if="step === 3">
          <div class="col-12 d-flex justify-center font-weight-black">{{ $t('OTPQrCode.TRY_2FA_CODE') }}</div>
          <div class="col-12 d-flex justify-center">{{ $t('OTPQrCode.TRY_2FA_CODE_INSTRUCTIONS') }}</div>
          <div class="col-12 d-flex justify-center">
            <v-otp-input length="6" type="number" :disabled="loading" @finish="checkCode"></v-otp-input>
            <v-overlay absolute :value="loading">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-overlay>
          </div>
        </div>
        <div class="d-flex w-100 pt-4">
          <div class="pr-1 w-100">
            <v-btn outlined block color="accent" @click="cancel">{{$t("OTPQrCode.CANCEL_BUTTON")}}</v-btn>
          </div>
          <div class="pl-1 w-100">
            <v-btn outlined block color="primary" @click="proceed">{{$t("OTPQrCode.SUBMIT_BUTTON")}}</v-btn>
          </div>
        </div>
      </div>
    </v-form>
  </v-card>
</template>

<script>

import { verify2Fa } from '@/api/backend.js'
export default {
  name: 'OTPQrCode',
  props: {
    setupKey: Object
  },
  data() {
    return {
      loading: false,
      step: 1
    }
  },
  computed: {
    img() {
      return `data:image/png;base64,${this.setupKey.qrCode}`
    }
  },
  methods: {
    copyToClipboard() {
      try {
        navigator.clipboard.writeText(this.setupKey.secret)
        this.showSuccessNotification(this.$t('OTPQrCode.CLIPBOARD_COPY_SUCCESS'))
      } catch (e) {
        this.showErrorNotification(this.$t('OTPQrCode.CLIPBOARD_COPY_ERROR'))
      }
    },
    cancel() {
      this.step = 1
      this.$emit('cancel')
    },
    proceed() {
      if (this.step === 1 || this.step === 2) {
        this.step = 3
      } else {
        this.step = 1
      }
    },
    checkCode(code) {
      this.loading = true
      verify2Fa(this.setupKey.secret, code).then(r => {
        this.step = 1
        this.$emit('done', this.setupKey)
        this.showSuccessNotification(this.$t('OTPQrCode.SETUP_SUCCESS'))
      }).catch(e => {
        this.showErrorNotification(this.$t('OTPQrCode.INVALID_CODE'))
      }).then(() => { this.loading = false })
    }
  }
}
</script>
