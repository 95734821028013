<template>
  <v-component v-bind:is="component" :data="data" v-on="$listeners"></v-component>
</template>
<script>
export default {
  name: 'AlarmNode',
  props: {
    data: Object
  },
  computed: {
    component() {
      if (this.data.type === 'BUSINESS_GROUP') {
        return () => import('@/components/alarms/AlarmBusinessGroupNode')
      }
      return () => import('@/components/alarms/AlarmDeviceNode')
    }
  }
}
</script>
