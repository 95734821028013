<template>
  <div>
    <v-menu offset-y open-on-click :close-on-click="closeOnClick" :close-on-content-click="false" min-width="290">
      <template v-slot:activator="{ on, attrs }">
        <v-btn plain tile v-bind="attrs" v-on="on" color="secondary">
          <v-icon>mdi-account-circle-outline</v-icon>
          <span class="d-none d-sm-block">{{ $store.getters['security/username'] }}</span>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-group color="secondary" active-class="secondary--text">
          <template v-slot:prependIcon>
            <v-icon class="pr-4" color="secondary">mdi-view-dashboard-outline</v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-title class="secondary--text justify-start v-btn" style="font-size: 0.875rem;">{{$t('AccountMenu.DASHBOARD_MENU_TITLE')}}</v-list-item-title>
          </template>
          <template v-slot:appendIcon>
            <v-icon color="secondary">mdi-chevron-down</v-icon>
          </template>
          <v-list-item @click="createDashboard">
            <v-btn plain tile :ripple="false" color="secondary" class="w-100 justify-start">
              <v-icon class="pr-2">mdi-card-plus-outline</v-icon>
              {{$t('AccountMenu.CREATE_DASHBOARD')}}
            </v-btn>
          </v-list-item>
          <v-list-item @click="exportDashboard">
            <v-btn plain tile :ripple="false" color="secondary" class="w-100 justify-start">
              <v-icon class="pr-2">mdi-download</v-icon>
              {{$t('AccountMenu.EXPORT_DASHBOARD')}}
            </v-btn>
          </v-list-item>
          <v-list-item link>
            <DashboardImportDialog @input="toggleCloseOnClick">
              <template v-slot:activator="{ on, attrs }">
                <v-btn plain tile :ripple="false" color="secondary" class="w-100 justify-start" v-bind="attrs" v-on="on" @click="toggleCloseOnClick">
                  <v-icon class="pr-2">mdi-upload</v-icon>
                  {{$t('AccountMenu.IMPORT_DASHBOARD')}}
                </v-btn>
              </template>
            </DashboardImportDialog>
          </v-list-item>
          <v-list-item link>
            <DashboardSortingDialog @input="toggleCloseOnClick">
              <template v-slot:activator="{ on, attrs }">
                <v-btn plain tile :ripple="false" color="secondary" class="w-100 justify-start" v-bind="attrs" v-on="on" @click="toggleCloseOnClick">
                  <v-icon class="pr-2">mdi-sort</v-icon>
                  {{$t('AccountMenu.SORT_DASHBOARD')}}
                </v-btn>
              </template>
            </DashboardSortingDialog>
          </v-list-item>
        </v-list-group>
        <v-list-item dense class="px-0" @click="showAccountSettingsDialog = !showAccountSettingsDialog">
          <v-btn plain tile :ripple="false" color="secondary" class="w-100 justify-start">
            <v-icon class="pr-2">mdi-account-cog-outline</v-icon>
            {{$t('AccountMenu.PERSONAL_SETTINGS')}}
          </v-btn>
        </v-list-item>
        <v-list-item dense class="px-0" @click="showDeviceConfigurationDialog=true">
          <v-btn plain tile :ripple="false" color="secondary" class="w-100 justify-start">
            <v-icon class="pr-2">mdi-file-cog-outline</v-icon>
            {{$t('AccountMenu.DEVICE_CONFIGURATION')}}
          </v-btn>
        </v-list-item>
        <v-list-item dense class="px-0" @click="showThemeSettingsDialog=true">
          <v-btn plain tile :ripple="false" color="secondary" class="w-100 justify-start">
            <v-icon class="pr-2">mdi-palette-outline</v-icon>
            {{$t('AccountMenu.THEME_CONFIGURATION')}}
          </v-btn>
        </v-list-item>
        <v-list-group color="secondary" active-class="secondary--text">
          <template v-slot:prependIcon>
            <v-icon class="pr-4" color="secondary">mdi-help-circle-outline</v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-title class="secondary--text justify-start v-btn" style="font-size: 0.875rem;">{{$t('AccountMenu.HELP_MENU_TITLE')}}</v-list-item-title>
          </template>
          <template v-slot:appendIcon>
            <v-icon color="secondary">mdi-chevron-down</v-icon>
          </template>
          <v-list-item :href="documentationUrl" target="_blank">
            <v-btn plain tile :ripple="false" color="secondary" class="w-100 justify-start">
              <v-icon class="pr-2">mdi-file-document-outline</v-icon>
              {{$t('AccountMenu.OPEN_DOCUMENTATION')}}
            </v-btn>
          </v-list-item>
          <v-list-item link>
            <FeatureRequestForm @input="toggleCloseOnClick">
              <template v-slot:activator="{ on, attrs }">
                <v-btn plain tile :ripple="false" color="secondary" class="w-100 justify-start" v-bind="attrs" v-on="on" @click="toggleCloseOnClick">
                  <v-icon class="pr-2">mdi-new-box</v-icon>
                  {{$t('AccountMenu.REQUEST_FEATURE')}}
                </v-btn>
              </template>
            </FeatureRequestForm>
          </v-list-item>
          <v-list-item link>
            <BugReportForm @input="toggleCloseOnClick">
              <template v-slot:activator="{ on, attrs }">
                <v-btn plain tile :ripple="false" color="secondary" class="w-100 justify-start" v-bind="attrs" v-on="on" @click="toggleCloseOnClick">
                  <v-icon class="pr-2">mdi-shield-bug-outline</v-icon>
                  {{$t('AccountMenu.REPORT_BUG')}}
                </v-btn>
              </template>
            </BugReportForm>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <v-list-group color="secondary" active-class="secondary--text" v-if="$GRANTS().SYSTEM.WRITE() || isLoggedInAs">
          <template v-slot:prependIcon>
            <v-icon class="pr-4" color="secondary">mdi-security</v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-title class="secondary--text justify-start v-btn" style="font-size: 0.875rem;">{{$t('AccountMenu.ADMIN_MENU_TITLE')}}</v-list-item-title>
          </template>
          <template v-slot:appendIcon>
            <v-icon color="secondary">mdi-chevron-down</v-icon>
          </template>
          <v-list-item @click="switchBackAccount" v-if="isLoggedInAs">
            <v-btn plain tile :ripple="false" color="secondary" class="w-100 justify-start">
              <v-icon class="pr-2">mdi-account-sync-outline</v-icon>
              {{$t('AccountMenu.SWITCH_BACK_ACCOUNT')}}
            </v-btn>
          </v-list-item>
          <v-list-item link v-if="$GRANTS().SYSTEM.WRITE()">
            <SwitchAccountForm @input="toggleCloseOnClick">
              <template v-slot:activator="{ on, attrs }">
                <v-btn plain tile :ripple="false" color="secondary" class="w-100 justify-start" v-bind="attrs" v-on="on" @click="toggleCloseOnClick">
                  <v-icon class="pr-2">mdi-account-switch-outline</v-icon>
                  {{$t('AccountMenu.SWITCH_USER')}}
                </v-btn>
              </template>
            </SwitchAccountForm>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <v-list-item dense @click="logout" class="px-0">
          <v-btn plain tile :ripple="false" color="secondary" class="w-100 justify-start">
            <v-icon>mdi-logout</v-icon>
            {{$t('AccountMenu.LOGOUT')}}
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
    <ThemeSettingsDialog :isVisible="showThemeSettingsDialog" @closed="showThemeSettingsDialog = !showThemeSettingsDialog"></ThemeSettingsDialog>
    <AccountSettingsDialog :isVisible="showAccountSettingsDialog" @closed="showAccountSettingsDialog = !showAccountSettingsDialog"></AccountSettingsDialog>
    <DeviceConfigurationDialog :isVisible="showDeviceConfigurationDialog" @closed="showDeviceConfigurationDialog = !showDeviceConfigurationDialog"></DeviceConfigurationDialog>
  </div>
</template>
<script>
import { CREATE_DASHBOARD, TOGGLE_DASHBOARD_EDIT, LOGOUT, SWITCH_ACCOUNT, OPEN_WIDGET_DETAILS_DIALOG } from '@/store/mutations'
import { findDashboardById, switchBackAccount } from '@/api/backend.js'
import SwitchAccountForm from '@/components/SwitchAccountForm.vue'
import BugReportForm from '@/components/BugReportForm.vue'
import FeatureRequestForm from '@/components/FeatureRequestForm.vue'
import AccountSettingsDialog from '@/components/AccountSettingsDialog.vue'
import ThemeSettingsDialog from '@/components/ThemeSettingsDialog.vue'
import DeviceConfigurationDialog from '@/components/device-configuration/DeviceConfigurationDialog.vue'
import DashboardImportDialog from '@/components/dashboards/import/DashboardImportDialog.vue'
import DashboardSortingDialog from '@/components/dashboards/sorting/DashboardSortingDialog.vue'
export default {
  name: 'AccountMenu',
  components: {
    AccountSettingsDialog,
    DeviceConfigurationDialog,
    DashboardImportDialog,
    DashboardSortingDialog,
    ThemeSettingsDialog,
    FeatureRequestForm,
    BugReportForm,
    SwitchAccountForm
  },
  data() {
    return {
      showThemeSettingsDialog: false,
      showAccountSettingsDialog: false,
      showDeviceConfigurationDialog: false,
      closeOnClick: true,
      documentationUrl: process.env.VUE_APP_DOCUMENTATION_URL
    }
  },
  computed: {
    isLoggedInAs() {
      return this.$store.getters['security/isLoggedInAs']
    }
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT)
    },
    switchBackAccount() {
      switchBackAccount().then(r => {
        this.$store.dispatch(`security/${SWITCH_ACCOUNT}`, r.data).then(() => {
          window.location = window.origin
        }).catch((e) => this.showErrorNotification(this.$t('SwitchAccountForm.ERROR_MESSAGE')))
          .then((r) => { this.loading = false })
      })
    },
    createDashboard() {
      this.showTextPromptDialog(this.$t('AccountMenu.DASHBOARD_SAVE_DIALOG_TITLE'), 'Title', 'required', '')
        .then(r => this.$store.dispatch(`dashboards/${CREATE_DASHBOARD}`, r))
        .then(r => {
          this.showSuccessNotification(this.$t('AccountMenu.DASHBOARD_SAVE_SUCCESS'))
          this.$router.push({
            name: 'dashboard',
            params: {
              id: r.data.id
            }
          }).catch(e => console.log(e))
            .then(() => this.$store.commit(`dashboards/${TOGGLE_DASHBOARD_EDIT}`, true))
            .then(() => this.$store.commit(`dashboards/${OPEN_WIDGET_DETAILS_DIALOG}`, true))
        })
        .catch(e => this.showErrorNotification(this.$t('AccountMenu.DASHBOARD_SAVE_ERROR', [e])))
    },
    exportDashboard() {
      const dashboards = []
      this.$store.state.dashboards.dashboardsAndGroups.forEach(element => {
        if (element.dashboards) {
          element.dashboards.forEach(dashboard => {
            dashboards.push(dashboard)
          })
        } else {
          dashboards.push(element)
        }
      })
      this.showSelectPromptDialog(this.$t('AccountMenu.DASHBOARD_EXPORT_DIALOG_TITLE'), 'Dashboard', 'required', dashboards, 'name').then(selectedDashboard => {
        return findDashboardById(selectedDashboard.id)
      }).then(response => {
        const selectedDashboard = response.data
        const dashboard = JSON.stringify(selectedDashboard)
        const blob = new Blob([dashboard], { type: 'application/json' })
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, selectedDashboard.name)
        } else {
          const elem = window.document.createElement('a')
          elem.href = window.URL.createObjectURL(blob)
          elem.download = selectedDashboard.name
          document.body.appendChild(elem)
          elem.click()
          document.body.removeChild(elem)
          URL.revokeObjectURL(elem.href)
        }
      })
    },
    toggleCloseOnClick(value) {
      this.closeOnClick = !value
    }
  }
}
</script>
<style scoped>
.checkbox-text:not(:hover) {
  opacity: 0.62;
}
* >>>.v-list-group__header__prepend-icon {
  margin-right: 8px !important;
}
* >>>.v-list-group__header__append-icon {
  margin-left: 0px !important;
  min-width: 24px !important;
}
* >>>.v-list-item__icon {
  margin-right: 0px !important;
  min-width: 24px !important;
}
* >>>.v-list-group__items {
  padding-left: 8px;
}
* >>>.v-list-group__header:not(:hover):not(.v-list-item--active) {
  opacity: 0.62;
}
</style>
