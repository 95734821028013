<template>
  <div :style="`width: ${width}px;`" class="dashboard-header expand-transition-enter-active">
    <div class="row justify-center">
      <div class="col-12 py-0">
        <v-expand-transition>
          <v-card v-show="editMode">
            <v-card-text>
            <div class="row">
              <div class="col-4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined block color="primary" v-bind="attrs" v-on="on" @click="renameDashboard">
                      <v-icon>mdi-view-dashboard-edit-outline</v-icon>
                      <span class="d-none d-md-block pl-md-2">{{$t('DashboardHeader.EDIT_DASHBOARD_MESSAGE')}}</span>
                    </v-btn>
                  </template>
                  <span>{{$t('DashboardHeader.EDIT_DASHBOARD_MESSAGE')}}</span>
                </v-tooltip>
              </div>
              <div class="col-4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined block color="primary" v-bind="attrs" v-on="on" @click="addWidget">
                      <v-icon>mdi-chart-box-plus-outline</v-icon>
                      <span class="d-none d-md-block pl-md-2">{{$t('DashboardHeader.ADD_WIDGET_MESSAGE')}}</span>
                    </v-btn>
                  </template>
                  <span>{{$t('DashboardHeader.ADD_WIDGET_MESSAGE')}}</span>
                </v-tooltip>
              </div>
              <div class="col-4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined block color="accent" v-bind="attrs" v-on="on" @click="deleteDashboard">
                      <v-icon>mdi-delete-forever-outline</v-icon>
                      <span class="d-none d-md-block pl-md-2">{{$t('DashboardHeader.DELETE_DASHBOARD_MESSAGE')}}</span>
                    </v-btn>
                  </template>
                  <span>{{$t('DashboardHeader.DELETE_DASHBOARD_MESSAGE')}}</span>
                </v-tooltip>
              </div>
            </div>
            </v-card-text>
          </v-card>
        </v-expand-transition>
      </div>
      <div class="col-12 d-flex justify-center pt-0">
        <v-btn v-if="editMode" color="primary" :class="classes" @click="saveDashboard">
            {{$t('DashboardHeader.SAVE_DASHBOARD_MESSAGE')}}
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
        <v-btn v-if="!editMode" color="primary" :class="classes" @click="editDashboard">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { DELETE_DASHBOARD, RENAME_DASHBOARD } from '@/store/mutations'
import elementResizeDetectorMaker from 'element-resize-detector'
export default {
  name: 'DashboardHeader',
  props: {
    editMode: Boolean,
    dashboard: Object
  },
  computed: {
    classes() {
      return this.$vuetify.theme.dark ? 'black--text no-radius-top-right no-radius-top-left' : 'no-radius-top-right no-radius-top-left'
    }
  },
  data() {
    return {
      width: 100,
      elementResizeDetector: elementResizeDetectorMaker({ strategy: 'scroll' })
    }
  },
  mounted() {
    this.elementResizeDetector.listenTo(this.$parent.$el, this.updateWidth)
  },
  beforeUnmount() {
    this.elementResizeDetector.removeAllListeners(this.$parent.$el)
  },
  methods: {
    updateWidth(element) {
      this.width = element.offsetWidth
    },
    deleteDashboard() {
      this.showConfirmationDialog(this.$t('DashboardHeader.DELETE_DASHBOARD_CONFIRMATION_MESSAGE', [this.dashboard.name]))
        .then(() => {
          this.$emit('toggleDashboardEdit', false)
          this.$store.dispatch(`dashboards/${DELETE_DASHBOARD}`, this.dashboard.id)
            .then(() => {
              this.showSuccessNotification(this.$t('DashboardHeader.DASHBOARD_DELETED_MESSAGE'))
              this.$router.push({ name: 'home' })
            })
            .catch(e => this.showErrorNotification(this.$t('DashboardHeader.DASHBOARD_DELETED_ERROR_MESSAGE', [e])))
        })
    },
    editDashboard() {
      this.$emit('toggleDashboardEdit', true)
    },
    saveDashboard() {
      this.$emit('toggleDashboardEdit', false)
    },
    renameDashboard() {
      this.showTextPromptDialog(this.$t('DashboardHeader.RENAME_DASHBOARD_MESSAGE'), 'Name', 'required', this.dashboard.name)
        .then(r => this.$store.dispatch(`dashboards/${RENAME_DASHBOARD}`, { name: r, id: this.dashboard.id }))
        .then(() => this.showSuccessNotification(this.$t('DashboardHeader.DASHBOARD_RENAMED_MESSAGE')))
        .catch(e => this.showErrorNotification(this.$t('DashboardHeader.DASHBOARD_RENAME_ERROR_MESSAGE', [e])))
    },
    addWidget() {
      this.$emit('addWidget')
    }
  }
}
</script>
<style scoped lang="scss">
.position-fixed {
  position: fixed !important;
  z-index: 1;
}
</style>
