<template>
  <v-dialog v-model="visible" width="400">
    <v-card>
      <v-card-title class="justify-space-between">
        <div>{{ $t('NotificationsBlockedDialog.TITLE') }}</div>
      <div>
        <v-btn icon small @click="visible = false" color="secondary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-card-title>
      <v-card-text>
        {{ $t('NotificationsBlockedDialog.MESSAGE') }}
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'NotificationsBlockedDialog',
  props: {
    isVisible: Boolean
  },
  computed: {
    visible: {
      get: function() {
        return this.isVisible
      },
      set: function(v) {
        this.$emit('closed')
      }
    }
  }
}
</script>
