<template>
  <v-snackbar v-model="visible" color="primary" outlined timeout="-1">
   <div>
      <b>Getting your file</b>
    </div>
    <v-progress-linear color="primary" indeterminate rounded height="6"/>
  </v-snackbar>
</template>
<script>
export default {
  name: 'DownloadNotification',
  props: {
    type: {
      type: String,
      default: 'download'
    }
  },
  data: () => ({
    isVisible: true
  }),
  computed: {
    visible: {
      get: function() {
        return this.isVisible
      },
      set: function() {
        this.isVisible = false
      }
    }
  }

}
</script>
