import {
  cloneLayout,
  sortLayoutItemsByRowCol,
  collides,
  compact
} from './utils'

function moveItemsDown(layout, item, i) {
  let collideIndex = null
  for (let j = i + 1; j < layout.length; j++) {
    if (collides(layout[j], item)) {
      collideIndex = j
      break
    }
  }
  if (collideIndex) {
    layout[collideIndex].y = item.y + item.h
    moveItemsDown(layout, layout[collideIndex], collideIndex)
  }
}
function applyRowAndSort(layout) {
  layout = layout.sort((a, b) => a.y - b.y)
  let yDifference = 0
  let currentRow = 0
  for (let i = 0; i < layout.length; i++) {
    const item = layout[i]
    if (item.y === currentRow) {
      yDifference = Math.max(yDifference, item.y + item.h)
    } else if (item.y > currentRow) { // we're not on the same row anymore
      currentRow = yDifference
      item.y = yDifference
      yDifference = item.y + item.h
      moveItemsDown(layout, item, i)
    } else { // same row but offset is negative, item y it's above current row -_
      item.y = currentRow
      yDifference = Math.max(yDifference, item.y + item.h)
      moveItemsDown(layout, item, i)
    }
  }
  return sortLayoutItemsByRowCol(layout)
}

export function translateLayout(currentLayout, breakpoint, lastBreakpoint, threshholds) {
  const currentColumnsNumber = threshholds[breakpoint]
  const lastColumnsNumber = threshholds[lastBreakpoint]
  console.time('translateLayout')
  let layout = cloneLayout(currentLayout)
  console.time('applyRowAndSort')
  layout = applyRowAndSort(layout)
  console.timeEnd('applyRowAndSort')

  if (currentColumnsNumber > lastColumnsNumber) { // upscale
    let height = null
    let currentRow = null
    let currentColumn = null
    let columnSpace = 0
    let previous = null
    for (let i = 0; i < layout.length; i++) {
      const item = layout[i]
      const y = item.y
      if (previous !== null) {
        if (previous.y === item.y) { // they were on the same row
          columnSpace = item.x - (previous.x + previous.w)
        } else {
          columnSpace = item.x
        }
        previous = { ...item }
      } else {
        previous = { ...item }
      }
      if (height === null) {
        height = item.h
        currentRow = item.y
        currentColumn = item.x + item.w
        continue
      }
      if (y !== currentRow) { // new row, check if we have space on the previous row at the end
        if (item.dw) { // if the width was changed
          item.w += item.dw
        }
        let addition = item.x + currentColumn + columnSpace
        if (item.x < currentColumn) {
          addition = currentColumn + columnSpace
        }
        if (item.dx) { // if the x axis was changed
          addition += item.dx
        }
        if (addition + item.w <= currentColumnsNumber) {
          item.x = addition
          currentColumn = addition + item.w
        } else {
          if (item.dx) {
            item.x += item.dx
          }
          currentRow = y
          currentColumn = item.x + item.w
        }
      } else {
        currentColumn = item.x + item.w
      }
    }
  } else if (currentColumnsNumber < lastColumnsNumber) {
    // downscale
    /**
      * iterate over items
      * once an item it's outside bounds, move down all the items by the highest height of the items in bounds
      * compute the dx and move all the items that are out of bound to the left
      */
    let currentY = 0
    let maxY = 0
    let maxX = 0
    for (let i = 0; i < layout.length; i++) {
      const item = layout[i]
      if (item.w > currentColumnsNumber) {
        item.w = currentColumnsNumber
      }
      if (maxX + item.w <= currentColumnsNumber) {
        item.x = maxX
        item.y = currentY
        if (maxY <= item.y + item.h) {
          maxY = item.y + item.h
        }
        if (maxX <= item.x + item.w) {
          maxX = item.x + item.w
        }
      } else {
        item.x = 0
        item.y = maxY
        currentY = item.y
        maxX = item.x + item.w
        if (maxY <= item.y + item.h) {
          maxY = item.y + item.h
        }
      }
    }
  }
  console.timeEnd('translateLayout')
  return compact(layout)
}
