<template>
  <div class="fill-height">
    <div class="loader" v-if="loading">
      <div class="background"></div>
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
    <div v-if="dashboard !== null" :class="wrapperClass">
      <DashboardHeader :editMode="editMode" :dashboard="dashboard" @toggleDashboardEdit="toggleDashboardEdit" @addWidget="widgetDetailsDialog = true" ref="header"/>
      <DashboardGrid :editMode="editMode" :dashboard="dashboard">
        <template v-slot:widget="scope">
          <Widget
            :widget="scope.widget"
            :editMode="editMode"
            :eventStream="eventStream"
            @toggleFullscreen="scope.toggleFullscreen(scope.position, $event)"
            @edit="editWidget"
            @editMarkAreaElements="toggleWidgetMarkAreaElementsDialog"
            @delete="deleteWidget"
            @update="updateWidget"/>
        </template>
      </DashboardGrid>
    </div>
    <WidgetDetailsDialog :isVisible="widgetDetailsDialog" :widget="selectedWidget" @closed="closeWidgetDetailsDialog" @save="updateWidget"/>
    <WidgetMarkAreaElementsDialog :isVisible="widgetMarkAreaElementsDialog" :widget="selectedWidget" @closed="toggleWidgetMarkAreaElementsDialog(null)" @saved="updateWidgetMarkAreaElements"/>
  </div>
</template>
<script>
import { Subject } from 'rxjs'
import DashboardGrid from '@/components/dashboards/grid/DashboardGrid.vue'
import { saveDashboard } from '@/api/backend'
import equal from 'fast-deep-equal'
import DashboardHeader from '@/components/dashboards/DashboardHeader.vue'
import Widget from '@/components/dashboards/widgets/Widget.vue'
import WidgetDetailsDialog from '@/components/dashboards/widgets/dialog2/WidgetDetailsDialog.vue'
import WidgetMarkAreaElementsDialog from '@/components/dashboards/widgets/dialog/WidgetMarkAreaElementsDialog.vue'
import { UPDATE_WIDGET_MARK_AREA_ELEMENTS, OPEN_WIDGET_DETAILS_DIALOG, UPDATE_DASHBOARD, TOGGLE_DASHBOARD_EDIT, RENAME_DASHBOARD, UPDATE_FAVORITE_DASHBOARD, REMOVE_LINES, SORT_DASHBOARDS_AND_GROUPS, UPDATE_ACCOUNT_SETTINGS } from '@/store/mutations'
export default {
  name: 'Dashboard',
  components: {
    DashboardHeader,
    Widget,
    DashboardGrid,
    WidgetDetailsDialog,
    WidgetMarkAreaElementsDialog
  },
  data: () => {
    return {
      loading: false,
      dashboard: null,
      unsubscribe: null,
      widgetDetailsDialog: false,
      widgetMarkAreaElementsDialog: false,
      selectedWidget: null,
      eventStream: new Subject({
        type: null,
        payload: null
      }),
      subscriptions: {
        unsubscribeFromStore: null
      }
    }
  },
  computed: {
    editMode: {
      get() {
        return this.$store.state.dashboards.editMode
      },
      set(value) {
        this.$store.dispatch(`dashboards/${TOGGLE_DASHBOARD_EDIT}`)
      }
    },
    wrapperClass() {
      if (this.editMode) {
        return 'dashboard-edit-on'
      }
      return 'dashboard-edit-off'
    },
    selectedDashboard() {
      return this.$store.state.dashboards.selectedDashboard
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.editMode) {
      this.editMode = false
      if (this.hasChanges()) {
        return this.showConfirmationDialog(this.$t('Dashboard.SAVE_CHANGES_MESSAGE'), true)
          .then(r => this.saveDashboard(this.dashboard))
          .then(() => next())
          .catch(e => next())
      }
    }
    return next()
  },
  created() {
    this.subscriptions.unsubscribeFromStore = this.$store.subscribe((mutation, state) => {
      if (mutation.type === `dashboards/${OPEN_WIDGET_DETAILS_DIALOG}`) {
        this.widgetDetailsDialog = true
      } else if (mutation.type === `dashboards/${RENAME_DASHBOARD}`) {
        this.dashboard.name = mutation.payload.name
      } else if (mutation.type === `dashboards/${UPDATE_FAVORITE_DASHBOARD}` && this.dashboard) {
        this.dashboard.favorite = (mutation.payload.id === this.dashboard.id) // if the current dashboard it's edited and it's favorite flag it's updated sync the prop
      } else if (mutation.type === `dashboards/${SORT_DASHBOARDS_AND_GROUPS}` && this.dashboard) { // if the order it's updated while in edit mode keep the same prop value
        this.dashboard.order = this.$store.state.dashboards.selectedDashboard.order
        this.dashboard.groupId = this.$store.state.dashboards.selectedDashboard.groupId
      } else if (mutation.type === UPDATE_ACCOUNT_SETTINGS && this.dashboard) {
        const d = this.clone(this.dashboard)
        d.widgets.forEach(w => {
          if (w.type === 'LINE_CHART' || w.type === 'MULTIGRID_CHART' || w.type === 'MULTIPLE_Y_CHART') {
            w.elements.forEach(e => {
              if (e.buffer !== null && e.useDefaultPcNumber) {
                e.buffer.pcNumber = this.$store.state.settings.defaultPcNumber
                e.pcNumber = null
              }
            })
          }
        })
        this.dashboard = d
      }
    })
    this.$watch(
      () => this.$store.state.dashboards.selectedDashboard,
      (newValue, oldValue) => {
        if (this.editMode) {
          this.editMode = false
          if (this.hasChanges(oldValue)) {
            this.showConfirmationDialog(this.$t('Dashboard.SAVE_CHANGES_MESSAGE'), true).then(r => {
              this.saveDashboard(this.dashboard)
            }).catch(e => { /* Save cancelled */ })
              .then(() => {
                if (!this.$store.state.dashboards.selectedDashboard) {
                  return this.$router.push({ name: '404' })
                }
                if (this.$store.state.dashboards.selectedDashboard) {
                  this.cloneDashboard()
                }
              })
            return
          }
        }
        if (!newValue) {
          return
        }
        if (!this.$store.state.dashboards.selectedDashboard) {
          return this.$router.push({ name: '404' })
        }
        if (this.$store.state.dashboards.selectedDashboard) {
          this.cloneDashboard()
        }
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  },
  beforeDestroy() {
    this.subscriptions.unsubscribeFromStore()
  },
  methods: {
    toggleWidgetMarkAreaElementsDialog(widget) {
      this.selectedWidget = widget
      this.widgetMarkAreaElementsDialog = (widget !== null)
    },
    updateWidgetMarkAreaElements(elements) {
      this.selectedWidget.markAreaElements = elements
      this.$store.dispatch(`dashboards/${UPDATE_WIDGET_MARK_AREA_ELEMENTS}`, { widget: this.selectedWidget.id, elements: elements })
      this.toggleWidgetMarkAreaElementsDialog(null)
    },
    updateWidget(widget) {
      const index = this.dashboard.widgets.findIndex(w => w.id === widget.id)
      if (index >= 0) {
        this.$set(this.dashboard.widgets, index, widget)
      } else {
        this.dashboard.widgets.push(widget)
      }
      this.closeWidgetDetailsDialog()
    },
    deleteWidget(widget) {
      this.showConfirmationDialog(this.$t('Dashboard.DELETE_WIDGET_MESSAGE', [widget.name])).then(r => {
        const index = this.dashboard.widgets.findIndex(w => w.id === widget.id)
        this.lastPosition = this.dashboard.widgets[index].position
        this.dashboard.widgets.splice(index, 1)
      })
    },
    editWidget(widget) {
      this.selectedWidget = widget
      this.widgetDetailsDialog = true
    },
    closeWidgetDetailsDialog() {
      this.selectedWidget = null
      this.widgetDetailsDialog = false
    },
    toggleDashboardEdit(editMode) {
      this.editMode = editMode
      if (this.hasChanges()) {
        this.showConfirmationDialog(this.$t('Dashboard.SAVE_CHANGES_MESSAGE'), true).then(r => {
          this.saveDashboard(this.dashboard)
        }).catch(() => {
          this.cloneDashboard()
        })
      }
    },
    saveDashboard(dashboard) {
      this.loading = true
      return saveDashboard(dashboard).then(r => {
        this.$store.dispatch(`dashboards/${UPDATE_DASHBOARD}`, this.clone(r.data))
        this.$store.dispatch(`devices/${REMOVE_LINES}`, r.data)
        this.showSuccessNotification(this.$t('Dashboard.DASHBOARD_SAVED_MESSAGE', [r.data.name]))
        this.loading = false
      }).catch(e => {
        this.showErrorNotification(this.$t('Dashboard.SAVE_DASHBOARD_ERROR_MESSAGE'))
      }).then(() => {
        this.loading = false
      })
    },
    cloneDashboard() {
      this.eventStream = new Subject({
        type: null,
        payload: null
      })
      this.dashboard = this.clone(this.$store.state.dashboards.selectedDashboard)
    },
    hasChanges(oldDashboard) {
      let toCompareWith = this.selectedDashboard
      if (oldDashboard) {
        toCompareWith = oldDashboard
      }
      if (this.dashboard) {
        return !equal(this.dashboard, toCompareWith)
      }
      return false
    }
  }
}
</script>
<style lang="scss">
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  .background {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.5;
    background-color: var(--v-secondary-base) !important;
    border-color: var(--v-secondary-base) !important;
  }
}
</style>
