<template>
  <ValidationProvider rules="required" :name="$t('DashboardImportDepartmentReplacementSelect.REPLACEMENT_DEVICE_FOR', [departmentNumber, subDepartmentNumber])" v-slot="{ errors, valid }" slim :skipIfEmpty="false">
    <v-select
      :items="availableDepartmens"
      label="Replace with"
      v-model="department"
      clearable
      return-object
      :item-text="computeDepartmentLabel"
      item-value="key"
      :error-messages="errors"
      :success="valid"
      :disabled="disabled"
      dense
      @change="updateElements">
      <template v-slot:prepend>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon :color="statusConstant[status].color" v-bind="attrs" v-on="on">{{statusConstant[status].icon}}</v-icon>
          </template>
          <span>{{statusConstant[status].message}}</span>
        </v-tooltip>
      </template>
      <template v-slot:prepend-item>
        <v-list-item>
          <v-list-item-content>
            <v-text-field label="Search" v-model="searchHint" hide-details dense></v-text-field>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
      <template v-slot:item="{item}">
        <span class="pr-1" v-if="$store.state.settings.showDepartmentNumbers">{{item.key}}</span>
        {{computeDepartmentLabel(item)}}
      </template>
      <template v-slot:selection="{item}">
        <span class="text-truncate">
          <span class="pr-1" v-if="$store.state.settings.showDepartmentNumbers">{{item.key}}</span>
          {{computeDepartmentLabel(item)}}
        </span>
      </template>
    </v-select>
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from 'vee-validate'
export default {
  name: 'DashboardImportDepartmentReplacementSelect',
  components: {
    ValidationProvider
  },
  props: {
    device: Object,
    departmentNumber: Number,
    subDepartmentNumber: Number,
    widgetElements: Array
  },
  computed: {
    disabled() {
      return this.device === null
    },
    status() {
      return this.device !== null && this.department !== null ? 'OK' : 'FAIL'
    },
    availableDepartmens() {
      const hint = this.searchHint.toLowerCase()
      if (this.device !== null) {
        return this.device.departments.filter(d => d.name.toLowerCase().includes(hint) || d.hexacode.toLowerCase().includes(hint))
      }
      return []
    },
    correctedDepartmentNumber() {
      if (this.device.networkNumber != null) { // if we have a device inside a BG
        return this.device.networkNumber
      } else { // just reset for other cases
        return 0
      }
    },
    statusConstant() {
      return {
        OK: {
          color: 'success',
          message: this.$t('DashboardImportDepartmentReplacementSelect.DEPARTMENT_MATCHED'),
          icon: 'mdi-check'
        },
        FAIL: {
          color: 'warning',
          message: this.$t('DashboardImportDepartmentReplacementSelect.DEPARTMENT_NOT_AVAILABLE'),
          icon: 'mdi-alert-outline'
        }
      }
    }
  },
  data() {
    return {
      searchHint: '',
      department: null
    }
  },
  created() {
    this.department = this.findDepartment()
  },
  methods: {
    findDepartment() {
      if (this.device !== null) {
        const department = this.device.departments.find(d => d.department === this.correctedDepartmentNumber && d.subDepartment === this.subDepartmentNumber)
        if (department !== undefined) {
          return department
        }
        if (this.device.type === 'BUSINESS_GROUP') {
          const device = this.device.devices.find(d => d.networkNumber === this.correctedDepartmentNumber)
          if (device !== undefined) {
            const department = device.departments.find(d => d.department === this.correctedDepartmentNumber && d.subDepartment === this.subDepartmentNumber)
            if (department !== undefined) {
              return department
            }
          }
        }
      }
      return null
    },
    computeDepartmentLabel(department) {
      if (department.configuration.label !== null) {
        return department.configuration.label
      }
      return department.name
    },
    updateElements(department) {
      if (department === null) {
        this.widgetElements.forEach(e => {
          e.department = null
          e.subDepartment = null
        })
      } else {
        this.widgetElements.forEach(e => {
          e.department = department.department
          e.subDepartment = department.subDepartment
        })
      }
    }
  },
  watch: {
    device(newValue, oldValue) {
      this.department = this.findDepartment()
    }
  }
}
</script>
