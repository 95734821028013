<template>
  <v-component v-bind:is="component" :data="source"></v-component>
</template>
<script>
export default {
  name: 'SidebarNode',
  props: {
    source: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    component() {
      if (this.source.type === 'REAL_DEVICE') {
        return () => import('@/components/sidebar/SidebarDeviceNode')
      }
      if (this.source.type === 'VIRTUAL_DEVICE') {
        return () => import('@/components/sidebar/SidebarDeviceNode')
      }
      if (this.source.type === 'BUSINESS_GROUP') {
        return () => import('@/components/sidebar/SidebarBusinessGroupNode')
      }
      return () => import('@/components/sidebar/SidebarDashboardElementsNode')
    }
  }
}
</script>
