<template>
  <v-menu v-model="isOpen" offset-y :close-on-content-click="false" :close-on-click="true" :disabled="disabled">
    <template v-slot:activator="{ on }">
      <v-btn fab x-small :style="defaultStyle" v-on="on">
        <div :style="computedStyle"></div>
      </v-btn>
    </template>
    <v-card>
      <v-card-text class="pa-0">
        <v-color-picker v-model="colorData" flat show-swatches swatches-max-height="100px" mode="hexa" :disabled="disabled"/>
      </v-card-text>
      <v-divider class="mx-4 mt-2"></v-divider>
      <v-card-actions class="d-flex flex-column w-100">
        <div class="d-flex w-100">
          <div class="pr-1 w-100">
            <v-btn outlined block color="accent" @click="closePicker">{{$t('ColorPickerMenu.CANCEL_MESSAGE')}}</v-btn>
          </div>
          <div class="pl-1 w-100">
            <v-btn outlined block color="primary" @click="updateColor">{{$t("ColorPickerMenu.SAVE_MESSAGE")}}</v-btn>
          </div>
        </div>
        <div class="w-100 pt-2">
          <v-btn outlined block color="secondary" @click="clearColor">{{$t("ColorPickerMenu.CLEAR_MESSAGE")}}</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  name: 'ColorPickerMenu',
  model: {
    prop: 'color',
    event: 'input'
  },
  props: {
    color: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      colorData: null,
      defaultStyle: {
        'background-image': 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==)',
        'background-repeat': 'repeat',
        'background-color': 'transparent'
      }
    }
  },
  computed: {
    hex() {
      if (this.colorData !== null) {
        return this.colorData
      }
      return null
    },
    computedStyle() {
      return {
        height: '32px',
        width: '32px',
        'border-radius': '50%',
        'background-color': this.color
      }
    }
  },
  created() {
    if (this.color === null) {
      this.colorData = ''
    } else {
      this.colorData = this.color
    }
  },
  methods: {
    closePicker() {
      this.colorData = this.color
      this.isOpen = false
    },
    updateColor() {
      this.$emit('input', this.colorData)
      this.isOpen = false
    },
    clearColor() {
      this.$emit('input', null)
      this.isOpen = false
    }
  },
  watch: {
    color: function(newValue, oldValue) {
      if (newValue === null) {
        this.colorData = ''
      } else {
        this.colorData = this.color
      }
    }
  }
}
</script>
