import Vue from 'vue'
// import clonedeep from 'lodash.clonedeep'
import {
  getDashboardElements, deleteDashboard, deleteDashboardsGroup,
  updateFavoriteDashboard, updateDashboardsGroup, createDashboard, renameDashboard, sortDashboardsAndGroups, createDashboardsGroup
} from '@/api/backend'
import {
  LOGOUT, LOAD_DASHBOARDS_AND_GROUPS, CREATE_DASHBOARD, CREATE_DASHBOARDS_GROUP, DELETE_DASHBOARDS_GROUP,
  UPDATE_DASHBOARD, DELETE_DASHBOARD, SELECT_DASHBOARD,
  LOADING, UPDATE_FAVORITE_DASHBOARD, TOGGLE_DASHBOARD_EDIT,
  UPDATE_DASHBOARD_SORTING_SETTINGS, SORT_DASHBOARDS_AND_GROUPS, UPDATE_DASHBOARDS_GROUP,
  RENAME_DASHBOARD, UPDATE_WIDGET_POSITION, UPDATE_WIDGET_MARK_AREA_ELEMENTS, UPDATE_DASHBOARDS_AND_GROUPS_ORDER, OPEN_WIDGET_DETAILS_DIALOG
} from '@/store/mutations'

const selectDashboard = function(dashboards, predicate) {
  for (let i = 0; i < dashboards.length; i++) {
    let d = dashboards[i]
    if (d.dashboards) {
      d = d.dashboards.find(predicate)
      if (d) {
        return d
      }
    } else if (predicate(d)) {
      return d
    }
  }
}

export default {
  namespaced: true,
  state: {
    dashboardsAndGroups: [],
    selectedDashboard: null,
    expanded: false,
    editMode: false
  },
  getters: {
    group(state) {
      return {
        id: -1,
        expanded: state.expanded,
        dashboardsAndGroups: state.dashboardsAndGroups
      }
    },
    nextDashboardOrder(state) {
      let max = 0
      state.dashboardsAndGroups.forEach(d => {
        if (d.order > max) {
          max = d.order
        }
      })
      return ++max
    },
    favorite(state) {
      const favorite = selectDashboard(state.dashboardsAndGroups, (d) => d.favorite)
      if (favorite) {
        return favorite
      }
      return null
    }
  },
  mutations: {
    [LOGOUT](state) {
      state.dashboardsAndGroups = []
      state.selectedDashboard = null
      state.expanded = false
    },
    expand(state) {
      state.expanded = !state.expanded
    },
    expandDashboardsGroup(state, dashboardsGroup) {
      dashboardsGroup.expanded = !dashboardsGroup.expanded
    },
    [LOADING](state, { object, value }) {
      Vue.set(object, 'loading', value)
    },
    [SORT_DASHBOARDS_AND_GROUPS](state) {
      const dashboardsSettings = this.state.settings.dashboardsSettings
      const direction = dashboardsSettings.sortDirection === 'ASC' ? 1 : -1
      state.dashboardsAndGroups.forEach((dg) => {
        if (dg.dashboards) {
          dg.dashboards.sort((l, r) => {
            if (dashboardsSettings.sortMode === 'CUSTOM') {
              return l.order - r.order
            } else {
              return l.name.localeCompare(r.name) * direction
            }
          })
        }
      })
      state.dashboardsAndGroups.sort((l, r) => {
        if (dashboardsSettings.sortMode === 'CUSTOM') {
          return l.order - r.order
        } else {
          return l.name.localeCompare(r.name) * direction
        }
      })
      state.dashboardsAndGroups = [...state.dashboardsAndGroups]
    },
    [LOAD_DASHBOARDS_AND_GROUPS](state, { dashboards, id }) {
      state.dashboardsAndGroups = dashboards
      this.commit(`dashboards/${SORT_DASHBOARDS_AND_GROUPS}`)
      let dashboard = this.getters['dashboards/favorite']
      if (id) {
        dashboard = selectDashboard(state.dashboardsAndGroups, (d) => d.id === id)
      }
      if (dashboard) {
        this.commit(`dashboards/${SELECT_DASHBOARD}`, dashboard)
      }
    },
    [DELETE_DASHBOARD](state, id) {
      const index = state.dashboardsAndGroups.findIndex(d => d.id === id)
      if (index >= 0) {
        state.dashboardsAndGroups.splice(index, 1)
      } else {
        state.dashboardsAndGroups.forEach(dg => {
          if (dg.dashboardsData !== undefined) {
            const index = dg.dashboards.findIndex(d => d.id === id)
            if (index >= 0) {
              dg.dashboards.splice(index, 1)
            }
          }
        })
      }
      if (state.selectedDashboard.id === id) {
        let toRedirect = this.getters['dashboards/favorite']
        if (!toRedirect) {
          toRedirect = selectDashboard(state.dashboardsAndGroups, d => true)
        }
        this.commit(`dashboards/${SELECT_DASHBOARD}`, toRedirect)
      }
    },
    [DELETE_DASHBOARDS_GROUP](state, id) {
      const index = state.dashboardsAndGroups.findIndex(d => d.id === id)
      if (index >= 0) {
        state.dashboardsAndGroups[index].dashboards.forEach(dashboard => {
          if (state.selectedDashboard.id === dashboard.id) { // redirect user to the favorite dashboard if the current one was removed with the group
            this.commit(`dashboards/${SELECT_DASHBOARD}`, this.getters['dashboards/favorite'])
          }
        })
        state.dashboardsAndGroups.splice(index, 1)
      }
    },
    [CREATE_DASHBOARD](state, dashboard) {
      state.dashboardsAndGroups.push(dashboard)
      this.commit(`dashboards/${SORT_DASHBOARDS_AND_GROUPS}`)
      this.commit(`dashboards/${SELECT_DASHBOARD}`, dashboard)
    },
    [CREATE_DASHBOARDS_GROUP](state, dashboard) {
      state.dashboardsAndGroups.push(dashboard)
      this.commit(`dashboards/${SORT_DASHBOARDS_AND_GROUPS}`)
    },
    [SELECT_DASHBOARD](state, dashboard = null) {
      state.selectedDashboard = dashboard
      state.expanded = true
      if (dashboard !== null && dashboard.groupId !== null) {
        state.dashboardsAndGroups.forEach(dg => {
          if (dg.id === dashboard.groupId) {
            dg.expanded = true
          } else if (dg.expanded) {
            dg.expanded = false
          }
        })
      }
    },
    [TOGGLE_DASHBOARD_EDIT](state, value) {
      if (value) {
        state.editMode = value
      } else {
        state.editMode = !state.editMode
      }
    },
    [UPDATE_FAVORITE_DASHBOARD](state, dashboard) {
      state.dashboardsAndGroups.forEach(element => {
        if (element.dashboards) {
          element.dashboards.forEach(dashboard => {
            dashboard.favorite = false
          })
        } else {
          element.favorite = false
        }
        dashboard.favorite = true
      })
    },
    [RENAME_DASHBOARD](state, { id, name }) {
      const dashboard = selectDashboard(state.dashboardsAndGroups, (d) => d.id === id)
      if (dashboard) {
        dashboard.name = name
        state.selectedDashboard.name = name // rename can be done only on the selected dashboard
      }
      this.commit(`dashboards/${SORT_DASHBOARDS_AND_GROUPS}`)
    },
    [UPDATE_WIDGET_POSITION](state, { widget, position }) {
      widget.position = position
    },
    [UPDATE_DASHBOARD](state, data) {
      const index = state.dashboardsAndGroups.findIndex(d => d.id === data.id)
      if (index >= 0) {
        state.dashboardsAndGroups[index].widgets = data.widgets
        Vue.set(state.dashboardsAndGroups, index, data)
        if (data.id === state.selectedDashboard.id) {
          state.selectedDashboard = state.dashboardsAndGroups[index]
        }
      } else {
        state.dashboardsAndGroups.forEach((element, i) => {
          if (element.dashboards) {
            const index = element.dashboards.findIndex(d => d.id === data.id)
            if (index >= 0) {
              Vue.set(state.dashboardsAndGroups[i].dashboards, index, data)
              if (data.id === state.selectedDashboard.id) {
                state.selectedDashboard = data
              }
            }
          }
        })
      }
    },
    [UPDATE_DASHBOARDS_GROUP](state, group) {
      const linkedDashboards = []
      let added = group.dashboards
      let matchedGroup = state.dashboardsAndGroups.find(d => d.id === group.id)
      if (matchedGroup) {
        const removed = matchedGroup.dashboards.filter(o => !group.dashboards.some(n => n.id === o.id))
        removed.forEach(d => {
          const index = matchedGroup.dashboards.findIndex(e => e.id === d.id)
          matchedGroup.dashboards.splice(index, 1)
          d.groupId = null
          state.dashboardsAndGroups.push(d)
        })
        added = group.dashboards.filter(o => !matchedGroup.dashboards.some(n => n.id === o.id))
        matchedGroup.name = group.name
      } else {
        matchedGroup = group
        matchedGroup.dashboards = []
        state.dashboardsAndGroups.push(matchedGroup)
      }
      added.forEach(d => {
        const index = state.dashboardsAndGroups.findIndex(e => e.id === d.id)
        const dashboard = state.dashboardsAndGroups[index]
        dashboard.groupId = matchedGroup.id
        state.dashboardsAndGroups.splice(index, 1)
        linkedDashboards.push(dashboard)
      })
      matchedGroup.dashboards.push(...linkedDashboards)
      this.commit(`dashboards/${SORT_DASHBOARDS_AND_GROUPS}`)
    },
    [UPDATE_WIDGET_MARK_AREA_ELEMENTS](state, data) {
      const widget = state.selectedDashboard.widgets.find(w => w.id === data.widget)
      if (widget) {
        widget.markAreaElements = data.elements
      }
    },
    [UPDATE_DASHBOARDS_AND_GROUPS_ORDER](state, data) {
      state.dashboardsAndGroups.forEach(g => {
        let value = data.sortData[g.id]
        if (value) {
          g.order = value.order
        }
        if (g.dashboards) {
          g.dashboards.forEach(d => {
            value = data.sortData[d.id]
            if (value) {
              d.order = value.order
            }
          })
        }
      })
      this.commit(`dashboards/${SORT_DASHBOARDS_AND_GROUPS}`)
      // expand group and select favorite dashboard
      var dashboard = this.getters['dashboards/favorite']
      if (state.selectedDashboard && state.selectedDashboard.id === dashboard.id) {
        this.commit(`dashboards/${SELECT_DASHBOARD}`, dashboard)
      }
    },
    [OPEN_WIDGET_DETAILS_DIALOG](state, data) {}
  },
  actions: {
    expand(context) {
      context.commit('expand')
    },
    expandDashboardsGroup(context, dashboardsGroup) {
      context.commit('expandDashboardsGroup', dashboardsGroup)
    },
    [LOAD_DASHBOARDS_AND_GROUPS](context, currentDashboardId) {
      return getDashboardElements()
        .then(r => context.commit(LOAD_DASHBOARDS_AND_GROUPS, { dashboards: r.data, id: currentDashboardId }))
        .catch(e => console.log(e))
    },
    [DELETE_DASHBOARD](context, id) {
      return deleteDashboard(id)
        .then(() => context.commit(DELETE_DASHBOARD, id))
    },
    [DELETE_DASHBOARDS_GROUP](context, id) {
      return deleteDashboardsGroup(id)
        .then(() => context.commit(DELETE_DASHBOARDS_GROUP, id))
    },
    [CREATE_DASHBOARD](context, name) {
      const dashboard = {
        name: name,
        favorite: context.getters.favorite === null,
        order: context.getters.nextDashboardOrder
      }
      return createDashboard(dashboard).then(r => {
        context.commit(CREATE_DASHBOARD, r.data)
        return r
      })
    },
    [CREATE_DASHBOARDS_GROUP](context, dashboardsGroup) {
      const group = {
        name: dashboardsGroup.name,
        order: context.getters.nextDashboardOrder,
        dashboardsData: dashboardsGroup.dashboardsData
      }
      return createDashboardsGroup(group).then(r => {
        context.commit(CREATE_DASHBOARDS_GROUP, r.data)
        return r.data
      })
    },
    [SELECT_DASHBOARD](context, dashboard) {
      context.commit(SELECT_DASHBOARD, dashboard)
    },
    [TOGGLE_DASHBOARD_EDIT](context, value) {
      context.commit(TOGGLE_DASHBOARD_EDIT, value)
    },
    [UPDATE_FAVORITE_DASHBOARD](context, dashboard) {
      return updateFavoriteDashboard(dashboard.id)
        .then(r => context.commit(UPDATE_FAVORITE_DASHBOARD, dashboard))
    },
    [RENAME_DASHBOARD](context, data) {
      return renameDashboard(data.id, data.name)
        .then(r => context.commit(RENAME_DASHBOARD, data))
        .then(r => data)
    },
    [UPDATE_WIDGET_POSITION](context, data) {
      context.commit(UPDATE_WIDGET_POSITION, data)
    },
    [UPDATE_DASHBOARD](context, data) {
      context.commit(UPDATE_DASHBOARD, data)
    },
    [UPDATE_DASHBOARDS_GROUP](context, data) {
      return updateDashboardsGroup(data).then(r => {
        const response = { ...r.data, ...data }
        context.commit(UPDATE_DASHBOARDS_GROUP, response)
        return response
      })
    },
    [UPDATE_WIDGET_MARK_AREA_ELEMENTS](context, data) {
      context.commit(UPDATE_WIDGET_MARK_AREA_ELEMENTS, data)
    },
    [UPDATE_DASHBOARDS_AND_GROUPS_ORDER](context, data) {
      return sortDashboardsAndGroups(data).then(r => {
        context.commit(UPDATE_DASHBOARD_SORTING_SETTINGS, data, { root: true })
        context.commit(UPDATE_DASHBOARDS_AND_GROUPS_ORDER, data)
        return r
      })
    }
  }
}
